<template>
    <div>
        <div class="header-image">
            <div class="header-content">
                <h1>OffshoreSMS Software-as-a-Service Terms of Use</h1>
            </div>
        </div>

        <div class="site-wrapper">
            <div class="container">
                <ul class="scroller">
                    <li><router-link to="useful-links">Useful Links</router-link></li>
                    <li class="is-active">
                        <a aria-current="page">SaaS Terms of Use</a>
                    </li>
                </ul>

                <div class="spacer s30"></div>

                <div class="content">
                    <p>
                        Please take the time to read the following terms and conditions statement
                        relating to the purchase and use of annual software subscriptions for any
                        OffshoreSMS plans and services. By purchasing any annual subscription plans
                        either via the website or the mobile application, you are agreeing to these
                        terms of use.
                    </p>
                    <p>
                        If you purchase any online subscriptions or services through OffshoreSMS,
                        there may be additional terms and conditions relating to the purchase.
                        Please make sure you agree with these terms and conditions, which you may
                        read prior to completing your purchase.
                    </p>

                    <!-- <div class="spacer s20"></div>
                    <h4>Definitions</h4>
                    <p>
                        <strong>Products and Services</strong> means the cloud based Safety
                        Management System tools and apps developed by Offshore SMS.
                    </p>
                    <p>
                        <strong>The Website</strong> means the web app located at
                        www.offshoresms.com.au, and includes any mobile or app versions of
                        OffshoreSMS.
                    </p>
                    <p>
                        <strong>We / Us</strong> etc means Offshore SMS Pty Ltd and any holding
                        companies, affiliates, employees, officers, agents or assigns.
                    </p> -->

                    <div class="spacer s20"></div>
                    <h4>Scope of Terms</h4>
                    <p>
                        These Terms and Conditions ("Terms") apply to the purchase and use of annual
                        software subscriptions offered by OffshoreSMS via its website dashboard and
                        mobile applications ("Software Services").
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Acceptance of Terms</h4>
                    <p>
                        By purchasing and/or using our Software Services, you agree to these Terms.
                        If you do not agree to these Terms, you are not authorised to use our
                        Software Services.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Subscription Period</h4>
                    <p>
                        All Software Services are subscribed to for a fixed period of twelve (12)
                        months from the date of purchase ("Subscription Period").
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Renewal</h4>
                    <p>
                        Unless cancelled by the subscriber, the Software Services will automatically
                        renew for successive 12-month periods. Notification of upcoming renewals
                        will be sent to the subscriber's registered email address at least twenty
                        one (21) days before the renewal date.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Payment</h4>
                    <p>
                        All subscription fees are payable in advance and are due upon initial
                        purchase and at the beginning of each renewal period.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>No Refunds</h4>
                    <p>
                        All sales are final. No refunds will be provided for any reason, including,
                        but not limited to, dissatisfaction with the Software Services, or early
                        termination of the Subscription Period.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Cancellation</h4>
                    <p>
                        Subscribers may cancel their subscription at any time by notifying
                        OffshoreSMS by email. Cancellation will take effect at the end of the
                        current Subscription Period, and the Software Services will not renew
                        thereafter. No prorated refunds will be given for unused portions of the
                        Subscription Period.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Service Availability</h4>
                    <p>
                        While OffshoreSMS strives to provide uninterrupted service, we do not
                        guarantee that our Software Services will always be available or be
                        uninterrupted. Maintenance, updates, or other factors may result in brief
                        service interruptions, which subscribers acknowledge and accept.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Modifications to Service</h4>
                    <p>
                        OffshoreSMS reserves the right to modify or discontinue the Software
                        Services, in whole or in part, at our sole discretion. If the Software
                        Services are permanently discontinued, subscribers will be provided notice
                        and may be entitled to a prorated refund for any unused portion of the
                        Subscription Period if an alternative software solution is not provided in
                        substitution.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Data & Privacy</h4>
                    <p>
                        By using our Software Services, subscribers agree to our data and privacy
                        practices as outlined in our
                        <router-link to="privacy-policy">Privacy Policy</router-link>, which is
                        incorporated into these Terms by reference.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Limitation of Liability</h4>
                    <!-- <p>
                        We take no responsibility for the accuracy of any of the content or
                        statements contained on this Website or in relation to our Services.
                        Statements made are by way of general comment only and you should satisfy
                        yourself as to their accuracy. Further, all of our Services are provided
                        without a warranty with the exception of any warranties provided by law. We
                        are not liable for any damages whatsoever, incurred as a result of or
                        relating to the use of the Website or our Services.
                    </p> -->
                    <p>
                        In no event shall OffshoreSMS, its affiliates, or its licensors be liable
                        for any indirect, incidental, punitive, special, or consequential damages,
                        including but not limited to damages for loss of profits, goodwill, use,
                        data, or other intangible losses, arising out of or in connection with the
                        Software Services.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Governing Law</h4>
                    <p>
                        These Terms shall be governed by and construed in accordance with all
                        relevant consumer laws in Australia and New Zealand, without regard to its
                        conflict of laws principles.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Amendments</h4>
                    <p>
                        OffshoreSMS may update or modify these Terms from time to time. We will
                        provide notice of any changes by posting the updated Terms on our website or
                        through other means. Continued use of the Software Services after changes
                        are made means that you agree to be bound by the updated Terms.
                    </p>

                    <div class="spacer s20"></div>
                    <h4>Contact</h4>
                    <p>
                        For any queries or concerns related to these Terms or our Software Services,
                        please <router-link to="feedback">contact us</router-link>.
                    </p>
                    <p>
                        By purchasing a subscription, you acknowledge that you have read,
                        understood, and agree to these Terms.
                    </p>
                </div>

                <hr />

                <p class="heading">Last Edit: 10 October, 2023</p>

                <div class="spacer s90"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',

    components: {},

    mounted: function() {},

    computed: {},

    data: function() {
        return {};
    },

    methods: {}
};
</script>

<style lang="scss" scoped>
.header-image {
    background: url('../assets/cyber2.jpg') no-repeat center center;
    color: #fff;
}

.avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    background: steelblue;
    display: block;
    margin: 0 2rem 2rem 0;
    float: left;
    transition: 0.3s;
    background-repeat: no-repeat;
    background-position: center center;
    &.company {
        background: url('../assets/img/offshore-sms-icon.jpg') no-repeat center center;
        background-size: cover !important;
    }
    &.steve {
        background: url('../assets/img/stevefraser.jpg') no-repeat center center;
        background-size: contain;
    }
}
.avatar-content-box {
    height: 150px;
    width: calc(100% - 200px);
    float: left;
    position: relative;
    .content-align {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            width: 0;
            bottom: -10px;
            background: steelblue;
            height: 5px;
            transition-property: width;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
        }
        h2 {
            font-size: 1.5rem;
            line-height: 1.8rem;
        }
        span {
        }
    }
}
.header-content {
    text-align: left;
}
.social {
    a {
        transition: 0.3s;
        font-size: 2rem;
    }
}
.content {
    p {
        margin-bottom: 1rem;
        color: #333;
    }
}

@media only screen and (max-width: 479px) {
    .avatar {
        float: none;
        margin: 0 auto 1rem;
    }
    .avatar-content-box {
        width: 100%;
        text-align: center;
    }
}
</style>
