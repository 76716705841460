<template>

	<div>

			<div class="header-image">
				<div class="header-content">
					<h1>Offshore SMS Support</h1>
				</div>
			</div>

			<div class="site-wrapper">

				

				<ul class="scroller">
			    <li><router-link to="home">Home</router-link></li>
			    <li class="is-active"><a aria-current="page">{{msg}}</a></li>
			  </ul>
			
				<div class="container">

					<div class="content">

						<div v-if="browser == 'IE' || browser == 'Edge'" class="notification is-danger">
								<font-awesome-icon class="icon" icon="exclamation-triangle" /><h5 class="is-size-5">Browser Issues</h5>
								<p>Sadly some of the cutting edge features of this web app are not supported by Internet Explorer, and maybe Edge. We highly recommend using a browser like Firefox or Google Chrome.</p>
						</div>

						<div class="box">
								<font-awesome-icon class="icon" icon="question-circle" /><h5 class="is-size-5">FAQs</h5>
								<p>Answers to some common questions about Offshore SMS</p>
								<router-link class="button is-info" to="faqs">Read FAQs</router-link>
						</div>

            <div v-if="isUser" class="box">
                <font-awesome-icon class="icon" icon="play" /><h5 class="is-size-5">Getting Started</h5>
                <p>Tell us a bit about yourself to help us tailor your experience on OffshoreSMS.</p>
                <router-link class="button is-success" to="getting-started">Visit Questionnaire</router-link>
            </div>

						<div class="box">
								<font-awesome-icon class="icon" icon="info-circle" /><h5 class="is-size-5">Our User Guide</h5>
								<p>Helpful tips and suggestions about how to get the most our of OffshoreSMS.</p>
								<router-link class="button is-skip" to="user-guide-start">View Guide</router-link>
						</div>

						<!-- <div class="box">
								<font-awesome-icon class="icon" icon="check" /><h5 class="is-size-5">App not working?</h5>
								<p>Sometimes things go wrong. If for any reason your data isn't loading, just click the refresh button below.</p>
								<button class="button is-warning" @click="windowReload">Refresh App</button>
						</div> -->

						<div class="box">
								<font-awesome-icon class="icon" icon="info-circle" /><h5 class="is-size-5">Contact Offshore SMS</h5>
								<p>We are always here to help, for suggestions, feedback or new ideas, please don't hesitate to leave a message below, or call <a href="tel:+61-447-139-382">+61 447 139 382</a>.</p>
								<router-link class="button is-primary" to="feedback">Leave a message</router-link>
						</div>



					</div>

					




				</div>


			</div>

	</div>

</template>






<script>
	
	import { db } from '../main.js';
	import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/compat/firestore';
  import 'firebase/compat/functions';

	export default {

		name: 'Support',
		computed: {
	    user() {
	      return this.$store.getters.getUser;
	    },
      isUser() {
        var user = firebase.auth().currentUser;
        return user != null;
      }
	  },
		data: function() {
			return {
				msg: 'Support',
				browser: '',
			}
		},
		mounted() {
			this.browser = this.$browserDetect.meta.name;
		},

		methods: {
      
      // viewDEMO: function() {
      // 	let vesselName = 'DEMO VESSEL';
      // 	let vesselID = 'kZgrsNOrggKmi7bjE5IA';
      // 	let vesselUVI = 'DEMO';
      // 	//this.$store.dispatch('UPDATESELECTEDVESSEL',{'DEMO VESSEL','kZgrsNOrggKmi7bjE5IA','DEMO'});
      // 	this.$store.dispatch('UPDATESELECTEDVESSEL',{vesselName,vesselID,vesselUVI});
      // 	this.$router.push({ name: 'DemoSms', params: { 
      // 		selectedVesselID : 'kZgrsNOrggKmi7bjE5IA',
      // 		selectedVesselName : 'DEMO VESSEL',
      // 		selectedVesselUVI : 'DEMO',
      // 		}
      // 	});
      // },

      // windowReload: function() {
      // 	console.log(this.user);
      // 	const userName = this.user == null ? 'A visitor' : this.user.displayName;
	     //  const userID = this.user == null ? 'no-user-id' : this.user.uid;
	     //  var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
	     //  userHasEngaged({
	     //    userID: userID, 
	     //    userName: userName,
	     //    message: 'A user has refreshed the the app from the support page',
	     //    subject: 'A user has refreshed the app'
	     //  }); 
      // 	window.location.reload();
      // },
      

		},

	}





</script>




<style lang="scss" scoped>
	
	

	.header-image {
	  background: url('../assets/img/boat-tied-up.jpg') no-repeat center top;
	  color: #fff;
	  background-size: cover;
	}

	.icon {
		display: inline-block;
		margin-right: 0.6rem;
		vertical-align: sub;
		float: left;
	}
  .button {
    &.is-skip {
      background-color: #ff7878;
      color: #fff;
      border-color: #fff;
    }
  }
	h5 {
		display: block;
	}
	.notification {
		h5 {
			color: #fff;
		}
	}


</style>