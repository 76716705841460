<template>

    <div>

        <iframe 
            src="https://docs.google.com/presentation/d/e/2PACX-1vRoMSaIrFbP9-4SQAUBBZSynSkigXGY22Amdu0SJZxPhYXsdhWzR8Um8ppwhm2DWemTFzVUSsXP9dU2/embed?start=false&loop=false&delayms=3000" 
            frameborder="0" 
            width="100%" 
            height="900" 
            allowfullscreen="true" 
            mozallowfullscreen="true" 
            webkitallowfullscreen="true">
        </iframe>

    </div>
    
</template>

<script>

export default {
    
}

</script>


<style scoped>




</style>