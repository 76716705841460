// https://offshoresms.com.au/link?u=y3tdRTE336xc455

<template>

	<div>

			<div class="header-image">
				<div class="header-content">
					<h1>Shortlink redirect</h1>
				</div>
			</div>

			<div class="site-wrapper">

			
				<div class="container">

					<div class="content">

						
                        <div v-if="errMsg != ''" class="notification is-warning">
                            <p>{{errMsg}}</p>
                        </div>


					</div>



				</div>


			</div>

	</div>

</template>






<script>
	
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';

export default {

    name: 'Support',
		computed: {
            // user() {
            //     return this.$store.getters.getUser;
            // },
            // isUser() {
            //     var user = firebase.auth().currentUser;
            //     return user != null;
            // }
        },
		data: function() {
			return {
				browser: '',
                targetUrl: '',
                shortLink: '',
                errMsg: '',
			}
		},
		mounted() {
			// this.browser = this.$browserDetect.meta.name;
            this.shortLink = this.$route.query.u ? this.$route.query.u : '';
            this.redirect();
		},

		methods: {
            redirect() {
                let self = this;
                NProgress.start;
                db.collection('shortLinks').doc(this.shortLink).get().then((doc) => {
                    self.targetUrl = doc.data().targetUrl ? doc.data().targetUrl : 'https://offshoresms.com.au';
                }).then(() => {
                    // window.location.href = self.targetUrl;
                    // self.$router.push('https://google.com');
                })
                .catch((err) => {
                    console.log(err);
                    self.errMsg = 'Something went wrong, sorry. Please contact the vessel owner.';
                });
            }

		},

	}





</script>




<style lang="scss" scoped>
	
	

	.header-image {
	  background: url('../assets/img/boat-tied-up.jpg') no-repeat center top;
	  color: #fff;
	  background-size: cover;
	}

	.icon {
		display: inline-block;
		margin-right: 0.6rem;
		vertical-align: sub;
		float: left;
	}
  .button {
    &.is-skip {
      background-color: #ff7878;
      color: #fff;
      border-color: #fff;
    }
  }
	h5 {
		display: block;
	}
	.notification {
		h5 {
			color: #fff;
		}
	}


</style>