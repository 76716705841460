<template>

    <div>
  
  
  
  
      <div class="header-image">
          <div class="header-content">
            <h1>Offshore SMS Data Collection & Cyber Security Policy</h1>
          </div>
        </div>
  
      <div class="site-wrapper">
  
          
  
        <div class="container">
  
          <ul class="scroller">
            <li><router-link to="useful-links">Useful Links</router-link></li>
            <li class="is-active"><a aria-current="page">Data & Security Policy</a></li>
          </ul>
  
          <div class="spacer s30"></div>
  
          <div class="content">
            
            <p>Please take the time to read this data collection and cyber security policy. By using Our Website and the services and information offered on Our Website, you are agreeing to our <router-link to="privacy-policy">terms and conditions</router-link>.</p>
  
            <div class="spacer s20"></div>
            <h4>Definitions</h4>
            <p><strong>Products and Services</strong> means the cloud based Safety Management System tools and apps developed by Offshore SMS. </p>
            <p><strong>The Website</strong> means the web app located at www.offshoresms.com.au, and includes any mobile or app versions of OffshoreSMS.</p>
            <p><strong>We / Us</strong> etc means Offshore SMS Pty Ltd and any holding companies, affiliates, employees, officers, agents or assigns.</p>

            <div class="spacer s20"></div>
            <h4>Passwords and logins</h4>
            <p>You are responsible for maintaining the confidentiality of your passwords and login details and for all activities carried out under your password and login.</p>
            <p>All user generated passwords are encrypted in the database and not visible even to OffshoreSMS developers and staff. In the event that we generate a temporary user-friendly password for customers in the event of a lost or locked-out login attempt, we expect that the user will change that password at the earliest convenience.</p>
            <p>As of early 2023, the OffshoreSMS web and app platforms have moved to a multi-factor authentication system allowing alternative methods of user validation and authentication.</p>
  
            <div class="spacer s20"></div>
            <h4>Collection of Data</h4>

            <h5>Introduction</h5>
            <p>At OffshoreSMS, we recognise that the privacy and security of our customers' data are of utmost importance. As a Software-as-a-Service (SaaS) business, we collect certain information from our customers to provide them with the best possible service.</p>
            <p>This Data Collection Policy outlines what data we collect, how we use it, and how we protect it.</p>
            <p>While the OffshoreSMS app and website are free to download and to use, creating a free profile will allow us to provide tailored services and content to users. Specifically we only collect your name, a contact phone number and an email address, all of which are only used to provide personalised customised content to you, for example to manage your profile, to add vessels and to allow you to be added as crew on other vessels.</p>
            <p>We also require all free user accounts to have a secure password and access to either a validated mobile phone number or email address to enhance the protection of your data and to prevent other users acting as you.</p>

            <h5>What data we collect:</h5>
            <p>We collect the following data from our customers:</p>
            <ul>
              <li>Personal information: This includes name, email address, phone number, and other contact information.</li>
              <li>Usage data: This includes information about how our customers use our services, such as which features they use, how often they log in, and the amount of data they upload/download.</li>
              <li>Device information: This includes information about the device and browser used to access our services, such as the operating system, IP address, and browser type.</li>
            </ul>
            <p>We do not collect or store billing or credit card information on our servers, but instead use Square APIs for all web product purchases and subscriptions.</p>

            <h5>How we use the data:</h5>
            <p>We use the data we collect to provide our customers with the best possible service, including:</p>
            <ul>
              <li>Billing and account management: We use personal and billing information to manage our customers' accounts, process payments, and send invoices, but do not record or retain any credit card details.</li>
              <li>Service improvement: We use usage and device information to improve our services, fix bugs, and develop new features.</li>
              <li>Marketing and communication: We may use personal information to send marketing materials and communicate with our customers about new products or services.</li>
              <li>Legal compliance: We may use personal and billing information to comply with legal requirements, such as tax laws.</li>
            </ul>

            <h5>How we protect the data:</h5>
            <p>We take the security of our customers' data very seriously. We use industry-standard measures to protect the data we collect, including:</p>
            <ul>
              <li>Encryption: We fully encrypt all data in transit and at rest.</li>
              <li>Access controls: We limit access to customer data to authorised, Australian-based OffshoreSMS personnel only.</li>
              <li>Monitoring: We monitor our systems for suspicious activity and take action to prevent unauthorised access.</li>
              <li>Regular backups: We regularly back up customer data to protect against data loss.</li>
            </ul>


            <!-- <div class="spacer s30"></div>
            <h4>Cyber Security</h4> -->
            <!-- <h5>Data Encryption</h5>
            <p>All data sent to and from our servers are fully end-to-end encrypted. Data is encrypted in transit using HTTPS protocols. This helps prevent unauthorized interception and ensures that data transmitted over the internet is secure.</p>
            <p>All data at rest is automatically encrypted before it is written to disk.</p> -->


  
            <div class="spacer s20"></div>
            <h4>Privacy of User Data</h4>
            <p>We will never share any user information, passwords, email addresses or other user data with any other OffshoreSMS users or with any third party businesses or individuals without the express consent of the user.</p>
            <p>We place the highest value on the trust that our customers have placed in us and will always go out of our way to maintain that trust.</p>
            <p>We are committed to protecting our customers' data and privacy. By using our services, customers agree to the terms outlined in this Data Collection Policy. We may update this policy from time to time, and we will note the date of most recent changes on this page.</p>
            <p>If you have any concerns or would like to know more about how your data is stored, please <router-link to="feedback">contact us</router-link>.</p>
  
            <div class="spacer s20"></div>
            <h4>Disaster Recovery and Business Continuity Plan</h4>
            <p>We place the highest value on the security and privacy of our clients' data, and are focused on delivering a 99.99% uptime of our web and cloud services.</p>
            <p>In the event of an outage or loss of data, our Disaster Recovery plan will be activated, which will include rapid notification to all clients and customers with regular updates as they are available. In particular, our disaster recovery plan includes restoring web and app access as quickly as possible, typically by restoring data from regular and incremental backups.</p>
            <p>In the unlikely event of a data outage requiring a restored backup, some recent data may appear missing or lost, and we will endeavour to restore full data as quickly as possible.</p>
            <p>OffshoreSMS has internal processes in place to ensure maximum uptime of services and timely continuity of our web and cloud services.</p>
  
            
  
  
          </div>
  
          <hr>
  
          <p class="heading">Last Edit: 11 May, 2023</p>
  
          <div class="spacer s90"></div>
  
  
        </div>
        
  
      </div>
  
  
  
  
    </div>
    
  
  </template>
  
  
  
  
  <script>
  
  
  
  
  export default {
  
    name: 'AboutUs',
  
    components: {
  
    },
  
    mounted: function() {
  
    },
  
    computed: {
  
    },
  
    data: function() {
      return {
  
  
      }
    },
  
    methods: {
      
    }
  
  }
  
  </script>
  
  
  
  
  <style lang="scss" scoped>
  
    .header-image {
      background: url('../assets/cyber2.jpg') no-repeat center center;
      color: #fff;
    }
  
    .avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      text-align: center;
      position: relative;
      background: steelblue;
      display: block;
      margin: 0 2rem 2rem 0;
      float: left;
      transition: 0.3s;
      background-repeat: no-repeat;
      background-position: center center;
      &.company {
        background: url('../assets/img/offshore-sms-icon.jpg') no-repeat center center;
        background-size: cover !important;
      }
      &.steve {
        background: url('../assets/img/stevefraser.jpg') no-repeat center center;
        background-size: contain;
      }
    }
    .avatar-content-box {
      height: 150px;
      width: calc(100% - 200px);
      float: left;
      position: relative;
      .content-align {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          left: 0;
          width: 0;
          bottom: -10px;
          background: steelblue;
          height: 5px;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
        }
        h2 {
          font-size: 1.5rem;
          line-height: 1.8rem;
        }
        span {
  
        }
      }
    }
    .header-content {
      text-align: left;
    }
    .social {
      a {
        transition: 0.3s;
        font-size: 2rem;
      }
    }
    .content {
      p {
        margin-bottom: 1rem;
        color: #333;
      }
    }
  
  
    @media only screen and (max-width: 479px) {
  
      .avatar {
        float: none;
        margin: 0 auto 1rem;
      }
      .avatar-content-box {
        width: 100%;
        text-align: center;
      }
  
  
    }
  
  </style>