<template>

    <div>
  
  
  
  
      <div class="header-image">
          <div class="header-content">
            <h1>Offshore User Accounts & Data</h1>
          </div>
        </div>
  
      <div class="site-wrapper">
  
          
  
        <div class="container">
  
          <ul class="scroller">
            <li><router-link to="useful-links">Useful Links</router-link></li>
            <li class="is-active"><a aria-current="page">User Account</a></li>
          </ul>
  
          <div class="spacer s30"></div>
  
          <div class="content">
            
            <p>Creating a user profile on OffshoreSMS is easy and free. We collect only the minimum amount of personal information to allow us to authenticate you in the system and nothing more. By creating a free user profile, all users agree to the OffshoreSMS <router-link to="privacy-policy">Privacy Policy</router-link>.</p>
  
            <div class="spacer s20"></div>
            <h4>Deletion of user accounts and user data</h4>
            <p>All users have the right to request that some or all of their data be changed or deleted from our system completely. Some data like names, phone numbers and email address can be changed easily by contacting us on the channels listed below.</p>
            <p>These changes will have little or no impact on the normal use of the OffshoreSMS platform. Deletion of other data may impact your ability to enjoy all of the features of OffshoreSMS. Please contact us below to find out how your specific request may impact your use of the app.</p>

            <div class="spacer s20"></div>
            <h4>Contact us about changing or deleting your data</h4>
            <p>Please contact us on one of the channels below and we can assist you with any enquiries about changing or deleting user accounts and data:</p>
            <div class="contact-link"><font-awesome-icon class="icon" icon="phone" /> &nbsp; Call us on <a href="tel:+61-447-139-382">+61 447 139 382</a></div>
            <div class="contact-link"><font-awesome-icon class="icon" icon="envelope" /> &nbsp; Email us at &nbsp;<a href="mailto:help@offshoresms.com.au?subject=User account enquiry from OffshoreSMS website">help@offshoresms.com.au</a></div>
            <div class="contact-link"><font-awesome-icon class="icon" icon="comment" /> &nbsp; Live Chat: If online, you will see a blue chat icon at the bottom of this page.</div>

            <div class="spacer s20"></div>
            <h4>More Information</h4>
            <p>To read more about our Privacy Policy and Terms &amp; Conditions, please <router-link to="privacy-policy">click here</router-link>.</p>
            <p>To read more about our Data Collection and Cyber Security Policy, please <router-link to="data-security-policy">click here</router-link>.</p>
  
  
          </div>
  
          <hr>
  
          <p class="heading">Last Edit: 14 May, 2023</p>
  
          <div class="spacer s90"></div>
  
  
        </div>
        
  
      </div>
  
  
  
  
    </div>
    
  
  </template>
  
  
  
  
  <script>
  
  
  
  
  export default {
  
    name: 'AboutUs',
  
    components: {
  
    },
  
    mounted: function() {
  
    },
  
    computed: {
  
    },
  
    data: function() {
      return {
  
  
      }
    },
  
    methods: {
      
    }
  
  }
  
  </script>
  
  
  
  
  <style lang="scss" scoped>
  
    .header-image {
      background: url('../assets/cyber2.jpg') no-repeat center center;
      color: #fff;
    }
  
    .avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      text-align: center;
      position: relative;
      background: steelblue;
      display: block;
      margin: 0 2rem 2rem 0;
      float: left;
      transition: 0.3s;
      background-repeat: no-repeat;
      background-position: center center;
      &.company {
        background: url('../assets/img/offshore-sms-icon.jpg') no-repeat center center;
        background-size: cover !important;
      }
      &.steve {
        background: url('../assets/img/stevefraser.jpg') no-repeat center center;
        background-size: contain;
      }
    }
    .avatar-content-box {
      height: 150px;
      width: calc(100% - 200px);
      float: left;
      position: relative;
      .content-align {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          left: 0;
          width: 0;
          bottom: -10px;
          background: steelblue;
          height: 5px;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
        }
        h2 {
          font-size: 1.5rem;
          line-height: 1.8rem;
        }
        span {
  
        }
      }
    }
    .header-content {
      text-align: left;
    }
    .social {
      a {
        transition: 0.3s;
        font-size: 2rem;
      }
    }
    .content {
      p {
        margin-bottom: 1rem;
        color: #333;
      }
    }

    .contact-link {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 0 0 8px 10px;
    }
  
  
    @media only screen and (max-width: 479px) {
  
      .avatar {
        float: none;
        margin: 0 auto 1rem;
      }
      .avatar-content-box {
        width: 100%;
        text-align: center;
      }
  
  
    }
  
  </style>