<template>
  <div>
    <div class="site-wrapper no-header">
      <div ref="smsWrapper" class="container" v-if="finalVesselID">
        <div class="buttons hideForPrint">
          <span v-if="showBack" @click="goBack()" class="button is-info"><span><font-awesome-icon icon="chevron-left" /></span> &nbsp; Back</span>
          <!-- <span id="pdfButton" class="button is-primary" @click="savePDF()">{{buttonText}}</span> -->
        </div>

        <hr v-if="showBack" class="narrow">
        <!-- <div class="img-wrapper"><img class="logo" src="https://ik.imagekit.io/offshoresms/offshore-sms-good-res-logo.jpg?updatedAt=1683103736289" alt="Offshore SMS"></div> -->
        
        <div class="img-wrapper" style="width: 200px;"><img style="max-width: 100%;" class="logo" src="https://ik.imagekit.io/offshoresms/offshore-sms-good-res-logo.jpg?updatedAt=1683103736289" alt="Offshore SMS"></div>



        <!-- <div style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 2rem; outline: none;">Offshore SMS</div> -->
        <h2 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;">{{details.vesselname}} SMS Snapshot - {{today}}</h2>

        <div v-if="fleetLogo != ''">
          <div class="spacer s10"></div>
          <img style="max-width: 400px; width: 100%;" class="fleetLogo" :src="fleetLogo" />
        </div>

        <div class="edit-note hideForPrint">
          <p>You can click in the Vessel Overview and Vessel Owner Information fields to edit the text for printing, but note that this won't change the saved values.</p>
          <p>To edit the fields permanently, go to My Vessels in your Dashboard, then click on Edit SMS.</p>
        </div>

        <div v-if="details.snapshotIntro != 'None'">
          <h3 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;"><font-awesome-icon icon="exclamation-triangle" />&nbsp;ABOUT THIS SAFETY MANAGEMENT SYSTEM</h3>
          <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <!-- <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  This Safety Management System (SMS) has been prepared based on specific vessel and operational information provided by the vessel owner. This snapshot is a summary of the SMS content on this date, and forms a part of the overall digital safety management system used in the cloud and in the OffshoreSMS app. Full SMS content, historical logs, crewing information and vessel activity is always available in the app and online at www.offshoresms.com.au. <br>
                  The contents of this safety management system and its use are the responsibility of the Owner of this vessel.
                </td> -->
                <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  <div contenteditable="true" v-for="line in details.snapshotIntro.split('\n')" :key="line">{{line}}<br></div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>


        <h3
          style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
          <font-awesome-icon icon="list-ul" />&nbsp; SMS SNAPSHOT CONTENTS</h3>
          
        <ul style="list-style: disc inside;margin-left: 1rem;margin-top: 1rem;">
          <li v-if="visibleSections.linkOwnerInfo" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-owner-info" v-smooth-scroll="{ offset: -100, updateHistory: false }">Vessel Owner Information</a></li>
          <li v-if="visibleSections.linkOverview" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-overview" v-smooth-scroll="{ offset: -100, updateHistory: false }">Vessel Overview</a></li>
          <li v-if="visibleSections.linkSpecs" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-specs" v-smooth-scroll="{ offset: -100, updateHistory: false }">Vessel Specifications</a></li>
          <li v-if="visibleSections.linkStatements" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-statements" v-smooth-scroll="{ offset: -80, updateHistory: false }">Statements of Responsibility</a></li>
          <li v-if="visibleSections.linkContacts" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-contacts" v-smooth-scroll="{ offset: -100, updateHistory: false }">Vessel Contacts</a></li>
          <li v-if="visibleSections.linkHazards" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-hazards" v-smooth-scroll="{ offset: -100, updateHistory: false }">Vessel Hazards &amp; Risk Assessments</a></li>
          <li v-if="visibleSections.linkPolicies" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-policies" v-smooth-scroll="{ offset: -100, updateHistory: false }">Vessel Policies</a></li>
          <li v-if="visibleSections.linkSops" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-sops" v-smooth-scroll="{ offset: -100, updateHistory: false }">Safe Work Procedures</a></li>
          <li v-if="visibleSections.linkEops" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-eops" v-smooth-scroll="{ offset: -100, updateHistory: false }">Emergency Procedures</a></li>
          <li v-if="visibleSections.linkScheds" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-scheds" v-smooth-scroll="{ offset: -100, updateHistory: false }">Schedules &amp; Drills</a></li>
          <li v-if="visibleSections.linkMaintenance" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-maintenance" v-smooth-scroll="{ offset: -100, updateHistory: false }">Maintenance Checklist</a></li>
          <li v-if="visibleSections.linkAssets" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-assets" v-smooth-scroll="{ offset: -100, updateHistory: false }">Plant Asset Register</a></li>
          <li v-if="visibleSections.linkStartupChecks" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-startup-checks" v-smooth-scroll="{ offset: -100, updateHistory: false }">Pre Departure Safety Checklist</a></li>
          <li v-if="visibleSections.linkInductionChecks" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-induction-checks" v-smooth-scroll="{ offset: -100, updateHistory: false }">Crew Induction Checklist</a></li>
          <li v-if="visibleSections.linkBriefingChecks" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-briefing-checks" v-smooth-scroll="{ offset: -100, updateHistory: false }">General Safety Briefing Checklist</a></li>
          <li v-if="visibleSections.linkCustomLogs" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-custom-logs" v-smooth-scroll="{ offset: -100, updateHistory: false }">Custom Logs</a></li>
          <li v-if="visibleSections.linkEquipment" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-equipment" v-smooth-scroll="{ offset: -100, updateHistory: false }">Safety Equipment Register</a></li>
          <li v-if="visibleSections.linkCrew" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-crew" v-smooth-scroll="{ offset: -100, updateHistory: false }">Crew Profiles</a></li>
          <li v-if="showActivityLink" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;">Recent activity
            <ul style="list-style: disc inside;margin-left: 1rem;margin-top: 8px;">
              <li v-if="visibleSections.linkDefects" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-defects" v-smooth-scroll="{ offset: -100, updateHistory: false }">Vessel Defects</a></li>
              <li v-if="visibleSections.linkInductions" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-inductions" v-smooth-scroll="{ offset: -100, updateHistory: false }">Crew Inductions</a></li>
              <li v-if="visibleSections.linkStartups" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-startups" v-smooth-scroll="{ offset: -100, updateHistory: false }">Startup Logs</a></li>
              <li v-if="visibleSections.linkMaintLogs" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-maint-logs" v-smooth-scroll="{ offset: -100, updateHistory: false }">General Maintenance</a></li>
              <li v-if="visibleSections.linkShipsLogs" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-ships-logs" v-smooth-scroll="{ offset: -100, updateHistory: false }">Ship's Logs</a></li>
              <li v-if="visibleSections.linkIncidents" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-incidents" v-smooth-scroll="{ offset: -100, updateHistory: false }">Incidents &amp; Near Misses</a></li>
              <li v-if="visibleSections.linkRevisions" style="color: dodgerblue;margin-bottom: 4px;font-weight: bold;"><a href="#link-revisions" v-smooth-scroll="{ offset: -100, updateHistory: false }">Revision History</a></li>
            </ul>
          </li>
        </ul>

        <hr>



        <div class="section-wrapper" v-if="visibleSections.linkOwnerInfo" :class="{'hideForPrint': visibleSections.linkOwnerInfo == false}">
          <h3 id="link-owner-info"
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="briefcase" />&nbsp; Vessel Owner Information <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkOwnerInfo = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <td
                  style="width:30% !important;font-weight: bold !important;font-size: 0.9rem;line-height: 1.8rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Owner name</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ details.vesselOwnerName }}</td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Phone number</td>
                <td contenteditable="true"
                  style="width: 50% !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-size: 0.9rem;line-height: 1.8rem;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ details.vesselOwnerPhone }}</td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Email address</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ details.vesselOwnerEmail }}</td>
              </tr>
              <tr v-if="!details.isCompanyVessel">
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Shore Contact Name</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ details.shoreContactName }}</td>
              </tr>
              <tr v-if="!details.isCompanyVessel">
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Shore Contact Number</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ details.shoreContactNumber }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        


        <div class="section-wrapper" v-if="visibleSections.linkOverview" :class="{'hideForPrint': visibleSections.linkOverview == false}">
          <h3 id="link-overview"
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="ship" />&nbsp; Vessel Overview <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkOverview = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <td
                  style="width:30% !important;font-weight: bold !important;font-size: 0.9rem;line-height: 1.8rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Vessel name</td>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ details.vesselname }}</td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Vessel Identifier</td>
                <td
                  style="width: 50% !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-size: 0.9rem;line-height: 1.8rem;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ details.uvi }}</td>
              </tr>
              <!-- <tr>
                <td style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Vessel MARKS</td>
                <td style="width: 50% !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-size: 0.9rem;line-height: 1.8rem;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{details.marks}}</td>
              </tr> -->
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Description</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <!-- {{details.description}} -->
                  <div v-for="(line, ix) in details.description.split('\n')" :key="`lines1-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Home Port</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ details.homePort }}</td>
              </tr>


              <!-- <tr>
                <td style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Area of Operation</td>
                <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{details.operationalArea}}</td>
              </tr> -->
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Vessel Class Info</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <!-- <ul>
                    <li v-for="classA in details.surveyClasses" :key="classA">{{classA}}</li>
                  </ul> -->
                  <div v-for="(line, ix) in details.vesselClass.split('\n')" :key="`lines2${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Operational Area</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <!-- <ul>
                    <li v-for="area in details.operationalAreas" :key="area">{{area}}</li>
                  </ul> -->
                  <div v-for="(line, ix) in details.vesselOperationalArea.split('\n')" :key="`lines3-${ix}`">{{ line }}<br></div>
                </td>
              </tr>

              <tr v-for="(field, idx) in details.customFields" :key="`field-${idx}`">
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ field.label }}</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div>{{ field.value }}</div>
                </td>
              </tr>

              <!-- <tr>
                <td style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">State/s</td>
                <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <ul>
                    <li v-for="state in details.vesselStates" :key="state">{{state}}</li>
                  </ul>
                </td>
              </tr> -->
              <!-- <tr>
                <td style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Industries</td>
                <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <ul>
                    <li v-for="industry in details.vesselIndustries" :key="industry">{{industry}}</li>
                  </ul>
                </td>
              </tr> -->
              <!-- <tr>
                <td style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Hull</td>
                <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{details.material}}</td>
              </tr>
              <tr>
                <td style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Length</td>
                <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{details.length}}</td>
              </tr> -->
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Propulsion</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in details.propulsion.split('\n')" :key="`lines4-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Minimum Crew</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ details.minCrew }}</td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Appropriate Crew</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ details.appropriateCrew }}</td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Crewing Determination</td>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in details.appCrewExplanation.split('\n')" :key="`lines5-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr v-if="details.avatarUrl != ''">
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Vessel Photo</td>
                <td 
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <img style="max-width: 500px; width: 100%;" class="fleetLogo" :src="details.avatarUrl" />
                </td>
              </tr>
            </tbody>
          </table>



          <!-- <div v-if="details.avatarUrl != ''">
            <div class="spacer s10"></div>
            <img style="max-width: 300px; width: auto;" class="fleetLogo" :src="details.avatarUrl" />
          </div> -->

          
        </div>
        


        <div class="section-wrapper" v-if="visibleSections.linkSpecs" :class="{'hideForPrint': visibleSections.linkSpecs == false}">
          <h3 id="link-specs"
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem; display:inline-block;">
            <font-awesome-icon icon="cog" />&nbsp; Vessel Specifications <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkSpecs = false">Remove Section</span></h3>
            
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <div v-for="(field, inx) in specFields" :key="`specs1-${inx}`">
                <tr v-if="field.isHeading || inx == 0" style="display: inline-table; width: 100%;">
                  <td colspan="2"
                    style="background: #f2f2f2;font-weight: bold !important;font-size: 0.9rem;line-height: 1.8rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    {{ field.label }}
                  </td>
                </tr>
                <tr v-else style="display: inline-table; width: 100%;">
                  <td
                    style="font-size: 0.9rem;line-height: 1.8rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    {{ field.label }}
                  </td>
                  <td
                    style="width: 70% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    <div v-for="(line, ix) in field.content.split('\n')" :key="`lines6-${ix}`">{{line}}<br></div>
                  </td>
                </tr>
              </div>
              <!-- <tr v-if="field.isHeading || inx == 0" v-for="(field, inx) in specFields" :key="`specs1-${inx}`">
                <td colspan="2"
                  style="background: #f2f2f2;font-weight: bold !important;font-size: 0.9rem;line-height: 1.8rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ field.label }}
                </td>
              </tr>
              <tr v-else>
                <td
                  style="font-size: 0.9rem;line-height: 1.8rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ field.label }}
                </td>
                <td
                  style="width: 70% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  <div v-for="(line, ix) in field.content.split('\n')" :key="`lines6-${ix}`">{{line}}<br></div>
                </td>
              </tr> -->
            </tbody>
          </table>  
          <div class="spacer s10"></div>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkStatements" :class="{'hideForPrint': visibleSections.linkStatements == false}">
          <div id="link-statements"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="pen" />&nbsp; Vessel Owner Statement of Responsibility <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkStatements = false">Remove All Statements</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <!-- {{details.ownerStatement}} -->
                  <div v-for="(line, ix) in details.ownerStatement.split('\n')" :key="`lines10-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  Vessel Owner is: {{ details.vesselOwnerName }}</td>
              </tr>
            </tbody>
          </table>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="pen" />&nbsp; Designated Person's Statement of Responsibility</h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <!-- {{details.designatedStatement}} -->
                  <div v-for="(line, ix) in details.designatedStatement.split('\n')" :key="`lines11-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <td contenteditable="true"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  Designated Person is: {{ details.designatedPerson }}</td>
              </tr>
            </tbody>
          </table>

          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="pen" />&nbsp; {{ details.savedRoles[2] }} Statement of Responsibility</h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <!-- {{details.masterStatement}} -->
                  <div v-for="(line, ix) in details.masterStatement.split('\n')" :key="`lines12-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <!-- <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">Vessel Master is: {{details.vesselMaster}}</td> -->
                <div style="margin-left: 1rem;">
                  <div class="spacer s10"></div>
                  <div v-if="details.vesselMasters.length > 0" class="vessel-owner"><span
                      class="bold caps">{{ details.savedRoles[2] }} List:</span></div>
                  <ul class="members">
                    <li v-for="member in details.vesselMasters" :key="member.id">{{ member.name }}</li>
                  </ul>
                  <!-- <div v-if="details.vesselMasters.length == 0">None found.</div> -->
                  <!-- <div class="spacer s10"></div> -->
                </div>
              </tr>
            </tbody>
          </table>

          <div v-if="details.roleTwoStatement != 'None'">
            <h3
              style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
              <font-awesome-icon icon="pen" />&nbsp; {{ details.savedRoles[1] }} Statement of Responsibility</h3>
            <table
              style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
              class="table is-bordered is-fullwidth">
              <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                <tr>
                  <td
                    style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                    <!-- {{details.masterStatement}} -->
                    <div v-for="(line, ix) in details.roleTwoStatement.split('\n')" :key="`lines13-${ix}`">{{ line }}<br></div>
                  </td>
                </tr>
                <tr>
                  <!-- <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">Vessel Master is: {{details.vesselMaster}}</td> -->
                  <div style="margin-left: 1rem;">
                    <div class="spacer s10"></div>
                    <div v-if="details.vesselRoleTwos.length > 0" class="vessel-owner"><span
                        class="bold caps">{{ details.savedRoles[1] }} List:</span></div>
                    <ul class="members">
                      <li v-for="member in details.vesselRoleTwos" :key="member.id">{{ member.name }}</li>
                    </ul>
                    <!-- <div v-if="details.vesselMasters.length == 0">None found.</div> -->
                    <!-- <div class="spacer s10"></div> -->
                  </div>
                </tr>
              </tbody>
            </table>
          </div>

          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="pen" />&nbsp; {{ details.savedRoles[0] }} Statement of Responsibility</h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <!-- {{details.masterStatement}} -->
                  <div v-for="(line, ix) in details.crewStatement.split('\n')" :key="`lines14-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <!-- <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">Vessel Master is: {{details.vesselMaster}}</td> -->
                <div style="margin-left: 1rem;">
                  <div class="spacer s10"></div>
                  <div v-if="details.vesselCrew.length > 0" class="vessel-owner"><span
                      class="bold caps">{{ details.savedRoles[0] }} List:</span></div>
                  <ul class="members">
                    <li v-for="member in details.vesselCrew" :key="member.id">{{ member.name }}</li>
                  </ul>
                  <!-- <div v-if="details.vesselMasters.length == 0">None found.</div> -->
                  <!-- <div class="spacer s10"></div> -->
                </div>
              </tr>
            </tbody>
          </table>

          <div v-if="details.customStatement.length > 0">
            <h3
              style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
              <font-awesome-icon icon="pen" />&nbsp; Other Statement</h3>
            <table
              style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
              class="table is-bordered is-fullwidth">
              <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                <tr>
                  <td
                    style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                    <!-- {{details.lineOfComms}} -->
                    <div v-for="(line, ix) in details.customStatement.split('\n')" :key="`lines15-${ix}`">{{ line }}<br></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="pen" />&nbsp; Line of Communication</h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <!-- {{details.lineOfComms}} -->
                  <div v-for="(line, ix) in details.lineOfComms.split('\n')" :key="`lines16-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        

        


        <div class="section-wrapper" v-if="visibleSections.linkContacts" :class="{'hideForPrint': visibleSections.linkContacts == false}">
          <div id="link-contacts"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="users" />&nbsp; Vessel Contacts <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkContacts = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Name</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Details</th>
              </tr>
              <tr v-for="contact in sorted(vesselContacts)" :key="contact.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ contact.title }}</th>
                <td
                  style="font-size: 0.9rem;line-height: 1.8rem;width: 50% !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in contact.details.split('\n')" :key="`lines17-${ix}`">
                    <div v-html="line"></div>
                  </div>
                  <div class="link" v-show="contact.link"><a :href="contact.link" target="_blank"
                      class="simple-link">{{ contact.link }}</a></div>
                  <div class="spacer s30"></div>
                  <div v-if="contact.uploadUrls.length > 0" class="image-div">
                    <ul class="uploads">
                      <li class="image-wrap" v-for="image in contact.uploadUrls" :key="image.id"
                        style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                        <img :src="image" />
                      </li>
                    </ul>
                  </div>
                  <div v-if="contact.versioned">
                    <!-- <div class="tag">Last Edit: {{ formatDate(contact.versionDate, 'D MMMM YYYY (h:mm a)') }} ({{ contact.savedByName }})</div> -->
                    <div class="tag">Last Edit: {{ formatSimpleDate(contact.versionDate) }} ({{ contact.savedByName }})</div>
                    <div class="spacer s10"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        


        <div class="section-wrapper" v-if="visibleSections.linkHazards" :class="{'hideForPrint': visibleSections.linkHazards == false}">
          <div id="link-hazards"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="exclamation-triangle" />&nbsp; Vessel Hazards & Risk Assessments <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkHazards = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            v-for="hazard in sorted(vesselHazards)" :key="hazard.id" class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <td
                  style="width:30% !important;font-weight: bold !important; font-size: 0.9rem;line-height: 1.8rem;text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Hazard name</td>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ hazard.title }}</td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Possible Risks</td>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in hazard.risks.split('\n')" :key="`lines18-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Controls in place</td>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in hazard.controls.split('\n')" :key="`lines19-${ix}`">{{ line }}<br></div>
                </td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Likelihood / Consequence</td>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <span style="text-transform: uppercase;">{{ hazard.likelihood }} / {{ hazard.consequence }}</span>
                </td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Residual Risk Rating</td>
                <td
                  :style="{ 'background-color': hazard.rating == 'high' ? 'red' : hazard.rating == 'medium' ? 'orange' : 'green' }"
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;text-transform: uppercase !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important; color: #fff;">
                  {{ hazard.rating }}</td>
              </tr>
              <tr>
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Responsibility</td>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ hazard.personResponsible }}</td>
              </tr>
              <tr v-if="hazard.uploadUrls.length > 0">
                <td>Pictures</td>
                <td>
                  <div class="spacer s30"></div>
                  <div class="image-div">
                    <ul class="uploads">
                      <li class="image-wrap" v-for="image in hazard.uploadUrls" :key="image.id"
                        style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                        <img :src="image" />
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr v-if="hazard.versioned">
                <td
                  style="font-weight: normal !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Last Edit
                </td>
                <td>
                  <div>
                    <!-- <div class="tag">{{ formatDate(hazard.versionDate, 'D MMMM YYYY (h:mm a)') }} ({{ hazard.savedByName }})</div> -->
                    <div class="tag">{{ formatSimpleDate(hazard.versionDate) }} ({{ hazard.savedByName }})</div>
                    <div class="spacer s10"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkPolicies" :class="{'hideForPrint': visibleSections.linkPolicies == false}">
          <div id="link-policies"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="list-ul" />&nbsp; Vessel Policies <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkPolicies = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Title</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Details</th>
              </tr>
              <tr v-for="policy in sorted(vesselPolicies)" :key="policy.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;ont-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{ policy.title }}</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in policy.details.split('\n')" :key="`lines20-${ix}`">{{ line }}<br></div>
                  <div class="link" v-show="policy.link"><a :href="policy.link" target="_blank"
                      class="simple-link">{{ policy.link }}</a></div>
                  <div class="spacer s30"></div>
                  <div v-if="policy.uploadUrls.length > 0" class="image-div">
                    <ul class="uploads">
                      <li class="image-wrap" v-for="image in policy.uploadUrls" :key="image.id"
                        style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                        <img :src="image" />
                      </li>
                    </ul>
                  </div>
                  <div v-if="policy.versioned">
                    <!-- <div class="tag">Last Edit: {{ formatDate(policy.versionDate, 'D MMMM YYYY (h:mm a)') }} ({{ policy.savedByName }})</div> -->
                    <div class="tag">Last Edit: {{ formatSimpleDate(policy.versionDate) }} ({{ policy.savedByName }})</div>
                    <div class="spacer s10"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        





        <!-- <div>
          <h3 style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">Important Dates</h3>
          <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Title</th>
                <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Date</th>
              </tr>
              <tr v-for="date in importantDates">
                <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{date.title}}</th>
                <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  {{date.humanDate}}
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="hideForPrint">
        </div> -->


        <div class="section-wrapper" v-if="visibleSections.linkSops" :class="{'hideForPrint': visibleSections.linkSops == false}">
          <div id="link-sops"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="anchor" />&nbsp; Safe Work Procedures <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkSops = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Title</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Details</th>
              </tr>
              <tr v-for="sop in sorted(vesselSOPS)" :key="sop.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ sop.title }}</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in sop.details.split('\n')" :key="`lines21-${ix}`">{{ line }}<br></div>
                  <div class="link" v-show="sop.link"><a :href="sop.link" target="_blank"
                      class="simple-link">{{ sop.link }}</a></div>
                  <div class="spacer s30"></div>
                  <div v-if="sop.uploadUrls.length > 0" class="image-div">
                    <ul class="uploads">
                      <li class="image-wrap" v-for="image in sop.uploadUrls" :key="image.id"
                        style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                        <img :src="image" />
                      </li>
                    </ul>
                  </div>
                  <div v-if="sop.versioned">
                    <!-- <div class="tag">Last Edit: {{ formatDate(sop.versionDate, 'D MMMM YYYY (h:mm a)') }} ({{ sop.savedByName }})</div> -->
                    <div class="tag">Last Edit: {{ formatSimpleDate(sop.versionDate) }} ({{ sop.savedByName }})</div>
                    <div class="spacer s10"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        



        <div class="section-wrapper" v-if="visibleSections.linkEops" :class="{'hideForPrint': visibleSections.linkEops == false}">
          <div id="link-eops"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="life-ring" />&nbsp; Emergency Procedures <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkEops = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Title</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Details</th>
              </tr>
              <tr v-for="eop in sorted(vesselEOPS)" :key="eop.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ eop.title }}</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in eop.details.split('\n')" :key="`lines22-${ix}`">{{ line }}<br></div>
                  <div class="link" v-show="eop.link"><a :href="eop.link" target="_blank"
                      class="simple-link">{{ eop.link }}</a></div>
                  <div class="spacer s30"></div>
                  <div v-if="eop.uploadUrls.length > 0" class="image-div">
                    <ul class="uploads">
                      <li class="image-wrap" v-for="image in eop.uploadUrls" :key="image.id"
                        style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                        <img :src="image" />
                      </li>
                    </ul>
                  </div>
                  <div v-if="eop.versioned">
                    <!-- <div class="tag">Last Edit: {{ formatDate(eop.versionDate, 'D MMMM YYYY (h:mm a)') }} ({{ eop.savedByName }})</div> -->
                    <div class="tag">Last Edit: {{ formatSimpleDate(eop.versionDate) }} ({{ eop.savedByName }})</div>
                    <div class="spacer s10"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        



        <div class="section-wrapper" v-if="visibleSections.linkScheds" :class="{'hideForPrint': visibleSections.linkScheds == false}">
          <div id="link-scheds"></div>
          <div v-if="vesselSchedules.length && details.isCompanyVessel">

            <h3
              style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
              <font-awesome-icon icon="calendar-check" />&nbsp; Schedules &amp; Drills <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkScheds = false">Remove Section</span></h3>
            <table
              style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
              class="table is-bordered is-fullwidth">
              <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                <tr>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Drill</th>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Due Date</th>
                </tr>
                <tr v-for="item in vesselSchedules" :key="item.title">
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    {{ item.title }}</th>
                  <td
                    style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                    {{ formatDate(item.dueDate, 'D MMMM YYYY') }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <router-link class="button is-info hideForPrint" to="company-edit-sms">Edit Custom Checklists</router-link> -->
            <hr class="hideForPrint">

          </div>
        </div>
        


        <div class="section-wrapper" v-if="visibleSections.linkMaintenance" :class="{'hideForPrint': visibleSections.linkMaintenance == false}">
          <div id="link-maintenance"></div>
          <div>
            <h3
              style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
              <font-awesome-icon icon="wrench" />&nbsp; Maintenance Checklist <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkMaintenance = false">Remove Section</span></h3>
            <table
              style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
              class="table is-bordered is-fullwidth">
              <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                <tr>
                  <th
                    style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Title</th>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Details</th>
                </tr>
                <tr v-for="item in sorted(maintenanceItems)" :key="item.id">
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    {{ item.title }}</th>
                  <td
                    style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                    <div v-for="(line, ix) in item.details.split('\n')" :key="`lines23-${ix}`">{{ line }}<br></div>
                    <div class="spacer s30"></div>
                    <div v-if="item.uploadUrls.length > 0" class="image-div">
                      <ul class="uploads">
                        <li class="image-wrap" v-for="image in item.uploadUrls" :key="image.id"
                          style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                          <img :src="image" />
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        


        <div class="section-wrapper" v-if="visibleSections.linkAssets" :class="{'hideForPrint': visibleSections.linkAssets == false}">
          <div id="link-assets"></div>
          <div v-if="vesselPlant.length && details.isCompanyVessel">

            <h3
              style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
              <font-awesome-icon icon="cog" />&nbsp; Plant Asset Register <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkAssets = false">Remove Section</span></h3>
            <table
              style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
              class="table is-bordered is-fullwidth">
              <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                <tr>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Plant or Equipment Item</th>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Next Schedule</th>
                </tr>
                <tr v-for="item in vesselPlant" :key="item.title">
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    {{ item.title }}</th>
                  <td v-if="item.trackHours"
                    style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                    {{ item.dueHours }} hours
                  </td>
                  <td v-else
                    style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                    No schedule
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <router-link class="button is-info hideForPrint" to="company-edit-sms">Edit Custom Checklists</router-link> -->
            <hr class="hideForPrint">

          </div>
        </div>
        


        <div class="section-wrapper" v-if="visibleSections.linkStartupChecks" :class="{'hideForPrint': visibleSections.linkStartupChecks == false}">
          <div id="link-startup-checks"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="check-circle" />&nbsp; Pre Departure Safety Checklist <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkStartupChecks = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Title</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Details</th>
              </tr>
              <tr v-for="item in sorted(startupItems)" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ item.title }}</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in item.details.split('\n')" :key="`lines24-${ix}`">{{ line }}<br></div>
                  <div class="spacer s30"></div>
                  <div v-if="item.uploadUrls.length > 0" class="image-div">
                    <ul class="uploads">
                      <li class="image-wrap" v-for="image in item.uploadUrls" :key="image.id"
                        style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                        <img :src="image" />
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkInductionChecks" :class="{'hideForPrint': visibleSections.linkInductionChecks == false}">
          <div id="link-induction-checks"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="users" />&nbsp; Crew Induction Checklist <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkInductionChecks = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Title</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Details</th>
              </tr>
              <tr v-for="item in sorted(inductionItems)" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ item.title }}</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in item.details.split('\n')" :key="`lines25-${ix}`">{{ line }}<br></div>
                  <div class="link" v-show="item.link"><a :href="item.link" target="_blank"
                      class="simple-link">{{ item.link }}</a></div>
                  <div class="spacer s30"></div>
                  <div v-if="item.uploadUrls.length > 0" class="image-div">
                    <ul class="uploads">
                      <li class="image-wrap" v-for="image in item.uploadUrls" :key="image.id"
                        style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                        <img :src="image" />
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkBriefingChecks" :class="{'hideForPrint': visibleSections.linkBriefingChecks == false}">
          <div id="link-briefing-checks"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="users" />&nbsp; General Safety Briefing Checklist <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkBriefingChecks = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Title</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  Details</th>
              </tr>
              <tr v-for="item in sorted(passengerBriefings)" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                  {{ item.title }}</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                  <div v-for="(line, ix) in item.details.split('\n')" :key="`lines26-${ix}`">{{ line }}<br></div>
                  <div class="link" v-show="item.link"><a :href="item.link" target="_blank"
                      class="simple-link">{{ item.link }}</a></div>
                  <div class="spacer s30"></div>
                  <div v-if="item.uploadUrls.length > 0" class="image-div">
                    <ul class="uploads">
                      <li class="image-wrap" v-for="image in item.uploadUrls" :key="image.id"
                        style="display: inline-block; margin-bottom: 2rem; margin-right: 1rem;">
                        <img :src="image" />
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkCustomLogs" :class="{'hideForPrint': visibleSections.linkCustomLogs == false}">
          <div id="link-custom-logs"></div>
          <div v-for="list in customChecklists" :key="list.name">
            <h3
              style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
              <font-awesome-icon icon="ship" />&nbsp; {{ list.name }} <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkCustomLogs = false">Remove Custom Logs Section</span></h3>
            <table
              style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
              class="table is-bordered is-fullwidth">
              <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                <tr>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Field Type</th>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Field Label</th>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Options</th>
                </tr>
                <tr v-for="item in sorted(list.checklistFields)" :key="item.id">
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    {{ item.type }}</th>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    {{ item.label }}</th>
                  <td
                    style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                    <ul style="list-style: disc outside; margin-left: 15px;">
                      <li v-for="option in item.options" :key="option">{{ option }}</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        


        <div class="section-wrapper" v-if="visibleSections.linkEquipment" :class="{'hideForPrint': visibleSections.linkEquipment == false}">
          <div id="link-equipment"></div>
          <div v-if="vesselEquipment.length > 0">
            <h3
              style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
              <font-awesome-icon icon="star" />&nbsp; Safety Equipment Register <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkEquipment = false">Remove Section</span></h3>
            <table
              style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
              class="table is-bordered is-fullwidth">
              <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                <tr>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Equipment</th>
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    Expiry Date</th>
                </tr>
                <tr v-for="date in vesselEquipment" :key="date.title">
                  <th
                    style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                    {{ date.title }}</th>
                  <td
                    style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">
                    {{ date.humanDate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        



        <!-- <h3 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">Crew List</h3>
        <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" v-for="crew in crewProfiles" class="table is-bordered is-fullwidth">
          <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
            <tr>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Name</th>
              <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{crew.crewName}}</td>
            </tr>
            <tr>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Phone number</th>
              <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{crew.phone}}</td>
            </tr>
            <tr>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Home Port</th>
              <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{crew.homeport}}</td>
            </tr>
            <tr>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Address</th>
              <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{crew.address}}</td>
            </tr>
            <tr>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Notes</th>
              <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{crew.qualifications}}</td>
            </tr>
            <tr>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Emergency Info</th>
              <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{crew.emergencyInfo}}</td>
            </tr>
            <tr>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Key duties</th>
              <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{crew.keyDuties}}</td>
            </tr>
          </tbody>
        </table>
        <br> -->

        <!-- <div class="html2pdf__page-break"/> -->


        <div class="section-wrapper" v-if="visibleSections.linkCrew" :class="{'hideForPrint': visibleSections.linkCrew == false}">
          <div id="link-crew"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="users" />&nbsp; Crew Profiles <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkCrew = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            v-for="crew in crewProfiles" class="table is-bordered is-fullwidth" :key="crew.crewName">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="width:30% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Name</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ crew.crewName }}</td>
              </tr>
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Phone number</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">
                  {{ crew.phone }}</td>
              </tr>
              <!-- <tr>
                <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">State</th>
                <td style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">{{crew.state}}</td>
              </tr> -->
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Home Port</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">
                  {{ crew.homeport }}</td>
              </tr>
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Address</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">
                  {{ crew.address }}</td>
              </tr>
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Notes</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">
                  {{ crew.qualifications }}</td>
              </tr>
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Emergency Info</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">
                  {{ crew.emergencyInfo }}</td>
              </tr>
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Emergency Contacts</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">
                  {{ crew.emergencyContacts }}</td>
              </tr>
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Key duties (Admin)</th>
                <td
                  style="width: 50% !important;font-size: 0.9rem;line-height: 1.8rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">
                  {{ crew.keyDuties }}</td>
              </tr>
              <tr v-if="crew.avatarUrl != ''">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Picture</th>
                <td><img style="max-width: 150px;" :src="crew.avatarUrl" /></td>
              </tr>
            </tbody>
          </table>
          <br>
          <!-- <router-link class="button is-info hideForPrint" :to="!isCompanyVessel ? 'my-crew' : 'staff'">Manage Vessel Crew</router-link> -->
          <hr v-if="!hideForPrint" class="hideForPrint">
        </div>
        






        <!-- <div class="pagebreak" style="display: block !important;clear: both !important;page-break-after: always !important;"></div> -->
        <!-- <h3 style="font-family: 'helvetica', sans-serif;color: #444;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">Current Alerts</h3>
        <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
          <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
            <tr>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">Alert</th>
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">Due Date</th>
            </tr>
            <tr v-for="alert in myAlerts" :key="alert.id">
              <th style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">{{alert.title}}</th>
              <td style="font-size: 0.9rem;line-height: 1.8rem;width: 50% !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif; text-align: left !important;">{{alert.remaining}}</td>
            </tr>
          </tbody>
        </table>
        <router-link class="button is-info hideForPrint" to="my-alerts">Add Alerts</router-link>
        <hr class="hideForPrint"> -->

        <!-- <div class="html2pdf__page-break"/> -->


        <div class="section-wrapper" v-if="visibleSections.linkDefects" :class="{'hideForPrint': visibleSections.linkDefects == false}">
          <div id="link-defects"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="wrench" />&nbsp; Vessel Defects <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkDefects = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Defect</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Resolved</th>
              </tr>
              <tr v-for="item in vesselDefects" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.defect }}</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.resolved }}</th>
              </tr>
              <tr v-if="vesselDefects.length == 0">
                <td colspan="2">No defects recorded.</td>
              </tr>
            </tbody>
          </table>
        </div>

        
        <div class="section-wrapper" v-if="visibleSections.linkInductions" :class="{'hideForPrint': visibleSections.linkInductions == false}">
          <div id="link-inductions"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="users" />&nbsp; Recent Inductions <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkInductions = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Crew Name</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Induction Date</th>
              </tr>
              <tr v-for="item in recentInductions" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.crewName }}</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.timestamp }}</th>
              </tr>
              <tr v-if="recentInductions.length == 0">
                <td colspan="2">No inductions recorded.</td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkStartups" :class="{'hideForPrint': visibleSections.linkStartups == false}">
          <div id="link-startups"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="ship" />&nbsp; Recent Startup Logs <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkStartups = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Date</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Port of Departure</th>
              </tr>
              <tr v-for="item in loggedStartups" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.logDate }}</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.port }}</th>
              </tr>
              <tr v-if="loggedStartups.length == 0">
                <td colspan="2">No startup logs recorded.</td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkMaintLogs" :class="{'hideForPrint': visibleSections.linkMaintLogs == false}">
          <div id="link-maint-logs"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="wrench" />&nbsp; Recent Maintenance Logs <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkMaintLogs = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Date</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Logged By</th>
              </tr>
              <tr v-for="item in loggedMaintenance" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.logDate }}</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.postedBy }}</th>
              </tr>
              <tr v-if="loggedMaintenance.length == 0">
                <td colspan="2">No maintenance logs recorded.</td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkShipsLogs" :class="{'hideForPrint': visibleSections.linkShipsLogs == false}">
          <div id="link-ships-logs"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="ship" />&nbsp; Recent Ships Logs <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkShipsLogs = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Date</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Log Details</th>
              </tr>
              <tr v-for="item in loggedGenerals" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.logDate }}</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.logDetails }} <span v-if="item.archived">(Archived)</span></th>
              </tr>
              <tr v-if="loggedGenerals.length == 0">
                <td colspan="2">No ship's logs recorded.</td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkIncidents" :class="{'hideForPrint': visibleSections.linkIncidents == false}">
          <div id="link-incidents"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="exclamation-triangle" />&nbsp; Recent Incidents & Near Misses <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkIncidents = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Date</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Type of incident</th>
              </tr>
              <tr v-for="item in loggedIncidents" :key="item.id">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.logDate }}</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.incidentType }}</th>
              </tr>
              <tr v-if="loggedIncidents.length == 0">
                <td colspan="2">No incidents or near misses recorded.</td>
              </tr>
            </tbody>
          </table>
        </div>
        

        <div class="section-wrapper" v-if="visibleSections.linkRevisions" :class="{'hideForPrint': visibleSections.linkRevisions == false}">
          <div id="link-revisions"></div>
          <h3
            style="font-family: 'Open Sans', sans-serif;color: cadetblue;font-weight: bold;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;">
            <font-awesome-icon icon="list-ul" />&nbsp; Revision History <span class="button is-small is-delete hideForPrint" @click="visibleSections.linkRevisions = false">Remove Section</span></h3>
          <table
            style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
            class="table is-bordered is-fullwidth">
            <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
              <tr>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Date</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Logged By</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  Description</th>
              </tr>
              <tr v-for="(item, inx) in revisions" :key="`revision-${inx}`">
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ formatDate(item.timestamp, 'D MMMM, YYYY (h:mm a)') }}</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.userName }}</th>
                <th
                  style="font-size: 0.9rem;line-height: 1.8rem;font-weight: normal !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                  {{ item.revision }}</th>
              </tr>
            </tbody>
          </table>
        </div>








        <!-- <span id="pdfButton" class="button is-primary" @click="savePDF()">{{buttonText}}</span> -->

      </div>

      <div class="container" v-else>
        <p>No vessel detected.</p>
        <p>&nbsp;</p>
        <!-- <router-link v-if="firebaseid != ''" to="my-vessels">
          <span class="button is-primary is-medium">Back to My Vessels</span>
        </router-link>
        <router-link v-else to="fast-track-start">
          <span class="button is-dark is-medium">Start New FastTrack SMS</span>
        </router-link> -->
      </div>

      <div class="spacer s50"></div>


    </div>
  </div>
</template>

<script>

import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import NProgress from 'nprogress';
// import * as moment from 'moment';
// import VueHtml2pdf from 'vue-html2pdf';

export default {

  name: 'FastTrackViewSms',

  props: {
    sentVesselID: String,
    sentVesselName: String,
    showBack: Boolean,

  },
  
  components: {
    // VueHtml2pdf,
  },

  computed: {
    // userProfile() {
    //   return this.$store.getters.getUserProfile;
    // },
    showActivityLink() {
      return this.visibleSections.linkDefects || this.visibleSections.linkInductions || this.visibleSections.linkStartups || this.visibleSections.linkMaintLogs || this.visibleSections.linkShipsLogs || this.visibleSections.linkIncidents || this.visibleSections.linkRevisions;
    },
    today() {
      const dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'};
      // const t = new Date();
      return new Date().toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
    },
    

  },

  mounted: function() {
    // console.log(this.$route);
    // firebase.auth().onAuthStateChanged(this.onUserLogin);

    let urlParams = new URLSearchParams(window.location.search);
    // let vid = urlParams.get('vid');
    this.queryID = urlParams.get('vid');

    // if (this.queryID != '') {
    //   console.log('Have query VID');
    //   this.loadQueryVesselID();
    // } else {
    //   console.log('Need fast track vid');
    //   this.loadVesselID();
    // }



    this.finalVesselID = this.sentVesselID ? this.sentVesselID : this.$route.query.vid ? this.$route.query.vid : '';
    if (this.finalVesselID != '') {
      // console.log(this.finalVesselID);
      this.loadVesselData();
      this.loadVesselEquipment();  
      // this.loadVesselCrew();
      // this.loadVesselSchedules();
      // this.loadVesselPlant();
    }     
    // this.today = moment().format('D MMMM YYYY');
    // this.today = new Date().toDateString();
  },

  data: function() {
    return {
      queryID: '',
      firebaseid: '',
      firebaseemail: '',
      firebasedisplayname: '',

      finalVesselID: '',

      details: {
        vesselname: '',
        uvi: '',
        description: '',
        length: '',
        material: '',
        colour: '',
        propulsion: '',
        appropriateCrew: '',
        appCrewExplanation: '',
        minCrew: '',
        ownerStatement: '',
        masterStatement: '',
        roleTwoStatement: '',
        crewStatement: '',
        designatedStatement: '',
        operationalArea: '',
        lineOfComms: '',
        customStatement: '',
        snapshotIntro: '',
        vesselOwner: '',
        vesselMaster: '',
        designatedPerson: '',
        surveyClasses: [],
        operationalAreas: [],
        vesselIndustries: [],
        vesselStates: [],
        homePort: '',
        vesselOwnerName: '',
        vesselOwnerPhone: '',
        vesselOwnerEmail: '',
        shoreContactName: '',
        shoreContactNumber: '',
        crewName: '',
        crewNumber: '',
        vesselState: '',
        isCompanyVessel: false,
        vesselClass: '',
        vesselOperationalArea: '',
        customFields: [],
        savedRoles: ['Crew','Restricted Master','Master'],
        vesselMasterIDs: [],
        vesselRoleTwoIDs: [],
        vesselCrewIDs: [],
        vesselMasters: [],
        vesselRoleTwos: [],
        vesselCrew: [],
      },
      fleetLogo: '',

      owner: {
        fullName: '',
        email: '',
        phone: '',
        homePort: '',
        address: '',
        state: '',
        emergencyContacts: '',
        qualifications: '',
      },

      startupItems: [],
      maintenanceItems: [],
      inductionItems: [],
      passengerBriefings: [],

      vesselHazards: [],
      vesselContacts: [],
      vesselPolicies: [],
      vesselSOPS: [],
      vesselEOPS: [],
      customChecklists: [],

      crewIDList: [],
      crewProfiles: [],

      myCrewIDs: [],
      tempCrewIDs: [],
      myCrewProfiles: [],
      tempCrewProfiles: [],

      buttonText: 'Download PDF',

      // today: '',
      importantDates: [],
      vesselEquipment: [],

      vesselSchedules: [],
      vesselPlant: [],

      htmlToPdfOptions: {
        margin: 0.3,
     
        filename: `Tax Invoice.pdf`,
     
        image: {
          type: 'jpeg', 
          quality: 2
        },
     
        enableLinks: false,
     
        html2canvas: {
          scale: 1,
          useCORS: true
        },
     
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait'
        }
      },
      hideForPrint: false,

      revisions: [],
      loggedStartups: [],
      recentInductions: [],
      loggedMaintenance: [],
      loggedIncidents: [],
      loggedGenerals: [],
      vesselDefects: [],
      specFields: [],

      visibleSections: {
        linkOwnerInfo: true,
        linkOverview: true,
        linkSpecs: true,
        linkStatements: true,
        linkContacts: true,
        linkHazards: true,
        linkPolicies: true,
        linkSops: true,
        linkEops: true,
        linkScheds: true,
        linkMaintenance: true,
        linkAssets: true,
        linkStartupChecks: true,
        linkInductionChecks: true,
        linkBriefingChecks: true,
        linkCustomLogs: true,
        linkEquipment: true,
        linkCrew: true,
        linkDefects: true,
        linkInductions: true,
        linkStartups: true,
        linkMaintLogs: true,
        linkShipsLogs: true,
        linkIncidents: true,
        linkRevisions: true,
      }


    }
  },

  methods: {

    onUserLogin: function(user) {
      if (user) {
        this.firebaseid = user.uid;
      }
      
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress($event) {
      console.log($event);
    },

    formatDate : function (sentDate,format) {
      let fixDate = (sentDate ? sentDate.toDate() : new Date());
      // return moment(fixDate).format(format);
      return fixDate;
    },

    formatSimpleDate(sentDate) {
        if (sentDate == '') return '';
        // const dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
        const dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric'};
        const tempDate = new Date(sentDate.toDate());
        return tempDate.toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
    },
    

    
    sorted: function(arr) {
      // Set slice() to avoid to generate an infinite loop!
      return arr.slice().sort(function(a, b) {
        return a.index - b.index;
      });
    },

    // loadUserData: function() {
    //   let self = this;
    //   db.collection('userdata').doc(self.details.vesselOwnerID).onSnapshot(function(doc) {
    //     if (doc.exists) {
    //       self.owner.fullName = doc.data().username ? doc.data().username : '';
    //       self.owner.email = doc.data().useremail ? doc.data().useremail : '';
    //       self.owner.homePort = doc.data().homeport ? doc.data().homeport : '';
    //       self.owner.state = doc.data().userstate ? doc.data().userstate : '';
    //       self.owner.doesDiving = doc.data().doesDiving ? doc.data().doesDiving : '';
    //       self.owner.address = doc.data().address ? doc.data().address : '';
    //       self.owner.qualifications = doc.data().qualifications ? doc.data().qualifications : '';
    //       self.owner.emergencyContacts = doc.data().contacts ? doc.data().contacts : '';
    //     }
    //   });
    // },

    goBack: function() {
      this.$router.go(-1);
    },

    loadVesselEquipment() {
      let self = this;
      db.collection("vessels").doc(this.finalVesselID).collection("vesselEquipment").get().then(function(querySnapshot) {
        self.vesselEquipment = [];
        querySnapshot.forEach((doc) => {
          self.vesselEquipment.push({
            title: doc.data().title ? doc.data().title : '',
            humanDate: doc.data().humanDate ? doc.data().humanDate : '-',
          })
        });
      });
    },

    loadVesselSchedules() {
      let self = this;
      if (this.details.isCompanyVessel) db.collection('vessels').doc(this.finalVesselID).collection('vesselSchedules').orderBy('dueDate','asc').get().then(function(querySnapshot) {
        self.vesselSchedules = [];
        querySnapshot.forEach(function(doc) {
          self.vesselSchedules.push({
            id: doc.id,
            title: doc.data().title,
            dueDate: doc.data().dueDate,
            description: doc.data().description,
          });
        });
      });
    },

    loadVesselPlant() {
      let self = this;
      if (this.details.isCompanyVessel) db.collection('vessels').doc(this.finalVesselID).collection('vesselPlantAndEquipment').get().then(function(querySnapshot) {
        self.vesselPlant = [];
        querySnapshot.forEach(function(doc) {
          self.vesselPlant.push({
            id: doc.id,
            title: doc.data().title,
            trackHours: doc.data().trackHours ? doc.data().trackHours : false,
            dueHours: doc.data().dueHours ? doc.data().dueHours : 0,
            description: doc.data().description,
          });
        });
      });
    },

    loadCompanyData() {
      let self = this;
      db.collection('companies').doc(this.details.vesselOwnerID).get().then(function(doc) {
        if (doc.exists) {
          self.details.vesselOwnerName = doc.data().companyName ? doc.data().companyName : '';
          self.details.vesselOwnerEmail = doc.data().companyEmail ? doc.data().companyEmail : '';
          self.details.vesselOwnerPhone = doc.data().companyPhone ? doc.data().companyPhone : '';
          self.fleetLogo = doc.data().fleetLogo ? doc.data().fleetLogo : '';
        }
      });
    },

    // async loadVesselCrew() {

    //   let self = this;
    //   self.crewProfiles = [];

    //   await db.collection('vessels').doc(this.finalVesselID).get().then((doc) => {
    //     self.vesselCrewIDs = doc.data().listOfCrewIDs ? doc.data().listOfCrewIDs : [];
    //     self.savedCrewIDs = doc.data().listOfCrewIDs ? doc.data().listOfCrewIDs : [];
    //     self.savedMasterIDs = doc.data().listOfMasterIDs ? doc.data().listOfMasterIDs : [];
    //   }).then(async () => {
    //     await Promise.all(self.vesselCrewIDs.map(async (crewID, index) => {
    //       await db.collection('userdata').doc(crewID).get().then((docRef) => {
    //         self.crewProfiles.push({
    //           id: docRef.id,
    //           crewID: crewID,
    //           crewName: docRef.data().username ? docRef.data().username : 'No Name',
    //           phone: docRef.data().userphone ? docRef.data().userphone : '-',
    //           state: docRef.data().userstate ? docRef.data().userstate : '-',
    //           qualifications: (docRef.data().qualifications ? docRef.data().qualifications : '-'),
    //           address: docRef.data().address ? docRef.data().address : '-',
    //           contacts: docRef.data().contacts ? docRef.data().contacts : '-',
    //           emergencyInfo: docRef.data().emergencyInfo ? docRef.data().emergencyInfo : '-',
    //           homeport: docRef.data().homeport ? docRef.data().homeport : '-',
    //           keyDuties: docRef.data().keyDuties ? docRef.data().keyDuties : '-',
    //           avatarUrl: docRef.data().avatarUrl ? docRef.data().avatarUrl : '',
    //           isMaster: self.savedMasterIDs.includes(crewID) ? true : false,
    //         });
    //       });  
    //     }));
    //   });
      
    // },


    async loadVesselData() {
      let self = this;

      NProgress.start();

      // vessel data
      await db.collection('vessels').doc(this.finalVesselID).onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          var data = documentSnapshot.data();
          // console.log(data);
          self.details.vesselname = (data.vesselName ? data.vesselName : 'Unnamed');
          self.details.uvi = (data.vesselUVI ? data.vesselUVI : '');
          self.details.marks = (data.vesselDistMarks ? data.vesselDistMarks : '');
          self.details.description = (data.vesselDescription ? data.vesselDescription : '');
          self.details.length = (data.vesselLength ? data.vesselLength : '');
          self.details.material = (data.vesselMaterial ? data.vesselMaterial : '');
          self.details.colour = (data.vesselColour ? data.vesselColour : '');
          self.details.propulsion = (data.vesselPropulsion ? data.vesselPropulsion : '');
          self.details.operationalArea = (data.vesselOperationalArea ? data.vesselOperationalArea : '');
          self.details.minCrew = (data.vesselMinCrew ? data.vesselMinCrew : '');
          self.details.appropriateCrew = (data.vesselCrew ? data.vesselCrew : '');
          self.details.appCrewExplanation = (data.appCrewExplanation ? data.appCrewExplanation : '');
          self.details.ownerStatement = data.ownerStatement ? data.ownerStatement : 'None';
          self.details.masterStatement = data.masterStatement ? data.masterStatement : 'None';
          self.details.roleTwoStatement = data.roleTwoStatement ? data.roleTwoStatement : 'None';
          self.details.crewStatement = data.crewStatement ? data.crewStatement : 'None';
          self.details.customStatement = data.customStatement ? data.customStatement : '';
          self.details.lineOfComms = data.lineOfComms ? data.lineOfComms : 'None';
          self.details.snapshotIntro = data.snapshotIntro ? data.snapshotIntro : 'None';
          self.details.designatedStatement = data.designatedStatement ? data.designatedStatement : 'None';
          self.details.vesselOwnerName = data.vesselOwnerName ? data.vesselOwnerName : data.vesselOwner;
          self.details.vesselOwnerPhone = data.vesselOwnerPhone ? data.vesselOwnerPhone : '';
          self.details.vesselOwnerEmail = data.vesselOwnerEmail ? data.vesselOwnerEmail : '';
          self.details.vesselMaster = data.vesselMaster ? data.vesselMaster : '-';
          self.details.vesselOwnerID = data.vesselOwnerID ? data.vesselOwnerID : self.firebaseid;
          self.details.designatedPerson = data.designatedPerson ? data.designatedPerson : '-';
          self.details.vesselStates = data.vesselStates ? data.vesselStates : [];
          self.details.surveyClasses = (data.surveyClasses ? data.surveyClasses : []);
          self.details.operationalAreas = (data.operationalAreas ? data.operationalAreas : []);
          self.details.vesselIndustries = (data.vesselIndustries ? data.vesselIndustries : []);
          self.details.homePort = (data.homePort ? data.homePort : '');
          self.details.startupTags = (data.startupTags ? data.startupTags : []);
          self.details.savedCompanyActivityItems = data.savedCompanyActivityItems ? data.savedCompanyActivityItems : [];
          self.details.savedCompanyContacts = data.savedCompanyContacts ? data.savedCompanyContacts : [];
          self.details.savedCompanyEOPS = data.savedCompanyEOPS ? data.savedCompanyEOPS : [];
          self.details.savedCompanyHazards = data.savedCompanyHazards ? data.savedCompanyHazards : [];
          self.details.savedCompanyInductionItems = data.savedCompanyInductionItems ? data.savedCompanyInductionItems : [];
          self.details.savedCompanyPassengerInductionItems = data.savedCompanyPassengerInductionItems ? data.savedCompanyPassengerInductionItems : [];
          self.details.savedCompanyJSAItems = data.savedCompanyJSAItems ? data.savedCompanyJSAItems : [];
          self.details.savedCompanyMaintenanceItems = data.savedCompanyMaintenanceItems ? data.savedCompanyMaintenanceItems : [];
          self.details.savedCompanyPolicies = data.savedCompanyPolicies ? data.savedCompanyPolicies : [];
          self.details.savedCompanyShutdownItems = data.savedCompanyShutdownItems ? data.savedCompanyShutdownItems : [];
          self.details.savedCompanyStartupItems = data.savedCompanyStartupItems ? data.savedCompanyStartupItems : [];
          self.details.savedCompanySOPS = data.savedCompanySOPS ? data.savedCompanySOPS : [];
          self.details.shoreContactName = data.shoreContactName ? data.shoreContactName : '';
          self.details.shoreContactNumber = data.shoreContactNumber ? data.shoreContactNumber : '';
          self.details.isCompanyVessel = data.isCompanyVessel ? data.isCompanyVessel : false;
          self.details.vesselClass = data.vesselClass ? data.vesselClass : '';
          self.details.vesselOperationalArea = data.vesselOperationalArea ? data.vesselOperationalArea : '';
          self.details.vesselQrCode = data.vesselQrCode ? data.vesselQrCode : '';
          self.details.vesselMasterIDs = data.listOfMasterIDs ? data.listOfMasterIDs : [];
          self.details.vesselRoleTwoIDs = data.listOfLevelTwoIDs ? data.listOfLevelTwoIDs : [];
          self.details.vesselCrewIDs = data.listOfCrewIDs ? data.listOfCrewIDs : [];
          self.details.savedRoles = data.vesselRoles ? data.vesselRoles : ['crew','restricted master','master'];
          self.details.customFields = data.customFields ? data.customFields : [];
          self.details.avatarUrl = data.avatarUrl ? data.avatarUrl : '';

          // self.vesselCrewIDs = doc.data().listOfCrewIDs ? doc.data().listOfCrewIDs : [];
          // self.savedCrewIDs = doc.data().listOfCrewIDs ? doc.data().listOfCrewIDs : [];
          // self.savedMasterIDs = doc.data().listOfMasterIDs ? doc.data().listOfMasterIDs : [];
        }
      });

      setTimeout(() => {
        this.loadSpecFields();
        this.loadVesselSchedules();
        this.loadVesselPlant();    
        this.loadCompanyData();
        this.loadMasters();
        this.loadRoleTwo();
        this.loadCrew();
      }, 2000);

      // hazards
      db.collection("vessels").doc(this.finalVesselID).collection("smsHazards").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          let loadVersion = doc.data().currentVersionID ? true : false;
          let archived = doc.data().archived ? doc.data().archived : false;
          if (loadVersion && !archived) {
            db.collection('vessels').doc(self.finalVesselID).collection("smsHazards").doc(doc.id).collection('smsHazardsVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                self.vesselHazards.push({
                  id: docRef.id,
                  title: (docRef.data().title ? docRef.data().title : ''),
                  risks: (docRef.data().risks ? docRef.data().risks : ''),
                  controls: (docRef.data().controls ? docRef.data().controls : ''),
                  rating: (docRef.data().newRating ? docRef.data().newRating : ''),
                  postedBy: (docRef.data().postedBy ? docRef.data().postedBy : '-'),
                  timestamp: (docRef.data().timestamp ? docRef.data().timestamp : ''),
                  personResponsible: docRef.data().personResponsible ? docRef.data().personResponsible.join(', ') : '',
                  uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                  versioned: true,
                  savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                  versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                  likelihood: docRef.data().newLikelihood ? docRef.data().newLikelihood.label : '',
                  consequence: docRef.data().newConsequence ? docRef.data().newConsequence.label : '',
                });
            });
          } else {
            if (!archived) self.vesselHazards.push({
              id: doc.id,
              title: (doc.data().title ? doc.data().title : ''),
              risks: (doc.data().risks ? doc.data().risks : ''),
              controls: (doc.data().controls ? doc.data().controls : ''),
              rating: (doc.data().newRating ? doc.data().newRating : ''),
              postedBy: (doc.data().postedBy ? doc.data().postedBy : '-'),
              timestamp: (doc.data().timestamp ? doc.data().timestamp : ''),
              personResponsible: doc.data().personResponsible ? doc.data().personResponsible.join(', ') : '',
              uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
              likelihood: doc.data().newLikelihood ? doc.data().newLikelihood.label : '',
              consequence: doc.data().newConsequence ? doc.data().newConsequence.label : '',
              versioned: false,
            });
          }
          // old:
          // self.vesselHazards.push({
          //   id: doc.id,
          //   title: (doc.data().title ? doc.data().title : ''),
          //   risks: (doc.data().risks ? doc.data().risks : ''),
          //   controls: (doc.data().controls ? doc.data().controls : ''),
          //   rating: (doc.data().newRating ? doc.data().newRating : ''),
          //   //imageURL: (doc.data().image ? doc.data().image : ''),
          //   postedBy: (doc.data().postedBy ? doc.data().postedBy : '-'),
          //   timestamp: (doc.data().timestamp ? doc.data().timestamp : ''),
          //   personResponsible: doc.data().personResponsible ? doc.data().personResponsible.join(', ') : '',
          //   uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
          //   likelihood: doc.data().newLikelihood ? doc.data().newLikelihood.label : '',
          //   consequence: doc.data().newConsequence ? doc.data().newConsequence.label : '',
          // });
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companyHazards').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              let loadVersion = doc.data().currentVersionID ? true : false;
              let archived = doc.data().archived ? doc.data().archived : false;
              if (self.details.savedCompanyHazards.includes(doc.id) && loadVersion && !archived) {
                db.collection('companies').doc(self.details.vesselOwnerID).collection("companyHazards").doc(doc.id).collection('companyHazardsVersions').doc(doc.data().currentVersionID).get().then((docRefTwo) => {
                    self.vesselHazards.push({
                      id: docRefTwo.id,
                      title: (docRefTwo.data().title ? docRefTwo.data().title : ''),
                      risks: (docRefTwo.data().risks ? docRefTwo.data().risks : ''),
                      controls: (docRefTwo.data().controls ? docRefTwo.data().controls : ''),
                      rating: (docRefTwo.data().newRating ? docRefTwo.data().newRating : ''),
                      postedBy: (docRefTwo.data().postedBy ? docRefTwo.data().postedBy : '-'),
                      timestamp: (docRefTwo.data().timestamp ? docRefTwo.data().timestamp : ''),
                      personResponsible: docRefTwo.data().personResponsible ? docRefTwo.data().personResponsible.join(', ') : '',
                      uploadUrls: docRefTwo.data().uploadUrls ? docRefTwo.data().uploadUrls : [],
                      versioned: true,
                      savedByName: docRefTwo.data().savedByName ? docRefTwo.data().savedByName : '',
                      versionDate: docRefTwo.data().versionDate ? docRefTwo.data().versionDate : '',
                    });
                });
              } else {
                if (self.details.savedCompanyHazards.includes(doc.id) && !archived) self.vesselHazards.push({
                  id: doc.id,
                  title: (doc.data().title ? doc.data().title : ''),
                  risks: (doc.data().risks ? doc.data().risks : ''),
                  controls: (doc.data().controls ? doc.data().controls : ''),
                  rating: (doc.data().newRating ? doc.data().newRating : ''),
                  //imageURL: (doc.data().image ? doc.data().image : ''),
                  postedBy: (doc.data().postedBy ? doc.data().postedBy : '-'),
                  timestamp: (doc.data().timestamp ? doc.data().timestamp : ''),
                  personResponsible: doc.data().personResponsible ? doc.data().personResponsible.join(', ') : '',
                  uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                  likelihood: doc.data().newLikelihood ? doc.data().newLikelihood.label : '',
                  consequence: doc.data().newConsequence ? doc.data().newConsequence.label : '',
                  versioned: false,
                });
              }
              // old:
              // if (self.details.savedCompanyHazards.includes(doc.id)) {
              //   self.vesselHazards.push({
              //     id: doc.id,
              //     title: doc.data().title,
              //     risks: doc.data().risks ? doc.data().risks : '',
              //     controls: doc.data().controls ? doc.data().controls : '',
              //     rating: doc.data().newRating ? doc.data().newRating : '',
              //     postedBy: doc.data().updatedBy ? doc.data().updatedBy : '',
              //     timestamp: doc.data().updatedDate ? doc.data().updatedDate : doc.data().timestamp ? doc.data().timestamp : '',
              //     personResponsible: doc.data().personResponsible ? doc.data().personResponsible.join(', ') : '',
              //     uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
              //     likelihood: doc.data().newLikelihood ? doc.data().newLikelihood.label : '',
              //     consequence: doc.data().newConsequence ? doc.data().newConsequence.label : '',
              //     // index: doc.data().index ? doc.data().index : 999,
              //     // selected: self.savedCompanyHazards.includes(doc.id),
              //   });
              // }
            });
          });
        }
      });



      // CONTACTS
      db.collection("vessels").doc(this.finalVesselID).collection("smsContacts").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          let loadVersion = doc.data().currentVersionID ? true : false;
          let archived = doc.data().archived ? doc.data().archived : false;
          if (loadVersion && !archived) {
            db.collection('vessels').doc(self.finalVesselID).collection("smsContacts").doc(doc.id).collection('smsContactsVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                self.vesselContacts.push({
                  id: docRef.id,
                  title: docRef.data().title,
                  details: docRef.data().details ? docRef.data().details : '',
                  link: docRef.data().link ? docRef.data().link : '',
                  index: docRef.data().index ? docRef.data().index : 999,
                  uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                  versioned: true,
                  savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                  versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                });
            });
          } else {
            if (!archived) self.vesselContacts.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details ? doc.data().details : '',
              link: doc.data().link ? doc.data().link : '',
              index: doc.data().index ? doc.data().index : 999,
              uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
              versioned: false,
            });
          }
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companyContacts').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              let loadVersion = doc.data().currentVersionID ? true : false;
              let archived = doc.data().archived ? doc.data().archived : false;
              if (self.details.savedCompanyContacts.includes(doc.id) && !archived) {
                
                if (loadVersion) {
                  db.collection('companies').doc(self.details.vesselOwnerID).collection("companyContacts").doc(doc.id).collection('companyContactsVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                      self.vesselContacts.push({
                        id: docRef.id,
                        title: docRef.data().title,
                        details: docRef.data().details ? docRef.data().details : '',
                        link: docRef.data().link ? docRef.data().link : '',
                        index: docRef.data().index ? docRef.data().index : 999,
                        uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                        versioned: true,
                        savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                        versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                      });
                  });
                } else {
                  self.vesselContacts.push({
                    id: doc.id,
                    title: doc.data().title,
                    details: doc.data().details ? doc.data().details : '',
                    link: doc.data().link ? doc.data().link : '',
                    index: doc.data().index ? doc.data().index : 999,
                    uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                    versioned: false,
                  });
                }

              }
            });
          });
        }
      });
      // old:
      // db.collection("vessels").doc(this.finalVesselID).collection("smsContacts").get().then(function(querySnapshot) {
      //   querySnapshot.forEach(function(doc) {
      //     self.vesselContacts.push({
      //       id: doc.id,
      //       title: doc.data().title,
      //       details: doc.data().details ? doc.data().details : '',
      //       link: doc.data().link ? doc.data().link : '',
      //       index: doc.data().index ? doc.data().index : 999,
      //       uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
      //     });
      //   });
      // })
      // .then(function() {
      //   if (self.details.isCompanyVessel) {
      //     db.collection('companies').doc(self.details.vesselOwnerID).collection('companyContacts').get().then(function(querySnapshot) {
      //       querySnapshot.forEach(function(doc) {
      //         if (self.details.savedCompanyContacts.includes(doc.id)) {
      //           self.vesselContacts.push({
      //             id: doc.id,
      //             title: doc.data().title,
      //             details: doc.data().details ? doc.data().details : '',
      //             link: doc.data().link ? doc.data().link : '',
      //             index: doc.data().index ? doc.data().index : 999,
      //             uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
      //           });
      //         }
      //       });
      //     });
      //   }
      // });


      // POLICIES
      db.collection("vessels").doc(this.finalVesselID).collection("smsPolicies").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          let loadVersion = doc.data().currentVersionID ? true : false;
          let archived = doc.data().archived ? doc.data().archived : false;
          if (loadVersion && !archived) {
            db.collection('vessels').doc(self.finalVesselID).collection("smsPolicies").doc(doc.id).collection('smsPoliciesVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                self.vesselPolicies.push({
                  id: docRef.id,
                  title: docRef.data().title,
                  details: docRef.data().details ? docRef.data().details : '',
                  link: docRef.data().link ? docRef.data().link : '',
                  index: docRef.data().index ? docRef.data().index : 999,
                  uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                  versioned: true,
                  savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                  versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                });
            });
          } else {
            if (!archived) self.vesselPolicies.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details ? doc.data().details : '',
              link: doc.data().link ? doc.data().link : '',
              index: doc.data().index ? doc.data().index : 999,
              uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
              versioned: false,
            });
          }
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companyPolicies').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              let loadVersion = doc.data().currentVersionID ? true : false;
              let archived = doc.data().archived ? doc.data().archived : false;
              if (self.details.savedCompanyPolicies.includes(doc.id) && !archived) {
                
                if (loadVersion) {
                  db.collection('companies').doc(self.details.vesselOwnerID).collection("companyPolicies").doc(doc.id).collection('companyPoliciesVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                      self.vesselPolicies.push({
                        id: docRef.id,
                        title: docRef.data().title,
                        details: docRef.data().details ? docRef.data().details : '',
                        link: docRef.data().link ? docRef.data().link : '',
                        index: docRef.data().index ? docRef.data().index : 999,
                        uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                        versioned: true,
                        savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                        versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                      });
                  });
                } else {
                  self.vesselPolicies.push({
                    id: doc.id,
                    title: doc.data().title,
                    details: doc.data().details ? doc.data().details : '',
                    link: doc.data().link ? doc.data().link : '',
                    index: doc.data().index ? doc.data().index : 999,
                    uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                    versioned: false,
                  });
                }

              }
            });
          });
        }
      });
      // old:
      // db.collection("vessels").doc(this.finalVesselID).collection("smsPolicies").get().then(function(querySnapshot) {
      //   querySnapshot.forEach(function(doc) {
      //     self.vesselPolicies.push({
      //       id: doc.id,
      //       title: doc.data().title,
      //       details: doc.data().details ? doc.data().details : '',
      //       link: doc.data().link ? doc.data().link : '',
      //       index: doc.data().index ? doc.data().index : 999,
      //       uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
      //     });
      //   });
      // })
      // .then(function() {
      //   if (self.details.isCompanyVessel) {
      //     db.collection('companies').doc(self.details.vesselOwnerID).collection('companyPolicies').get().then(function(querySnapshot) {
      //       querySnapshot.forEach(function(doc) {
      //         if (self.details.savedCompanyPolicies.includes(doc.id)) {
      //           self.vesselPolicies.push({
      //             id: doc.id,
      //             title: doc.data().title,
      //             details: doc.data().details ? doc.data().details : '',
      //             link: doc.data().link ? doc.data().link : '',
      //             index: doc.data().index ? doc.data().index : 999,
      //             uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
      //           });
      //         }
      //       });
      //     });
      //   }
      // });


      // SOPS
      db.collection("vessels").doc(this.finalVesselID).collection("smsSOPS").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          let loadVersion = doc.data().currentVersionID ? true : false;
          let archived = doc.data().archived ? doc.data().archived : false;
          if (loadVersion && !archived) {
            db.collection('vessels').doc(self.finalVesselID).collection("smsSOPS").doc(doc.id).collection('smsSOPSVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                self.vesselSOPS.push({
                  id: docRef.id,
                  title: docRef.data().title,
                  details: docRef.data().details ? docRef.data().details : '',
                  link: docRef.data().link ? docRef.data().link : '',
                  index: docRef.data().index ? docRef.data().index : 999,
                  uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                  versioned: true,
                  savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                  versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                });
            });
          } else {
            if (!archived) self.vesselSOPS.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details ? doc.data().details : '',
              link: doc.data().link ? doc.data().link : '',
              index: doc.data().index ? doc.data().index : 999,
              uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
              versioned: false,
            });
          }
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companySOPS').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              let loadVersion = doc.data().currentVersionID ? true : false;
              let archived = doc.data().archived ? doc.data().archived : false;
              if (self.details.savedCompanySOPS.includes(doc.id) && !archived) {
                
                if (loadVersion) {
                  db.collection('companies').doc(self.details.vesselOwnerID).collection("companySOPS").doc(doc.id).collection('companySOPSVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                      self.vesselSOPS.push({
                        id: docRef.id,
                        title: docRef.data().title,
                        details: docRef.data().details ? docRef.data().details : '',
                        link: docRef.data().link ? docRef.data().link : '',
                        index: docRef.data().index ? docRef.data().index : 999,
                        uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                        versioned: true,
                        savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                        versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                      });
                  });
                } else {
                  self.vesselSOPS.push({
                    id: doc.id,
                    title: doc.data().title,
                    details: doc.data().details ? doc.data().details : '',
                    link: doc.data().link ? doc.data().link : '',
                    index: doc.data().index ? doc.data().index : 999,
                    uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                    versioned: false,
                  });
                }

              }
            });
          });
        }
      });
      // old:
      // db.collection("vessels").doc(this.finalVesselID).collection("smsSOPS").get().then(function(querySnapshot) {
      //   querySnapshot.forEach(function(doc) {
      //     self.vesselSOPS.push({
      //       id: doc.id,
      //       title: doc.data().title,
      //       details: doc.data().details ? doc.data().details : '',
      //       link: doc.data().link ? doc.data().link : '',
      //       index: doc.data().index ? doc.data().index : 999,
      //       uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
      //     });
      //   });
      // })
      // .then(function() {
      //   if (self.details.isCompanyVessel) {
      //     db.collection('companies').doc(self.details.vesselOwnerID).collection('companySOPS').get().then(function(querySnapshot) {
      //       querySnapshot.forEach(function(doc) {
      //         if (self.details.savedCompanySOPS.includes(doc.id)) {
      //           self.vesselSOPS.push({
      //             id: doc.id,
      //             title: doc.data().title,
      //             details: doc.data().details ? doc.data().details : '',
      //             link: doc.data().link ? doc.data().link : '',
      //             index: doc.data().index ? doc.data().index : 999,
      //             uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
      //           });
      //         }
      //       });
      //     });
      //   }
      // });


      // EOPS
      db.collection("vessels").doc(this.finalVesselID).collection("smsEOPS").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          let loadVersion = doc.data().currentVersionID ? true : false;
          let archived = doc.data().archived ? doc.data().archived : false;
          if (loadVersion && !archived) {
            db.collection('vessels').doc(self.finalVesselID).collection("smsEOPS").doc(doc.id).collection('smsEOPSVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                self.vesselEOPS.push({
                  id: docRef.id,
                  title: docRef.data().title,
                  details: docRef.data().details ? docRef.data().details : '',
                  link: docRef.data().link ? docRef.data().link : '',
                  index: docRef.data().index ? docRef.data().index : 999,
                  uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                  versioned: true,
                  savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                  versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                });
            });
          } else {
            if (!archived) self.vesselEOPS.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details ? doc.data().details : '',
              link: doc.data().link ? doc.data().link : '',
              index: doc.data().index ? doc.data().index : 999,
              uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
              versioned: false,
            });
          }
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companyEOPS').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              let loadVersion = doc.data().currentVersionID ? true : false;
              let archived = doc.data().archived ? doc.data().archived : false;
              if (self.details.savedCompanyEOPS.includes(doc.id) && !archived) {
                
                if (loadVersion) {
                  db.collection('companies').doc(self.details.vesselOwnerID).collection("companyEOPS").doc(doc.id).collection('companyEOPSVersions').doc(doc.data().currentVersionID).get().then((docRef) => {
                      self.vesselEOPS.push({
                        id: docRef.id,
                        title: docRef.data().title,
                        details: docRef.data().details ? docRef.data().details : '',
                        link: docRef.data().link ? docRef.data().link : '',
                        index: docRef.data().index ? docRef.data().index : 999,
                        uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                        versioned: true,
                        savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                        versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                      });
                  });
                } else {
                  self.vesselEOPS.push({
                    id: doc.id,
                    title: doc.data().title,
                    details: doc.data().details ? doc.data().details : '',
                    link: doc.data().link ? doc.data().link : '',
                    index: doc.data().index ? doc.data().index : 999,
                    uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                    versioned: false,
                  });
                }

              }
            });
          });
        }
      });
      // old: 
      // db.collection("vessels").doc(this.finalVesselID).collection("smsEOPS").get().then(function(querySnapshot) {
      //   querySnapshot.forEach(function(doc) {
      //     self.vesselEOPS.push({
      //       id: doc.id,
      //       title: doc.data().title,
      //       details: doc.data().details ? doc.data().details : '',
      //       link: doc.data().link ? doc.data().link : '',
      //       index: doc.data().index ? doc.data().index : 999,
      //       uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
      //     });
      //   });
      // })
      // .then(function() {
      //   if (self.details.isCompanyVessel) {
      //     db.collection('companies').doc(self.details.vesselOwnerID).collection('companyEOPS').get().then(function(querySnapshot) {
      //       querySnapshot.forEach(function(doc) {
      //         if (self.details.savedCompanyEOPS.includes(doc.id)) {
      //           self.vesselEOPS.push({
      //             id: doc.id,
      //             title: doc.data().title,
      //             details: doc.data().details ? doc.data().details : '',
      //             link: doc.data().link ? doc.data().link : '',
      //             index: doc.data().index ? doc.data().index : 999,
      //             uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
      //           });
      //         }
      //       });
      //     });
      //   }
      // });


      // STARTUP CHECKLISTS
      db.collection("vessels").doc(this.finalVesselID).collection("smsStartupItems").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.startupItems.push({
            id: doc.id,
            title: doc.data().title,
            details: doc.data().details ? doc.data().details : '',
            index: doc.data().index ? doc.data().index : 999,
            uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
          });
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companyStartupItems').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              if (self.details.savedCompanyStartupItems.includes(doc.id)) {
                self.startupItems.push({
                  id: doc.id,
                  title: doc.data().title,
                  details: doc.data().details ? doc.data().details : '',
                  link: doc.data().link ? doc.data().link : '',
                  index: doc.data().index ? doc.data().index : 999,
                  uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                });
              }
            });
          });
        }
      });


      // MAINTENANCE CHECKLISTS
      db.collection("vessels").doc(this.finalVesselID).collection("smsMaintenanceItems").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.maintenanceItems.push({
            id: doc.id,
            title: doc.data().title,
            details: doc.data().details ? doc.data().details : '',
            index: doc.data().index ? doc.data().index : 999,
            uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
          });
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companyMaintenanceItems').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              if (self.details.savedCompanyMaintenanceItems.includes(doc.id)) {
                self.maintenanceItems.push({
                  id: doc.id,
                  title: doc.data().title,
                  details: doc.data().details ? doc.data().details : '',
                  link: doc.data().link ? doc.data().link : '',
                  index: doc.data().index ? doc.data().index : 999,
                  uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                });
              }
            });
          });
        }
      });


      // INDUCTION CHECKLISTS
      db.collection("vessels").doc(this.finalVesselID).collection("smsVesselInductionItems").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.inductionItems.push({
            id: doc.id,
            title: doc.data().title,
            details: doc.data().details ? doc.data().details : '',
            link: doc.data().link ? doc.data().link : '',
            index: doc.data().index ? doc.data().index : 999,
            uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
          });
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companyInductionItems').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              if (self.details.savedCompanyInductionItems.includes(doc.id)) {
                self.inductionItems.push({
                  id: doc.id,
                  title: doc.data().title,
                  details: doc.data().details ? doc.data().details : '',
                  link: doc.data().link ? doc.data().link : '',
                  index: doc.data().index ? doc.data().index : 999,
                  uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                });
              }
            });
          });
        }
      });


      // GENERAL SAFETY BRIEFING
      db.collection("vessels").doc(this.finalVesselID).collection("smsPassengerInductionItems").orderBy('index','asc').get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.passengerBriefings.push({
            id: doc.id,
            title: doc.data().title,
            details: doc.data().details ? doc.data().details : '',
            link: doc.data().link ? doc.data().link : '',
            index: doc.data().index ? doc.data().index : 999,
            uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
          });
        });
      })
      .then(function() {
        if (self.details.isCompanyVessel) {
          db.collection('companies').doc(self.details.vesselOwnerID).collection('companyPassengerInductionItems').orderBy('index','asc').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              if (self.details.savedCompanyPassengerInductionItems.includes(doc.id)) {
                self.passengerBriefings.push({
                  id: doc.id,
                  title: doc.data().title,
                  details: doc.data().details ? doc.data().details : '',
                  link: doc.data().link ? doc.data().link : '',
                  index: doc.data().index ? doc.data().index : 999,
                  uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                });
              }
            });
          });
        }
      });


      // CUSTOM CHECKLISTS
      db.collection("vessels").doc(this.finalVesselID).collection("customChecklists").orderBy('index','asc').get().then(function(querySnapshot) {
        self.customChecklists = [];
        querySnapshot.forEach(function(doc) {
          let customFields = [];
          db.collection("vessels").doc(self.finalVesselID).collection("customChecklists").doc(doc.id).collection('customFields').orderBy('index','asc').get().then(function(subSnapshot) {
            subSnapshot.forEach((subDoc) => {
              customFields.push(subDoc.data());
            });
          })
          .then(() => {
            self.customChecklists.push({
              id: doc.id,
              name: doc.data().checklistName,
              checklistFields: customFields
            });
          });
        });
      });  


      db.collection('vessels').doc(this.finalVesselID).collection('vesselRevisions').orderBy('timestamp','desc').limit(15).onSnapshot(function(querySnapshot) {
        self.revisions = [];
        querySnapshot.forEach((doc) => {
          self.revisions.push(doc.data());
        });
      });

            
      db.collection("vessels").doc(this.finalVesselID).collection("logStartups").orderBy('startupTimestamp', 'desc').limit(10).get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.loggedStartups.push({
            id: doc.id,
            // logDate: doc.data().startupTimestamp ? moment(doc.data().startupTimestamp.toDate()).format('dddd D MMMM (h:mm a)') : '',
            logDate: doc.data().startupTimestamp ? doc.data().startupTimestamp.toDate().toDateString() : '',
            port: doc.data().port ? doc.data().port : 'No port recorded'
          });
        });
      });
      
      db.collection("vessels").doc(this.finalVesselID).collection("vesselInductions").orderBy('timestamp', 'desc').limit(10).get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          let inductedCrew = doc.data().crewInducted.length > 0 ? doc.data().crewInducted.map((crew) => crew.name).join(', ') : [];
          self.recentInductions.push({
            id: doc.id,
            // timestamp: doc.data().timestamp ? moment(doc.data().timestamp.toDate()).format('dddd D MMMM (h:mm a)') : '',
            timestamp: doc.data().timestamp ? doc.data().timestamp.toDate().toDateString() : '',
            crewName: inductedCrew,
          });
        });
      });

      db.collection("vessels").doc(this.finalVesselID).collection("logMaintenance").orderBy('timestamp', 'desc').limit(10).get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.loggedMaintenance.push({
            id: doc.id,
            // logDate: doc.data().timestamp ? moment(doc.data().timestamp.toDate()).format('dddd D MMMM (h:mm a)') : '',
            logDate: doc.data().timestamp ? doc.data().timestamp.toDate().toDateString() : '',
            postedBy: doc.data().postedBy ? doc.data().postedBy : 'Not recorded'
          });
        });
      });

      db.collection("vessels").doc(this.finalVesselID).collection("shipsLog").orderBy('logTimestamp', 'desc').limit(10).get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.loggedGenerals.push({
            id: doc.id,
            // logDate: doc.data().logTimestamp ? moment(doc.data().logTimestamp.toDate()).format('dddd D MMMM (h:mm a)') : '',
            logDate: doc.data().logTimestamp ? doc.data().logTimestamp.toDate().toDateString() : '',
            logDetails: doc.data().logDetails ? doc.data().logDetails : 'Details not recorded',
            archived: doc.data().archived ? doc.data().archived : false,
          });
        });
      });

      db.collection("vessels").doc(this.finalVesselID).collection("logIncident").orderBy('timestamp', 'desc').limit(10).get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.loggedIncidents.push({
            id: doc.id,
            // logDate: doc.data().timestamp ? moment(doc.data().timestamp.toDate()).format('dddd D MMMM (h:mm a)') : '',
            logDate: doc.data().timestamp ? doc.data().timestamp.toDate().toDateString() : '',
            incidentType: doc.data().selectedTypes ? doc.data().selectedTypes.join(', ') : 'Type not recorded'
          });
        });
      });
      
      db.collection("vessels").doc(this.finalVesselID).collection("smsDefects").orderBy('timestamp', 'desc').limit(10).get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          self.vesselDefects.push({
            id: doc.id,
            defect: doc.data().defect,
            resolved: doc.data().resolved ? doc.data().resolvedAt.toDate().toDateString() : 'Unresolved',
          });
        });
      });



      NProgress.done();

    },

    loadSpecFields() {
      let self = this;
      db.collection('vessels').doc(this.finalVesselID).collection('vesselSpecs').orderBy('index','asc').onSnapshot(function(querySnapshot) {
        self.specFields = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          self.specFields.push(data);
        });
      });
    },

    loadMasters() {
      let self = this;
      console.log(this.details.vesselMasterIDs);
      this.details.vesselMasterIDs.forEach((id) => {
        db.collection('userdata').doc(id).get().then((doc) => {
          console.log(doc.data());
          self.details.vesselMasters.push({
            id: id,
            name: doc.data().username
          });
        })
      });
    },

    loadRoleTwo() {
      let self = this;
      this.details.vesselRoleTwoIDs.forEach((id) => {
        db.collection('userdata').doc(id).get().then((doc) => {
          self.details.vesselRoleTwos.push({
            id: id,
            name: doc.data().username
          });
        })
      });
    },

    loadCrew() {
      let self = this;
      this.details.vesselCrewIDs.forEach((id) => {
        db.collection('userdata').doc(id).get().then((doc) => {
          if (!self.details.vesselMasterIDs.includes(id)) self.details.vesselCrew.push({
            id: id,
            name: doc.data().username
          });
          self.crewProfiles.push({
            id: doc.id,
            crewID: doc.id,
            crewName: doc.data().username ? doc.data().username : 'No Name',
            phone: doc.data().userphone ? doc.data().userphone : '-',
            state: doc.data().userstate ? doc.data().userstate : '-',
            qualifications: (doc.data().qualifications ? doc.data().qualifications : '-'),
            address: doc.data().address ? doc.data().address : '-',
            contacts: doc.data().contacts ? doc.data().contacts : '-',
            emergencyInfo: doc.data().emergencyInfo ? doc.data().emergencyInfo : '-',
            emergencyContacts: doc.data().contacts ? doc.data().contacts : '-',
            homeport: doc.data().homeport ? doc.data().homeport : '-',
            keyDuties: doc.data().keyDuties ? doc.data().keyDuties : '-',
            avatarUrl: doc.data().avatarUrl ? doc.data().avatarUrl : '',
          });
        })
      });
    },

    savePDF: function() {

      this.buttonText = 'Preparing PDF. Please wait...';
      var self = this;

      var generalEmail = firebase.functions().httpsCallable('generalEmail');
      generalEmail({
        senderEmail: self.details.vesselOwnerEmail,
        senderName: self.details.vesselOwnerName,
        senderUserID: '',
        subject: self.details.vesselOwnerName + ' has printed an SMS',
        message: self.details.vesselOwnerName + ' has printed an SMS for vessel ' + self.details.vesselname + ' (ID: ' + self.finalVesselID + ')',
      });

      // First we take the HTML of the page
      var html = document.documentElement.outerHTML;

      // Now we prepare the data that we pass to the API
      // In this example we want to hide the section with the example so we pass custom CSS
      // Note that you don't need to pass any other parameters if your defaults in the members area are already good
      var css = '.header { display:none !important;} #pdfButton {display:none;} .vue-back-to-top {display: none;} .hideForPrint {display: none;} .narrow {display: none;}';
      // var data = { html: html, license: 'E197E92RdrKd', css: css };
      var data = { html: html, license: 'E197E92RdrKd', css: css, orientation: 'portrait', top: 0, bottom: 5, left: 0, right: 0, width: 210, height: 297, unit: 'mm' };
      var serialized = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
      }).join('&');

      //console.log(serialized);

      // You can insert an "in progress" message here

      // We now prepare the API call
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
        var a;
        if (xhttp.readyState === 4 && xhttp.status === 200) {
          // The PDF is now generated
          // You can remove the "in progress" message here

          // Now we show the PDF to the user
          a = document.createElement('a');
          a.href = window.URL.createObjectURL(xhttp.response);
          a.download = self.details.vesselname + ".pdf";
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          //console.log("One");
          self.buttonText = 'Download PDF';
        }
      };

      // This is the actual call to our API
      xhttp.open("POST", "https://pdfmyurl.com/api", true);
      xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhttp.responseType = 'blob';
      xhttp.send(serialized);
      //console.log("Two");

      //NProgress.done();




    },

  },




}
</script>
<style lang="scss" scoped>

.img-wrapper {
  max-width: 200px;
}
#app .header {
  position: relative !important;
}

.site-wrapper .container {
  max-width: 840px;
}


.edit-note {
  margin-top: 1rem;
  font-size: 1rem;
  color: steelblue;
  font-style: italic;
}

hr {
  margin-bottom: 4rem !important;

  &.narrow {
    margin-bottom: 2rem !important;
  }
}

h3 {
  font-size: 1.2rem !important;
  text-transform: uppercase !important;
  margin-top: 2.5rem !important;
}

table {
  margin-top: 1rem !important;
  table-layout: fixed !important;
  width: 100% !important;

  th {
    font-weight: normal !important;
    border: 1px solid #d4d4d4 !important;
    padding: 8px 12px;
    font-family: 'Open Sans', sans-serif;
    text-align: left !important;
    word-break: break-word;
  }

  td {
    width: 50% !important;
    border: 1px solid #d4d4d4 !important;
    padding: 8px 12px;
    font-family: 'Open Sans', sans-serif;
    text-align: left !important;
    word-break: break-word;
    .link {
      margin-top: 5px;
    }
  }
}

.pagebreak {
  display: block !important;
  clear: both !important;
  page-break-after: always !important;
}
.section-wrapper {
  h3 {
    .is-delete {
      display: none;
      margin-left: 1rem;
    }
  }
  &:hover {
    h3 {
      .is-delete {
        display: inline-block;
      }
    }
  }
}
.tag {
  word-break: break-word;
  height: auto;
  white-space: normal;
  padding: 0.75em;
}



@media only screen and (max-width: 767px) {
  .site-wrapper {
    z-index: 1100;
  }
}


</style>