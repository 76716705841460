<template>

    <div>


        <div class="new-home-section hero">
            <div class="section-content">

                <div class="slider-carousel">

                    <carousel :per-page="1" :autoplay="true" :paginationEnabled="false" :navigationEnabled="true" :loop="true"
                        :autoplayTimeout="5000">

                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>The ultimate app for commercial vessel skippers and crew</h2>
                                    <!-- <h5>Download the OffshoreSMS app and start logging startups checks, maintenance, incidents
                                        and more!</h5> -->
                                    <h3 class="price is-warning">Get involved with the safety of the vessels your work on!</h3>
                                    <!-- <div class="spacer s10"></div> -->
                                    <!-- <div class="buttons">
                                        <span class="button is-medium is-success" @click="trackLink('sign-up', 'signup')">Create
                                            a FREE Profile</span>
                                        <span class="button is-medium is-light" @click="trackLink('sign-in', 'login')">Member
                                            Log
                                            in</span>
                                    </div> -->
                                </div>
                            </div>
                            <div class="right-side">
                                <ImageItem class="article-item__image" :source="require(`../assets/img/apps2.png`)"
                                    alt="Say goodbye to paper" />
                            </div>
                        </slide>

                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>Amazing software tools for vessel owners and fleet managers</h2>
                                    <!-- <h5>Digital logs, safety management systems, operations manuals and more, with free support
                                        every step of the way.</h5> -->
                                    <h3 class="price is-warning">Get access to real time vessel data from anywhere</h3>
                                    <!-- <div class="spacer s10"></div> -->
                                    <!-- <div class="buttons">
                                        <router-link class="button is-medium is-skip" to="book-a-demo">Book a Free Online
                                            Demo</router-link>
                                    </div> -->
                                </div>
                            </div>
                            <div class="right-side">
                                <!-- <img src="@/assets/devices.png"> -->
                                <ImageItem class="article-item__image" :source="require(`../assets/img/devices2.png`)"
                                    alt="Amazing software tools" />
                            </div>
                        </slide>

                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>Say goodbye to paper logs and safety management systems</h2>
                                    <!-- <h5>Manage all of your vessels, skippers and crew from one real-time dashboard</h5> -->
                                    <h3 class="price is-warning">Enjoy maximum compliance with zero paper!</h3>
                                    <!-- <div class="spacer s10"></div> -->
                                    <!-- <div class="buttons">
                                        <router-link class="button is-medium is-success" to="plan-fleet">Find out
                                            more</router-link>
                                    </div> -->
                                </div>
                            </div>
                            <div class="right-side">
                                <ImageItem class="article-item__image" :source="require(`../assets/img/fleet-screens.png`)"
                                    alt="Fleet Management" />
                            </div>
                        </slide>

                        <slide>
                            <div class="left-side text-side">
                                <div class="text-wrapper">
                                    <h2>Scheduled maintenance, asset register, defect logs and more!</h2>
                                    <!-- <h5>Imagine a fully compliant safety management system that is accessible from anywhere, all with zero paper!</h5> -->
                                    <!-- <h5>Build your own SMS in our app, or send us your existing SMS and we can set it up for you
                                    </h5> -->
                                    <h3 class="price is-warning">Keep track of what needs to be done and when</h3>
                                    <!-- <div class="spacer s10"></div> -->
                                    <!-- <div class="buttons">
                                        <router-link class="button is-medium is-info" to="plan-logs-sms">Find out
                                            more</router-link>
                                    </div> -->
                                </div>
                            </div>
                            <div class="right-side">
                                <ImageItem class="article-item__image" :source="require(`../assets/img/ian-demo.png`)"
                                    alt="Fully compliant SMS" />
                            </div>
                        </slide>

                    </carousel>

                </div>

            </div>
        </div>

        <div class="new-home-section">
        <div class="hero-intro">
            <h1>Download Australia's number 1 commercial vessel app!</h1>
            <h2>Create your own user profile for free, then add your own vessel or request access to an existing vessel.</h2>
            <!-- <div class="buttons centered">
            <span class="button is-medium is-success" @click="trackLink('sign-up','signup')">Get Started for FREE</span>
            <span class="button is-medium is-info" @click="showHowItWorks = true">See How it Works</span>
            </div> -->
        </div>
        </div>

        <div class="spacer s10"></div>

        <div class="new-home-section flex">
            <div class="download centered flex">
                <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank" rel="noreferrer">
                    <ImageItem class="article-item__image" :source="require(`../assets/img/app-store.png`)" alt="OffshoreSMS app on the Apple App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp" target="_blank" rel="noreferrer">
                    <ImageItem class="article-item__image" :source="require(`../assets/img/play-store.png`)" alt="OffshoreSMS app on the Google Play Store" />
                </a>
            </div>
        </div>

        <div class="new-home-section divider">
            <div class="spacer s30"></div>
            <hr>
            <div class="spacer s30"></div>
            <div class="buttons centered flex">
                <div class="button is-medium is-success" @click="trackLink('sign-up','signup')"><font-awesome-icon icon="user" />&nbsp; Create a FREE Profile</div>
                <div class="button is-medium is-light" @click="trackLink('new-home','local')"><font-awesome-icon icon="info" />&nbsp; Learn More</div>
            </div>
        </div>

        <div class="spacer s100"></div>
        <div class="spacer s100"></div>

        
    </div>



</template>
  
<script>

import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Carousel, Slide } from 'vue-carousel';
import ImageItem from "../reusables/ImageItem.vue";
import NProgress from 'nprogress';

export default {

    name: 'Downloads',

    props: {



    },

    components: {
        Carousel,
        Slide,
        ImageItem
    },

    computed: {


    },

    mounted: function () {


    },

    data: function () {
        return {




        }
    },

    methods: {



      trackLink(page,type) {
        console.log(page,type);
        NProgress.start();
        if (type == 'local') {
            this.$router.push({
                name: page
            });
        } else {
            let addRevision = firebase.functions().httpsCallable("addRevision");
            addRevision({
                userID: '-',
                userName: '-',
                description: 'A user has clicked through to the app.offshoresms ' + type + ' page.'
            });
            window.location.href = 'https://app.offshoresms.com.au/' + page;
        }
        
      },



    },




}
</script>

<style lang="scss" scoped>
#app .header {
    position: relative !important;
}


.image__item {
  max-width: 280px !important;
}
.hero-intro {
  width: 90%;
  margin: 3rem auto 1rem;
  text-align: center;
  h1 {
    font-family: 'Roboto Slab', serif;
    font-size: 2rem;
    line-height: 2.5rem;
    color: #19b6eb;
    svg {
      font-size: 1.5rem;
      vertical-align: baseline;
    }
  }
  h2 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 1rem auto 2rem;
    color: #666;
  }
}
.new-home-section {
  width: 100%;
  margin: 0;
  &.hero {
    // background: #2562a4;
    // background-image: linear-gradient(#3e76b3, #2562a4);
    background: url('../assets/img/hero-bg5.jpg') no-repeat center center;
    height: auto;
    // background: #f7f7f7;
    // margin-top: -20px;
    position: relative;
    background-size: cover;
    // margin-bottom: 2rem;
    .section-content {
      margin: 0;
      padding: 0;
      max-width: none;
      h2, h5 {
        color: #fff;
      }
      img {
        max-width: 400px;
      }
      .left-side {
        float: left;
        width: 50%;
        padding: 1rem 2rem;
        height: 100%;
        // border-top-right-radius: 13% 80%;
        border-bottom-right-radius: 13% 80%;
        // background: #1d4ace;
        // background-image: linear-gradient(#06145a, #11598c);
        // background-image: linear-gradient(#103469, #1d83ca);
        // height: 550px;
        padding: 1rem 2rem;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.text-side {
          .text-wrapper {
            background: rgba(0,0,0,0.7);
            padding: 1.2rem 1.7rem;
            border-radius: 8px;
            h2 {

            }
            h3 {

            }
            .buttons {
              max-width: none;
              margin-left: 0;
            }
          }
          h3 {
            &.price {
              color: #fff;
              font-size: 1.3rem;
              line-height: 1.5rem;
              margin: 12px 0;
              &.is-warning {
                color: #FEFF07;
              }
            }
          }
        }
      }
      .right-side {
        float: right;
        width: 50%;
        padding: 1rem 2rem;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          max-width: 280px;
          width: 100%;
        }
        .image__wrapper {
          max-width: 400px;
          margin: 0 auto;
          background: #fff;
          border-radius: 50%;
        }
      }
      .VueCarousel {
        overflow: hidden;
        img {
          max-width: 400px;
        }
      }
      // .slider-carousel > div {
      //   overflow: hidden !important;
      // }
    }
    .scroll-more {
      // width: 140px;
      margin: 2rem auto 0;
      text-align: center;
      // position: absolute;
      // bottom: 5px;
      // left: 50%;
      // transform: translateX(-70px);
      .scroll-text {
        color: #fff;
        font-size: 0.9rem;
      }
      .down-arrow {
        width: 42px;
        height: 42px;
        margin: 0.5rem auto;
        background: url('../assets/img/down-arrow.png') no-repeat center center;
        background-size: cover;
      }
    }
  }
  &.newsletter {
    padding: 3rem 1rem 2rem;
    h5 {
      color: dodgerblue;
      font-size: 1.2rem;
    }
    input {
      padding: 1.5rem 2rem;
      height: auto;
      border: 1px solid #dedede;
      border-radius: 3px;
      display: inline-block;
      margin: 15px auto 20px;
      font-size: 1.2rem;
      width: 100%;
      max-width: 400px;
      &:focus-visible {
        outline: none;
        border: 1px solid limegreen;
      }
    }

  }
  &.grey-bar {
    margin: 0;
    padding: 2.2rem 0 1.8rem;
    background: #1c82c9;
    margin-top: 0;
  }
  .section-content {
    margin: 6rem auto 4rem;
    padding: 0 2rem;
    width: 100%;
    max-width: 1200px;
    h2 {
      font-family: 'Roboto Slab', serif;
      color: #2562a4;
      font-size: 1.6rem;
      line-height: 1.9rem;
      margin-bottom: 0.5rem;
    }
    h3 {
      font-family: 'Roboto Slab', serif;
      color: #2562a4;
      font-size: 2rem;
      line-height: 2.3rem;
      margin-bottom: 1.5rem;
      &.green {
        color: limegreen;
      }
      &.is-primary {
        color: #00d1b2;
      }
    }
    h5 {
      color: #2562a4;
      font-size: 1.2rem;
      &.bold {
        font-weight: bold;
        margin-top: 2rem;
      }
    }
    .left-side {
      float: left;
      width: 50%;
      padding-top: 3rem;
      img {
        max-width: none;
        width: 100%;
      }
      // &.text-side {
      //   padding-top: 3rem;
      // }
    }
    .right-side {
      float: right;
      width: 50%;
      img {
        max-width: none;
        width: 100%;
      }
      &.text-side {
        padding-top: 3rem;
        padding-left: 2rem;
      }
    }
    .thirds {
      width: 33%;
      float: left;
      text-align: center;
    }
    .simple-link {
      color: #2562a4;
      transition: 0.3s;
      &:hover {
        color: #37cda8;
      }
    }
    .footer-links {
      display: flex;
      justify-content: center;
      li {
        margin-bottom: 0.6rem;
        flex-basis: 33%;
        text-align: center;
      }
    }
    .cloud {
      font-size: 5rem;
      color: #f947a7;
    }
  }
  &.tight {
    .section-content {
      margin-top: 2rem;
    }
  }
}

.download {
    display: flex;
    justify-content: space-between;
    a {
        display: block;
        margin: 0 10px;
    }
}
.divider {
    margin: 0 auto;
    max-width: 90%;
}

.article-item__image {
    max-width: 280px !important;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 959px) {
  .imac-wrapper {
    height: 600px;
    .screen-bg {
      top: 40px;
      max-height: 270px;
    }
  }
  .new-home-section.hero {
    .section-content {
      .left-side {
        // width: 100%;
        // float: none;
        // margin-bottom: 2rem;
        padding: 3rem 2rem;
      }
      .right-side {
        // width: 100%;
        // float: none;
        // margin-bottom: 2rem;
        &.text-side {
          padding-top: 0;
          padding-left: 0;
          // text-align: center;
        }
      }
    }
  }

}

@media only screen and (max-width: 859px) {
  .new-home-section.hero {
    .section-content {
      .left-side {
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 0;
        padding: 2rem 1rem;
        height: auto;
      }
      .right-side {
        display: none;
        width: 100%;
        height: auto;
        text-align: center;
        padding: 1rem;
      }
    }
  }
  .new-home-section {
    .section-content {
      .left-side {
        width: 100%;
        height: auto;
        text-align: center;
        border-radius: 0;
        padding: 2rem 0;
      }
      .right-side {
        width: 100%;
        height: auto;
        text-align: center;
        padding: 1rem 0;
        &.text-side {
          padding: 1rem 0;
        }
      }
    }
  }

}



@media only screen and (max-width: 767px) {
  .imac-wrapper {
    display: none;
  }
  .test-activity {
    display: none;
  }
  .static-image {
    display: block;
    img {
      max-width: none;
      width: 100%;
    }
  }
  .new-home-section {
    .section-content {
      padding: 0 1rem;
      .thirds {
        width: 100%;
        float: none;
        text-align: center;
        margin-bottom: 2rem;
      }
      .footer-links {
        flex-direction: column;
        li {
          flex-basis: 100%;
        }
      }
    }
  }
    .flex {
        flex-direction: column;
        a {
            max-width: none;
        }
    }
//   .download {
//     a {
//       max-width: 170px;
//     }
//   }
  .tab-wrapper {
    .round-tab {
      min-width: auto;
      padding: 1rem 2rem;
      font-size: 1.1rem;
      line-height: 1.3rem;
      span {
        &.icon {
          font-size: 1.5rem;
          margin-bottom: 7px;
        }
        &.text {
          font-size: 1.1rem;
        }
      }
    } 
  }
  .tab-content-wrapper {
    padding: 3rem 2rem;
    flex-direction: column;
     &:before {
      top: -40px;
      left: 22%;
    }
    &.two {
      &:before {
        right: 20%;
        left: auto;
      }
    }
    .tc-image {
      width: 100%;
      order: 2;
    }
    .tc-content {
      width: 100%;
    }
  }

}


@media only screen and (max-width: 479px) {

  .buttons {
    .button {
      display: block;
      height: auto;
      font-size: 1.1rem;
      line-height: 1.1rem;
      padding: 12px 6px;
      margin-right: 2px !important;

      height: auto;
      padding: 1rem 1.2rem;
      margin-bottom: 10px;
      min-width: 250px;
      font-size: 1.25rem;
    }
  }

  .new-home-section.hero {
    .section-content {
      .left-side {
        height: auto;
      }
    }
  }

  .icon-list {
    li {
      margin-bottom: 3rem;
      .small-icon {
        float: none;
        margin: 0 auto;
      }
      .details {
        float: none;
        width: 100%;
        margin-left: 0;
        padding: 0;
        text-align: center;
      }
    }
  }
  .download {
    a {

    }
  }
  .tab-wrapper {
    width: 95%;
    // flex-direction: column;
    .round-tab {
      min-width: auto;
      padding: 10px 12px;
      font-size: 1rem;
      line-height: 1.1rem;
      margin: 0 5px;
      span {
        &.icon {
          font-size: 1.2rem;
          margin-bottom: 7px;
        }
        &.text {
          font-size: 1rem;
        }
      }
    } 
  }
   .tab-content-wrapper {
    padding: 3rem 1.5rem;
    flex-direction: column;
     &:before {
      left: 10%;
    }
    &.two {
      &:before {
        right: 10%;
        left: auto;
      }
    }
  }


}


.site-wrapper .container {
    max-width: 840px;
}

</style>