<template>
    <div>
        <!-- <div class="header-image">
				<div class="header-content">
					<h1>Useful Links</h1>
				</div>
			</div> -->

        <div class="site-wrapper no-header">
            <div class="container">
                <ul class="scroller">
                    <li><router-link to="/">Home</router-link></li>
                    <li class="is-active">
                        <a aria-current="page">{{ msg }}</a>
                    </li>
                </ul>

                <div class="content">
                    <div class="spacer s30"></div>

                    <div class="box">
                        <font-awesome-icon class="icon" icon="users" />
                        <h5 class="is-size-5">ABOUT US</h5>
                        <p>
                            Where did OffshoreSMS come from, who is driving it and what is the
                            story?
                        </p>
                        <router-link to="about-us" class="button is-info">Read More</router-link>
                    </div>

                    <!-- <div class="box">
								<font-awesome-icon class="icon" icon="ship" /><h5 class="is-size-5">DEMO SMS</h5>
								<p>Have a play with a DEMO Safety Management System in the cloud.</p>
								<span class="button is-success" @click="viewDEMO()">View DEMO</span>
						</div> -->

                    <!-- <div class="box">
                <font-awesome-icon class="icon" icon="money-bill-alt" /><h5 class="is-size-5">Pricing</h5>
                <p>View the diferent subscriptions available in Offshore SMS</p>
                <router-link class="button is-skip" to="pricing">View Pricing</router-link>
            </div> -->

                    <div class="box">
                        <font-awesome-icon class="icon" icon="question-circle" />
                        <h5 class="is-size-5">FAQs</h5>
                        <p>Answers to some common questions about Offshore SMS</p>
                        <router-link class="button is-primary" to="faqs">Read FAQs</router-link>
                    </div>

                    <!-- <div class="box">
								<font-awesome-icon class="icon" icon="info-circle" /><h5 class="is-size-5">Getting started guide</h5>
								<p>A few tips on how to get started with your new SMS in the cloud.</p>
								<router-link class="button is-primary" to="getting-started">Get Started</router-link>
						</div> -->

                    <!-- <div class="box">
                <font-awesome-icon class="icon" icon="info-circle" /><h5 class="is-size-5">Getting Started Guide</h5>
                <p>Learn how to get the most out of OffshoreSMS.</p>
                <router-link class="button is-success" to="user-guide-start">View Guide</router-link>
            </div> -->

                    <!-- <div class="box">
								<font-awesome-icon class="icon" icon="life-ring" /><h5 class="is-size-5">What are my safety obligations</h5>
								<p>What do I have to do and when to have a compliant vessel SMS?</p>
								<router-link class="button is-warning" to="user-guide-obligations">See what's required</router-link>
						</div> -->

                    <!-- <div class="box">
								<font-awesome-icon class="icon" icon="cog" /><h5 class="is-size-5">Development Timeline</h5>
								<p>A timeline of development at Offshore SMS, from launch to now.</p>
								<router-link class="button is-primary" to="development-timeline">View timeline</router-link>
						</div> -->

                    <!-- <div class="box">
                <font-awesome-icon class="icon" icon="ship" /><h5 class="is-size-5">Seafood Industry Partnerships</h5>
                <p>How we are working to help commercial fishermen around Australia.</p>
                <router-link class="button is-skip" to="australian-seafood-partnerships">Read more</router-link>
            </div> -->

                    <!-- <div class="box">
                <font-awesome-icon class="icon" icon="link" /><h5 class="is-size-5">Oyster Industry Challenges</h5>
                <p>Our work in support of the growing Australian Oyster industry.</p>
                <router-link class="button is-skip" to="oysters">Read more</router-link>
            </div> -->

                    <div class="box">
                        <font-awesome-icon class="icon" icon="globe" />
                        <h5 class="is-size-5">Software as a Service Terms of Use</h5>
                        <p>
                            Terms and Conditions which apply to the purchase and use of any
                            OffshoreSMS annual software subscriptions.
                        </p>
                        <router-link class="button is-warning" to="saas-terms"
                            >Read more</router-link
                        >
                    </div>

                    <div class="box">
                        <font-awesome-icon class="icon" icon="lock" />
                        <h5 class="is-size-5">Privacy Policy and Terms & Conditions</h5>
                        <p>
                            Privacy and security of our customer data is at the core of OffshoreSMS.
                            Read our Privacy Policy and Terms & Conditions here.
                        </p>
                        <router-link class="button is-skip" to="privacy-policy"
                            >Read more</router-link
                        >
                    </div>

                    <div class="box">
                        <font-awesome-icon class="icon" icon="lock" />
                        <h5 class="is-size-5">Data Collection and Cyber Security Policy</h5>
                        <p>
                            We take Data Security and Cyber Security seriously. Read our Data
                            Collection and Cyber Security Policy to understand what we are doing to
                            ensure the protection of your data.
                        </p>
                        <router-link class="button is-danger" to="data-security-policy"
                            >Read more</router-link
                        >
                    </div>

                    <div class="box">
                        <font-awesome-icon class="icon" icon="users" />
                        <h5 class="is-size-5">User Accounts and User Data</h5>
                        <p>
                            It is free and easy to create a user profile on OffshoreSMS to be able
                            to use the app and its many features. Learn more about what we collect
                            and what your rights are.
                        </p>
                        <router-link class="button is-info" to="account-data"
                            >Read more</router-link
                        >
                    </div>

                    <!-- <div class="box">
						<font-awesome-icon class="icon" icon="lock" /><h5 class="is-size-5">OffshoreSMS User Guides</h5>
						<p>Take a look at some of the key features of OffshoreSMS and how they work.</p>
						<router-link class="button is-success" to="user-guide-start">View User Guides</router-link>
					</div> -->

                    <div class="box">
                        <font-awesome-icon class="icon" icon="users" />
                        <h5 class="is-size-5">Leave a Testimonial</h5>
                        <p>
                            Help share the love and let us know how OffshoreSMS has helped in your
                            operation.
                        </p>
                        <router-link class="button is-warning" to="leave-testimonial"
                            >I'd love to</router-link
                        >
                    </div>

                    <!-- <div class="box">
								<font-awesome-icon class="icon" icon="check" /><h5 class="is-size-5">Feature List</h5>
								<p>What do you get with Offshore SMS now, and what awesome things are on the way?</p>
								<router-link class="button is-info" to="features">View features</router-link>
						</div> -->
                </div>

                <div class="spacer s100"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export default {
    name: 'UsefulLinks',
    computed: {
        user() {
            return this.$store.getters.getUser;
        }
    },
    data: function() {
        return {
            msg: 'Useful Links'
        };
    },
    methods: {
        viewDEMO: function() {
            let vesselName = 'DEMO VESSEL';
            let vesselID = 'kZgrsNOrggKmi7bjE5IA';
            let vesselUVI = 'DEMO';
            //this.$store.dispatch('UPDATESELECTEDVESSEL',{'DEMO VESSEL','kZgrsNOrggKmi7bjE5IA','DEMO'});
            this.$store.dispatch('UPDATESELECTEDVESSEL', { vesselName, vesselID, vesselUVI });
            // this.$router.push({ name: 'DemoSms', params: {
            // 	selectedVesselID : 'kZgrsNOrggKmi7bjE5IA',
            // 	selectedVesselName : 'DEMO VESSEL',
            // 	selectedVesselUVI : 'DEMO',
            // 	}
            // });
            this.$router.push({ name: 'DemoBuilder' });
        }
    }
};
</script>

<style lang="scss" scoped>
.header-image {
    background: url('../assets/img/boat-tied-up.jpg') no-repeat center top;
    color: #fff;
    background-size: cover;
}

.icon {
    display: inline-block;
    margin-right: 0.6rem;
    vertical-align: sub;
    float: left;
}

h5 {
    display: block;
}

.button.is-skip {
    background: #ff7878;
    border-color: transparent;
    color: #fff;
}
</style>
