<template>
    <div class="">
        <div class="container">
            <div v-if="checkingDetails || loadingPageData">
                <div class="spacer s30"></div>
                <h3>
                    {{ status }}
                </h3>
            </div>

            <div class="spacer s30 desktop"></div>

            <div v-if="formLoaded">
                <div v-if="accessAllowed">
                    <div v-if="requiresLogin">
                        <div v-if="userHasAccess">
                            display web page with logged in user content
                        </div>
                        <div v-else>
                            <div class="spacer s30"></div>
                            {{ formMessage }} <br />
                            <div class="spacer s30"></div>
                            <div v-if="!isLoggedIn" class="button is-primary" @click="showLogin()">Login required</div>
                        </div>
                    </div>
                    <div v-else>
                        <div>Public web page: {{ formData.contactName }}</div>
                        <hr />
                        <div>{{ formData }}</div>
                    </div>
                </div>
                <div v-else>
                    <div class="spacer s30"></div>
                    {{ formMessage }}
                </div>
            </div>
            <div v-else>
                <div class="spacer s30"></div>
                {{ errorMessage }}
            </div>

            <div class="spacer s100"></div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, query, where, doc, onSnapshot, addDoc, getDocs, getDoc, orderBy, limit } from 'firebase/firestore';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();
const cloudFunctionSendMultipleEmails = httpsCallable(functions, 'cloudFunctionSendMultipleEmails');

export default {
    props: {
        sentCustomLog: Object,
        webLink: String
    },

    mounted: function() {
        let hasParam = this.$route.query && this.$route.query.x;
        if (hasParam) {
            this.xID = this.$route.query.x ? this.$route.query.x : '';
        } else {
            this.xID = this.webLink ? this.webLink : '';
        }
        let self = this;
        if (this.xID != '') {
            this.checkCodeExists();
        } else {
            setTimeout(() => {
                self.checkingDetails = false;
                self.status = 'No defect found.';
                self.errorMessage = 'No defect found.';
            }, 1000);
        }
        this.getIPFromAmazon();
    },

    components: {},

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        userID() {
            return this.userData.userid;
        }
    },

    data: function() {
        return {
            code: '',
            currentWebLink: '',
            checkingDetails: true,
            formExists: false,
            loadingPageData: false,
            ipAddress: '',
            hasData: false,
            status: 'Checking if conversation exists..',
            formMessage: '',
            formLoaded: false,
            errorMessage: '',

            pageMeta: {},
            formData: {},

            xID: '',
            accessAllowed: false,
            requiresLogin: false,

            userHasAccess: false,
            phoneNumber: ''
        };
    },

    methods: {
        close() {
            this.showTimelineModal = false;
            this.newPin = '';
            this.validating = false;
            this.savingEntry = false;
            this.errorMsg = '';
        },

        async getIPFromAmazon() {
            fetch('https://checkip.amazonaws.com/')
                .then(res => res.text())
                .then(data => {
                    // console.log('AWS', data);
                    this.ipAddress = data;
                });
        },

        async checkCodeExists() {
            this.errorMessage = '';
            let self = this;
            console.log('checking', this.xID);

            const docRef = doc(db, 'smsEnquiryUsedCodes', this.xID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                self.pageMeta = docSnap.data();
                // if (docSnap.data().phoneNumber) {
                //     self.$store.dispatch('setCurrentConversation', { vesselID });
                // }
                // let logMeta = self.pageMeta;
                // self.$store.dispatch('setCurrentConversationMeta', { logMeta });
                setTimeout(() => {
                    self.status = 'Checking status...';
                    self.formExists = true;
                    self.loadingPageData = true;
                    self.checkingDetails = false;
                    self.loadFormData();
                }, 500);
            } else {
                self.checkingDetails = false;
                self.status = 'This page could not be loaded.';
                self.errorMessage = 'No conversation found.';
            }
        },

        loadFormData() {
            let self = this;
            if (this.pageMeta.phoneNumber && this.pageMeta.phoneNumber != '') {
                self.hasData = true;
                self.status = 'Loading new information...';
                setTimeout(() => {
                    self.loadConversationData();
                }, 500);
            }
        },

        async loadConversationData() {
            let self = this;
            const docRef = doc(db, 'smsConversations/' + this.pageMeta.phoneNumber);
            const docSnap = await getDoc(docRef);
            this.formData = {};
            if (docSnap.exists()) {
                let data = docSnap.data();
                data.id = docSnap.id;
                self.currentWebLink = docSnap.data().webLink ? docSnap.data().webLink : '';
                if (self.xID != self.currentWebLink) {
                    self.loadingPageData = false;
                    self.errorMessage = 'Page expired. Access denied.';
                    return;
                }
                self.formData = data;
                // let logData = self.formData;
                // self.$store.dispatch('setCurrentConversationData', { logData });
                setTimeout(() => {
                    self.accessAllowed = true;
                    self.loadingPageData = false;
                    self.formLoaded = true;
                }, 500);
            } else {
                self.formMessage = 'This web page is not available, sorry.';
                self.loadingPageData = false;
            }
        },

        validateAccess() {
            let self = this;
            self.accessAllowed = true;
            self.loadingPageData = false;
            self.formLoaded = true;
        },

        checkUserAccess() {
            let self = this;
            this.userHasAccess = this.isLoggedIn && this.allVesselCrewIDs.includes(this.userID);
            this.formMessage = this.userHasAccess ? 'Access permitted..' : 'This form is only available to authorised users.';
            self.loadingPageData = false;
            self.formLoaded = true;
        },

        showLogin() {
            this.$router.push({
                name: 'UserLogin',
                params: {
                    return: true
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
