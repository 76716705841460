<template>

	<div>

			<!-- <div class="header-image">
				<div class="header-content">
					<h1>Feedback</h1>
				</div>
			</div> -->

			<div class="site-wrapper no-header">

				

  				
			
				<div class="container">

				<ul class="scroller">
					<li><router-link to="/">Home</router-link></li>
					<!-- <li><router-link to="support">Support</router-link></li> -->
					<li class="is-active"><a aria-current="page">Contact Us</a></li>
				</ul>

				<div class="spacer s30"></div>

					<div class="content">
						<h4 class="is-primary">Drop us a line</h4>
						<p>We really would love to hear from you, whether you have a great idea, a suggestion for improvement, or just have some questions that you can't find the answers to.</p>
						<p>Either way, please either give us a call on <a href="tel:+61-447-139-382">+61 447 139 382</a>, or email <a href="mailto:help@offshoresms.com.au?subject=Question from Offshore SMS">help@offshoresms.com.au</a>, or fill in the form below and we will get back to you as soon as possible.</p>
					</div>

					<hr>

					<div v-if="showMessage" class="notification is-info">
						<p class="is-size-5">Thanks for your enquiry!</p>
						<p>We'll do our best to get back to you as quick as we can, if that's what you asked for:)</p>
					</div>

					<form v-if="!showMessage" @submit.prevent="saveFeedback()">


							<div class="field">
								<!-- <label class="label">Your name</label> -->
							  <div class="control has-icons-left has-icons-right">
							    <input class="input" type="text" placeholder="Your name" v-model="name" required>
							    <span class="icon is-small is-left">
							      <font-awesome-icon icon="user" />
							    </span>
							    <span v-if="name != ''" class="icon is-small is-right">
							      <font-awesome-icon icon="check" />
							    </span>
							  </div>
							</div>

							<div class="field">
							  <div class="control has-icons-left has-icons-right">
							    <input class="input" type="email" placeholder="Email address" v-model="email">
							    <span class="icon is-small is-left">
							      <font-awesome-icon icon="envelope" />
							    </span>
							    <span v-if="email != ''" class="icon is-small is-right">
							      <font-awesome-icon icon="check" />
							    </span>
							  </div>
							</div>

							<div class="field">
								<!-- <label class="label">Your name</label> -->
							  <div class="control has-icons-left has-icons-right">
							    <input class="input" type="tel" placeholder="Phone number" v-model="phone" required>
							    <span class="icon is-small is-left">
							      <font-awesome-icon icon="phone" />
							    </span>
							    <span v-if="phone != ''" class="icon is-small is-right">
							      <font-awesome-icon icon="check" />
							    </span>
							  </div>
							</div>

							<div class="form-group">   
								<div class="select">
									<select v-model="industry">
									<option disabled value="">Your Industry</option>
									<option v-for="industry in industries" :key="industry.name">
										{{ industry.name }}
									</option>
									</select>
								</div>
							</div>
              

							<div class="form-group">  
							    <div class="select">
							      <select v-model="myreason">
							        <option disabled value="">Reason for making contact</option>
							        <option v-for="reason in reasons" :value="reason" :key="reason.name">
										{{ reason.name }}
									</option>
							      </select>
							    </div>
							</div>


							<div class="field">
							  <!-- <label class="label">Message</label> -->
							  <div class="control">
							    <textarea autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" class="textarea" placeholder="Please type your message..." v-model="message"></textarea>
							  </div>
							</div>
							<div class="spacer s10"></div>

							<!-- <div class="field">
								<input class="is-checkradio" type="checkbox" id="needReply" v-model="reply">
								<label for="needReply"> &nbsp; I would like a reply</label>
							</div> -->

							

							<div class="field is-grouped form-group">
							  <div class="control">
							    <button :disabled="cantSubmit" class="button is-primary">Submit</button>
							  </div>
							</div>

					</form>




				</div>


			</div>

	</div>

</template>






<script>
	
	import { db } from '../main.js';
	import firebase from 'firebase/compat/app';
	import 'firebase/compat/auth'; 
	import 'firebase/compat/firestore';
	import 'firebase/compat/functions';
	import NProgress from 'nprogress';
  // const StickyFooter = () => import('../reusables/StickyFooter.vue');

	export default {

		name: 'Feedback',
		components: {
			// StickyFooter
		},
		computed: {
			user() {
				return this.$store.getters.getUser;
			},
			isUserAdmin() {
				return this.$store.getters.getIsUserAdmin;
			},
			userData() {
				return this.$store.getters.getSubscriptionInfo;
			},
			cantSubmit() {
				return this.name == '' || this.email == '' || this.phone == '';
			}
		},

		props: [
			'sentReason'
		],

		mounted() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );
		},

		data: function() {
			return {
				msg: 'Feedback',
				name: '',
				email: '',
				phone: '',
				myreason: {
					name: "Reason for making contact", description: "",
				},
				industry: '',
				reply: true,
				message: '',
				industries: [
					{ name: "Fishing"},
					{ name: "Aquaculture"},
					{ name: "Commercial Diving"},
					{ name: "Marine Construction"},
					{ name: "Marine Rescue"},
					{ name: "Government"},
					{ name: "Passenger Vessels"},
					{ name: "Charter Industry"},
					{ name: "Other"},
				],
				reasons: [
					{name: "Reason for making contact", description: ""},
					{name: "I would like some more information", description: "We can help"},
					{name: "I need help with something", description: "How can we help?"},
					{name: "I have some feedback", description: "Please tell us what you're thinking..."},
					{name: "I have some ideas", description: "We're all ears!"},
				],
				showMessage: false,
				allHelp: [
					{ item: 'If you can\'t find the answers you need, please call us on +61 447 139 382.'},
				],
				helpTitle: '',
			}
		},

		methods: {

			onUserLogin: function( user ) {
				let self = this;
				if (user) {         
					// this.firebaseid = user.uid;
					this.email = user.email;
					this.name = this.userData.username;
					this.industry = this.userData.industry;
					this.providerData = user.providerData;
					user.providerData.forEach((provider) => {
						if (provider.providerId === "phone") { 
							self.phone = "0" + provider.phoneNumber.slice(3,12) 
						}
					});
					// console.log('SentReason',this.$route);
					if (this.$route.params.sentReason) {
						// console.log('SentReason',self.$route.params);
						self.myreason = self.$route.params.sentReason; 
					}
				}
				// if (this.sentReason) {
				// 	self.myreason = self.sentReason; 
				// }
			},
		
			saveFeedback() {
				console.log('save feedback');

				let self = this;    
				let message = {
					title: 'Send this message?',
					// body: ''
				};
				let options = {
					okText: 'Yes, Send',
					backdropClose: true,
					cancelText: 'Cancel',
				};
				this.$dialog.confirm(message, options).then(function(dialog) {
					NProgress.start();  
					//
					var feedbackArray = {
						name: self.name,
						email: self.email,
						phone: self.phone,
						industry: self.industry,
						reason: self.myreason.name,
						message: self.message,
						needsReply: self.reply,
						timestamp: firebase.firestore.FieldValue.serverTimestamp()
					}
					console.log(feedbackArray);
					db.collection("feedback").add(feedbackArray).then(function(docRef) {
						console.log("Document written with ID: ", docRef.id);
						self.name = '';
						self.email = '';
						self.phone = '';
						self.industry = '';
						self.myreason = '';
						self.message = '';
						self.reply = true;
						self.showMessage = true;
						NProgress.done();
					});
					
				})
				.catch(function(error) {
					console.log('Clicked on cancel', error); 
					NProgress.done();
				});

				
				// console.log(this.name, this.email, this.phone, this.industry, this.myreason.name, this.message, this.reply);
				// var self = this;  	
				// let dialogueMsg = "<h3>Send message?</h3>";
				// let dialogueOptions = {
				// 	html: true,
				// 	//loader: true,
				// 	okText: 'Yes, send',
				// 	cancelText: 'No, go back',
				// 	//message: 'This is a message',
				// 	backdropClose: true
				// };

				// this.$dialog.confirm(dialogueMsg, dialogueOptions).then(function(dialog) {
				// 	NProgress.start();
				// 	var feedbackArray = {
				// 		name: self.name,
				// 		email: self.email,
				// 		phone: self.phone,
				// 		industry: self.industry,
				// 		reason: self.myreason.name,
				// 		message: self.message,
				// 		needsReply: self.reply,
				// 		timestamp: firebase.firestore.FieldValue.serverTimestamp()
				// 	}
				// 	db.collection("feedback").add(feedbackArray).then(function(docRef) {
				// 		console.log("Document written with ID: ", docRef.id);
				// 		self.name = '';
				// 		self.email = '';
				// 		self.phone = '';
				// 		self.industry = '';
				// 		self.myreason = '';
				// 		self.message = '';
				// 		self.reply = true;
				// 		self.showMessage = true;
				// 		NProgress.done();
				// 	});

				// })
				// .catch(function() {
				// 	console.log('Clicked on cancel'); 
				// 	NProgress.done();
				// });	     


			},



		},

	}





</script>




<style lang="scss" scoped>
	
	
  .field {
    input {
      padding: 20px 40px;
      height: auto;
    }
  }
  .control.has-icons-left .icon.is-left {
    top: 13px;
  }
	// .header-image {
	//   background: url('../assets/img/boat-tied-up.jpg') no-repeat center top;
	//   color: #fff;
	//   background-size: cover;
	// }


</style>