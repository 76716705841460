<template>
    <div class="site-wrapper no-header">
        <div ref="smsWrapper" class="container">
            <div class="buttons hideForPrint">
                <span v-if="showBack" @click="goBack()" class="button is-info"
                    ><span><font-awesome-icon icon="chevron-left"/></span> &nbsp; Back</span
                >
            </div>

            <div v-if="loadingData">
                <span><font-awesome-icon icon="cog" class="fa-spin" />&nbsp;Loading new vessel info...</span>
                <div class="spacer s40"></div>
            </div>

            <div v-else>
                <div v-if="dataError">
                    <h3 class="title">{{ errorMessage }}</h3>

                    <p>
                        Refresh the page and if the issue remains, please either give us a call on
                        <a href="tel:+61-447-139-382">+61 447 139 382</a>, or email <a href="mailto:help@offshoresms.com.au?subject=Question from Offshore SMS">help@offshoresms.com.au</a>.
                    </p>
                </div>

                <div v-else>
                    <div class="img-wrapper" style="width: 100%; border-bottom: 1px solid #e4e4e4; padding-bottom: 1rem;">
                        <img style="max-width: 200px;" class="logo" src="https://ik.imagekit.io/offshoresms/offshore-sms-good-res-logo.jpg?updatedAt=1683103736289" alt="Offshore SMS" />
                    </div>

                    <h3 style="font-family: 'helvetica', sans-serif;color: #444;font-size: 1.2rem;font-weight: bold;text-transform: uppercase;margin-top: 1.5rem;display: block;">
                        New Vessel Name: {{ logData.questions[4].answer[0] }}
                    </h3>
                    <p style="font-family: 'helvetica', sans-serif;color: #444;font-size: 1rem;margin-top: 6px;margin-bottom: 5px;">Saved on: {{ showDate(logData.timestamp) }}</p>
                    <p style="font-family: 'helvetica', sans-serif;color: #444;font-size: 1rem;margin-top: 6px;margin-bottom: 25px;">By: {{ logData.userName }}</p>
                    <table
                        v-if="logData.questions && logData.questions.length > 0"
                        style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
                        class="table is-bordered is-fullwidth"
                    >
                        <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                            <tr v-for="(answer, index) in logData.questions" :key="`index-${index}`">
                                <th
                                    style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4;padding: 15px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;"
                                >
                                    <div>{{ answer.title }}</div>
                                    <div class="copy-link" @click="copyText(false, false, answer.title)">
                                        <font-awesome-icon icon="link" />
                                    </div>
                                </th>

                                <th
                                    style="font-size: 0.9rem;font-weight: normal !important;border: 1px solid #d4d4d4;padding: 15px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;"
                                >
                                    <div
                                        v-if="answer.type != 'doodle' && answer.type != 'dumbtext' && answer.type != 'dumbphoto'"
                                        class="copy-link"
                                        @click="copyText(Array.isArray(answer.answer), answer.type == 'datetime', answer.answer)"
                                    >
                                        <font-awesome-icon icon="link" />
                                    </div>

                                    <div v-if="typeof answer.answer == 'string'">
                                        <div v-if="answer.type == 'doodle'">
                                            <img :src="answer.answer" alt="" />
                                        </div>
                                        <div v-if="answer.type == 'text'">
                                            {{ answer.answer }}
                                        </div>
                                        <div v-if="answer.type == 'paragraph'">
                                            <div v-for="(line, ix) in answer.answer.split('\n')" :key="`line-${ix}`">{{ line }}<br /></div>
                                        </div>
                                        <div v-if="answer.type == 'dropdown'">
                                            {{ answer.answer }}
                                        </div>
                                    </div>

                                    <div v-if="answer.type == 'dumbtext'">
                                        <div v-for="(line, ix) in answer.prompt.split('\n')" :key="`line2-${ix}`">{{ line }}<br /></div>
                                    </div>

                                    <div v-if="answer.type == 'dumbphoto'">
                                        <img :src="answer.prompt" alt=" " />
                                    </div>

                                    <div v-if="Array.isArray(answer.answer)">
                                        <div v-if="answer.type == 'checkboxes'">
                                            <div v-for="snippet in answer.answer" :key="snippet">&nbsp;• {{ snippet }}</div>
                                        </div>
                                        <div v-if="answer.type == 'pindrop'">
                                            <div>LATITUDE: {{ answer.answer[0] }}</div>
                                            <div>LONGITUDE: {{ answer.answer[1] }}</div>
                                            <div>What3Words: {{ answer.answer[2] }}</div>
                                        </div>
                                        <div v-if="answer.type == 'text' || answer.type == 'paragraph'">
                                            <div v-for="(text, idx) in getTextAnswer(answer.answer)" :key="`text-${idx}`">
                                                <div v-for="(line, inx) in text.split('\n')" :key="`line-${inx}`">{{ line }}<br /></div>
                                            </div>

                                            <!-- <div v-for="snippet in bit.answer" :key="snippet">&nbsp; {{snippet}}</div> -->
                                            <!-- <div v-for="(line, idx) in answer.answer.split('\n')" :key="`line-${idx}`">{{line}}<br></div> -->
                                        </div>
                                    </div>

                                    <div class="answer" v-if="answer.type == 'datetime'">
                                        {{ answer.answer == 'Choose an option' ? 'Not completed' : simpleDate(answer.answer) }}
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="logData.uploads && logData.uploads.length > 0" class="image-div hideForPrint">
                        <ul class="uploads">
                            <li class="image-wrap" v-for="(image, index) in logData.uploads" :key="`image2-${index}`">
                                <a :href="image.imageUrl" target="_blank">
                                    <span v-if="image.type == 'pdf'">
                                        <img src="@/assets/img/pdf-image-placeholder.png" />
                                    </span>
                                    <span v-else>
                                        <img :src="image.imageUrl" />
                                    </span>
                                </a>
                            </li>
                        </ul>
                        <div class="spacer s40"></div>
                    </div>

                    <div class="clear"></div>
                    <div class="spacer s40 hideForPrint"></div>

                    <div class="buttons hideForPrint">
                        <span id="pdfButton" class="button is-primary" @click="savePDF()">
                            {{ buttonText }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="copy-prompt hideForPrint" :class="{ visible: showCopyPrompt }">
                Copied to clipboard!
            </div>
        </div>

        <div class="spacer s50"></div>
    </div>
</template>

<script>
import { db } from '../main.js';
//   import firebase from 'firebase/app';
//   import 'firebase/auth';
//   import 'firebase/firestore';
import NProgress from 'nprogress';
// import * as moment from 'moment';
// import VueHtml2pdf from 'vue-html2pdf';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

export default {
    name: 'NewVesselResponseInfo',

    props: {
        sentVesselID: String,
        sentVesselName: String,
        showBack: Boolean
    },

    components: {
        // VueHtml2pdf,
    },

    computed: {
        // today() {
        //   const dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'};
        //   // const t = new Date();
        //   return new Date().toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        // },
    },

    mounted: function() {
        // console.log(this.$route);

        //   let urlParams = new URLSearchParams(window.location.search);
        //   this.queryID = urlParams.get('vid');

        let self = this;

        this.finalLogID = this.$route.query.lid ? this.$route.query.lid : '';

        if (this.finalLogID != '') {
            this.loadVesselData();
        } else {
            setTimeout(() => {
                self.dataError = true;
                self.errorMessage = 'No form data found.';
                self.loadingData = false;
            }, 1000);
        }
        // this.today = new Date().toDateString();
    },

    data: function() {
        return {
            finalVesselID: '',
            finalLogID: '',
            loadingData: true,
            vesselData: {},
            logData: {},
            vesselName: '',

            buttonText: 'Download PDF',

            // today: '',

            htmlToPdfOptions: {
                margin: 0.3,

                filename: `New-Vessel-Info.pdf`,

                image: {
                    type: 'jpeg',
                    quality: 2
                },

                enableLinks: false,

                html2canvas: {
                    scale: 1,
                    useCORS: true
                },

                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait'
                }
            },
            hideForPrint: false,
            showCopyPrompt: false,

            dataError: false,
            errorMessage: ''
        };
    },

    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },

        loadVesselData() {
            let self = this;
            db.collection('onboarding')
                .doc('newVesselQuestions')
                .collection('newVesselInfo')
                .doc(self.finalLogID)
                .get()
                .then(log => {
                    if (log.exists) {
                        self.logData = log.data();
                        self.dataError = false;
                    } else {
                        self.dataError = true;
                        self.errorMessage = 'There was an error loading the form data.';
                    }
                })
                .then(() => {
                    setTimeout(() => {
                        self.loadingData = false;
                        self.errorMessage = '';
                    }, 1500);
                })
                .catch(err => {
                    setTimeout(() => {
                        self.loadingData = false;
                        self.dataError = true;
                        self.errorMessage = 'There was an error loading the form data.';
                    }, 1500);
                });
        },

        copyText(isArray, isDate, data) {
            console.log(isArray, isDate, data);
            let self = this;
            let dateString = isDate ? data.toDate().toLocaleString('en-US', { timeZone: 'Australia/Sydney' }) : '';
            let copyData = isArray ? data.join('\r\n') : isDate ? dateString : data;
            this.$copyText(copyData).then(
                function(e) {
                    console.log(copyData);
                    self.showCopied();
                },
                function(e) {
                    alert('This data cannot be copied');
                    console.log(e);
                }
            );
        },

        showCopied() {
            this.showCopyPrompt = true;
            setTimeout(() => {
                this.showCopyPrompt = false;
            }, 1000);
        },

        formatDate: function(sentDate, format) {
            // return moment(sentDate).format(format);
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            return fixDate;
        },

        showDate(d) {
            // if (d) console.log(d.toDate().toDateString());
            // if (d) console.log(d.toDate().toTimeString());
            // if (d) console.log(d.toDate().toLocaleTimeString());
            // return d ? d.toDate().toDateString() + ' (' + d.toDate().toLocaleTimeString() + ')' : '';
            // logData.logTimestamp.toDate().toDateString() (logData.logTimestamp.toDate().toTimeString())
            const dateOptions = {
                // timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            const t = new Date(d.toDate());
            return t.toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        sorted: function(arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function(a, b) {
                return a.index - b.index;
            });
        },

        goBack: function() {
            this.$router.go(-1);
        },

        getTextAnswer(answer) {
            return Array.isArray(answer) ? answer : [answer];
        },

        simpleDate(sentDate) {
            console.log(sentDate);
            let fixDate = sentDate ? sentDate.toDate() : new Date();
            const dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            const myDate = new Date(sentDate.toDate());
            return myDate.toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        slugify2(str) {
            return String(str)
                .normalize('NFKD') // split accented characters into their base characters and diacritical marks
                .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
                .trim() // trim leading or trailing whitespace
                .toLowerCase() // convert to lowercase
                .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
                .replace(/\s+/g, '-') // replace spaces with hyphens
                .replace(/-+/g, '-'); // remove consecutive hyphens
        },

        savePDF() {
            this.buttonText = 'Preparing PDF. Please wait...';
            var self = this;
            let person = this.logData.questions[0].answer[0];
            let vesselName = this.logData.questions[4].answer[0];
            let slug = this.slugify2(vesselName);

            var generalEmail = firebase.functions().httpsCallable('generalEmail');
            generalEmail({
                senderEmail: '',
                senderName: '',
                senderUserID: '',
                subject: 'New vessel information form printed',
                message: person + ' has printed a new vessel information form for vessel ' + vesselName
            });

            // First we take the HTML of the page
            var html = document.documentElement.outerHTML;

            // Now we prepare the data that we pass to the API
            // In this example we want to hide the section with the example so we pass custom CSS
            // Note that you don't need to pass any other parameters if your defaults in the members area are already good
            var css =
                '.header, .copy-link { display:none !important;} #pdfButton {display:none;} .vue-back-to-top {display: none;} .hideForPrint {display: none;} .narrow {display: none;} .site-wrapper {padding-top: 0 !important;} .buttons {display: none !important;} .site-wrapper {padding-bottom: 0 !important; }';
            var data = { html: html, license: 'E197E92RdrKd', css: css };
            var serialized = Object.keys(data)
                .map(function(k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
                })
                .join('&');

            // You can insert an "in progress" message here

            // We now prepare the API call
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                var a;
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // The PDF is now generated
                    // You can remove the "in progress" message here

                    // Now we show the PDF to the user
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    a.download = 'new-vessel-info-' + slug + '.pdf';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    self.buttonText = 'Download PDF';
                }
            };

            // This is the actual call to our API
            xhttp.open('POST', 'https://pdfmyurl.com/api', true);
            xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhttp.responseType = 'blob';
            xhttp.send(serialized);
        }
    }
};
</script>
<style lang="scss" scoped>
#app .header {
    position: relative !important;
}

.site-wrapper .container {
    max-width: 1040px;
}

.edit-note {
    margin-top: 1rem;
    font-size: 1rem;
    color: steelblue;
    font-style: italic;
}

.copy-prompt {
    position: fixed;
    top: 80px;
    right: 20px;
    background: #a4dba4;
    color: #fff;
    padding: 1rem;
    border-radius: 6px;
    opacity: 0;
    transition: 0.2s;
    z-index: 9999;
    &.visible {
        opacity: 1;
    }
}

hr {
    margin-bottom: 4rem !important;

    &.narrow {
        margin-bottom: 1.5rem !important;
    }
}
.img-wrapper {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 1rem;
}

h3 {
    font-size: 1.2rem !important;
    text-transform: uppercase !important;
    margin-top: 1.5rem !important;
}

table {
    margin-top: 1rem !important;
    table-layout: fixed !important;
    width: 100% !important;

    tr {
        th {
            font-weight: normal !important;
            border: 1px solid #d4d4d4;
            padding: 12px 20px;
            font-family: 'Open Sans', sans-serif;
            text-align: left !important;
            word-break: break-word;
            position: relative;
            .copy-link {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 99;
                width: 40px;
                height: 40px;
                background: #f4f4f4;
                // display: flex;
                align-items: center;
                justify-content: center;
                display: none;
                cursor: pointer;
            }
            &:hover {
                .copy-link {
                    display: flex;
                }
            }
        }

        td {
            width: 50% !important;
            border: 1px solid #d4d4d4 !important;
            padding: 8px 12px;
            font-family: 'Open Sans', sans-serif;
            text-align: left !important;
            word-break: break-word;
            .link {
                margin-top: 5px;
            }
        }
    }
}

ul.uploads {
    list-style: none;
    // height: 250px;
    height: auto;
    margin-bottom: 2rem;

    .sorting-list {
        display: inline-flex;
        height: 100%;
    }

    li {
        display: inline-flex;
        max-width: 200px;
        min-width: 100px;
        margin: 0 1rem 1rem 0;
        height: 100%;
        float: left;
        // padding: 15px;
        border: 1px solid #ddd;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            align-self: center;
        }

        &.show {
            width: 100%;
            max-width: none;
            display: inline-table;

            img {
                max-width: none;
            }
        }

        .caption {
            border: none;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 0.9rem;
            width: 100%;
            padding: 8px;
            background: #f4f4f4;
            text-align: center;
            border: none;
            border-radius: 0;

            input {
                border: none;
                background: none;
                padding: 0;
                height: auto;
                min-height: 0;
                margin: 0;
                border-radius: 0;
                font-size: 0.9rem;
                line-height: 1rem;
                text-align: center;
            }

            textarea {
                min-height: 0;
                border: none;
                background: none;
                padding: 0;
                height: auto;
                min-height: 0;
                margin: 0;
                border-radius: 0;
                font-size: 0.9rem;
                line-height: 1rem;
                text-align: center;
            }
        }

        .cross {
            justify-content: center;
            position: absolute;
            top: -12px;
            right: -12px;
            z-index: 999;
            background: #dedede;
            padding: 10px;
            width: 35px;
            height: 35px;
            display: flex;
            border-radius: 50%;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                color: red;
            }
        }
    }
}
.pagebreak {
    display: block !important;
    clear: both !important;
    page-break-after: always !important;
}

@media only screen and (max-width: 550px) {
    table,
    tbody,
    tr,
    th,
    td {
        display: block;
    }
    tr {
        margin-bottom: 8px;
        th {
            width: 100%;
            &:first-of-type {
                border-bottom: none !important;
            }
        }
    }
}
</style>
