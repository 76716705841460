<template>
    <div>
        <div class="info-wrapper">
            <div v-if="!saving" class="">
                <h2><font-awesome-icon icon="ship" /> &nbsp;{{ heading }}</h2>
                <p class="sub-heading">{{ subHeading }}</p>
                <h4>{{ intro }}</h4>
                <div class="spacer s20"></div>

                <div class="form-section" v-for="(question, idx) in questions" :key="`q-${idx}`">
                    <div class="notification">
                        <h3
                            :class="{
                                red:
                                    question.required &&
                                    (question.answer == '' ||
                                        question.answer == null ||
                                        question.answer.length == 0 ||
                                        question.answer == 'Choose an option')
                            }"
                        >
                            {{ question.title }}
                            <span :class="{ red: question.required }" v-if="question.required"
                                >*</span
                            >
                        </h3>
                        <p class="field-prompt">{{ question.prompt }}</p>

                        <div class="question-type" v-if="question.type == 'text'">
                            <div class="text-input">
                                <input
                                    value="Text box"
                                    type="text"
                                    placeholder="Type answer..."
                                    v-model="question.answer[0]"
                                />
                            </div>
                        </div>

                        <div class="question-type" v-if="question.type == 'paragraph'">
                            <div class="text-input">
                                <textarea class="textarea" v-model="question.answer[0]"></textarea>
                            </div>
                        </div>

                        <div
                            v-if="question.type == 'dropdown'"
                            class="question-type form-group-reg"
                        >
                            <div class="select">
                                <select v-model="question.answer[0]">
                                    <option value="Choose an option">Choose an option</option>
                                    <option
                                        v-for="(option, ix) in question.options"
                                        :value="option"
                                        :key="`option-${ix}`"
                                        >{{ option }}</option
                                    >
                                </select>
                            </div>
                            <div class="spacer s10"></div>
                        </div>

                        <div
                            v-if="question.type == 'checkboxes'"
                            class="question-type checkbox-wrapper"
                        >
                            <div
                                class="field"
                                v-for="(option, ix) in question.options"
                                :key="`check-${ix}`"
                            >
                                <input
                                    class="is-checkradio"
                                    type="checkbox"
                                    :id="option"
                                    :value="option"
                                    v-model="question.answer"
                                />
                                <label :for="option">{{ option }}</label>
                            </div>
                        </div>
                    </div>

                    <!-- {{ question.answer }} -->
                </div>
            </div>

            <!-- {{ incompleteFields }} -->
            <span class="red align-right">* Answer required</span>

            <hr />

            <div v-if="!saving" class="buttons centered">
                <button
                    :disabled="incompleteFields.length > 0"
                    class="button is-primary"
                    @click="review()"
                >
                    Submit Answers
                </button>
            </div>

            <div v-if="saving" class="save-message">{{ saveMsg }}</div>

            <div class="spacer s100"></div>

            <div
                class="modal modal-full-screen modal-fx-fadeInScale"
                v-bind:class="{ 'is-active': showReviewModal }"
            >
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head is-primary">
                        <p class="modal-card-title">
                            {{
                                formSubmitted
                                    ? 'Log Complete'
                                    : incompleteFields.length > 0
                                    ? 'Errors found'
                                    : 'Ready to Submit'
                            }}
                        </p>
                        <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                    </header>

                    <section class="modal-card-body">
                        <div v-if="formSubmitted">
                            <p class="green bold">Vessel info sent at: {{ today }}</p>
                            <div class="spacer s10"></div>
                        </div>

                        <div v-else>
                            <div v-if="!reviewing">
                                <div v-if="reviewed">
                                    <p class="green bold">{{ today }}</p>
                                    <div class="spacer s20"></div>
                                    <div class="review-form">
                                        <div class="review-field">
                                            <label
                                                class="label"
                                                :class="{ red: formUserName == '' }"
                                                >Your name:</label
                                            >
                                            <input
                                                type="text"
                                                class="input"
                                                placeholder="Type your full name"
                                                v-model="formUserName"
                                            />
                                        </div>
                                        <div class="spacer s10"></div>

                                        <div class="review-field">
                                            <!-- <input
                                                class="is-checkradio normal"
                                                type="checkbox"
                                                id="localDifferent"
                                                v-model="sendEmail"
                                            />
                                            <label for="localDifferent"
                                                >I'd like to </label
                                            > -->
                                            <div class="spacer s10"></div>
                                            <div v-if="sendEmail">
                                                <label
                                                    class="label"
                                                    :class="{ red: !emailValid(formUserEmail) }"
                                                    >Your email address:</label
                                                >
                                                <input
                                                    type="text"
                                                    class="input"
                                                    placeholder="Type your email"
                                                    v-model="formUserEmail"
                                                />
                                            </div>
                                            <div class="spacer s20"></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="spacer s10"></div>
                                    <p>
                                        {{ incompleteFields.length }} incomplete fields found.
                                        Please go back and check answers.
                                    </p>
                                    <div class="spacer s10"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer v-if="!formSubmitted" class="modal-card-foot">
                        <button class="button" @click="close()">Cancel</button>
                        <button
                            :disabled="
                                incompleteFields.length > 0 ||
                                    formUserName == '' ||
                                    (sendEmail && !emailValid(formUserEmail))
                            "
                            class="button is-primary"
                            @click="saveLog()"
                        >
                            Save Log
                        </button>
                    </footer>
                    <footer v-else class="modal-card-foot">
                        <button class="button is-primary" @click="close()">All Done!</button>
                    </footer>
                </div>
            </div>
        </div>

        <div class="clear"></div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';

export default {
    data: function() {
        return {
            saving: false,
            saveMsg: '',
            heading: 'heading coming!',
            intro: 'intro here..',
            subHeading: '',
            questions: [],
            answers: {
                one: '',
                two: '',
                three: ''
            },
            loadingQuestions: false,
            formUserName: '',
            sendEmail: true,
            formUserEmail: '',
            showReviewModal: false,
            formSubmitted: false,
            reviewing: false,
            reviewed: true,
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        };
    },
    mounted() {
        NProgress.start();
        this.loadSetup();
    },
    computed: {
        incompleteFields() {
            return this.questions
                .filter(
                    m =>
                        m.required &&
                        (m.answer == '' ||
                            m.answer == null ||
                            m.answer.length == 0 ||
                            m.answer == 'Choose an option')
                )
                .map(n => n.title);
        },
        showButton() {
            return this.testimonial != '' && this.author != '';
        },
        user() {
            return this.$store.getters.getUser;
        },
        today() {
            const dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            return new Date().toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        }
    },
    methods: {
        loadSetup() {
            let self = this;
            db.collection('onboarding')
                .doc('requestSmsReview')
                .get()
                .then(doc => {
                    self.heading = doc.data().heading;
                    self.intro = doc.data().intro;
                    self.subHeading = doc.data().subHeading;
                })
                .then(_ => {
                    self.loadQuestions();
                })
                .catch(err => {
                    console.log('Error', err);
                    self.loadingQuestions = false;
                    NProgress.done();
                });
        },
        async loadQuestions() {
            let self = this;
            db.collection('onboarding')
                .doc('requestSmsReview')
                .collection('questions')
                .orderBy('index', 'asc')
                .onSnapshot(querySnapshot => {
                    self.questions = [];
                    querySnapshot.forEach(doc => {
                        // console.log(doc.data());
                        self.questions.push(doc.data());
                    });
                });

            setTimeout(() => {
                self.loadingQuestions = false;
                NProgress.done();
            }, 1000);
        },
        emailValid(email) {
            return this.reg.test(email) && email.includes('@');
        },

        close() {
            console.log('close');
            if (this.formSubmitted) {
                this.$router.push({ name: 'new-home' });
            } else {
                this.showReviewModal = false;
                this.reviewed = false;
                this.reviewing = false;
            }
        },
        submit() {
            let self = this;
            NProgress.start();
            this.saving = true;
            this.saveMsg = 'Saving your testimonial...';
            let newData = {
                author: self.author,
                town: self.town,
                testimonial: self.testimonial,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            };
            db.collection('testimonials')
                .add(newData)
                .then(function(doc) {
                    self.saveMsg = 'Thank you! We really value your support!';
                    NProgress.done();
                    setTimeout(() => {
                        self.$router.push({ name: 'new-home', params: {} });
                    }, 3000);
                    let addRevision = firebase.functions().httpsCallable('addRevision');
                    addRevision({
                        userID: '',
                        userName: self.town ? self.town : 'from somewhere',
                        description: self.author + ' has left a new testimonial'
                    });
                })
                .catch(function(error) {
                    console.log(error);
                    NProgress.done();
                });
        },

        review() {
            let self = this;
            NProgress.start();
            // this.formUserName = this.formUserName;
            this.finalAnswers = [];
            this.reviewed = false;
            this.reviewing = true;
            setTimeout(() => {
                this.reviewing = false;
                this.reviewed = this.incompleteFields.length == 0;
                this.showReviewModal = true;
                NProgress.done();
            }, 500);
        },

        async saveLog() {
            NProgress.start();
            let self = this;
            let notifyEmails = [];
            if (this.sendEmail) notifyEmails.push(this.formUserEmail);
            console.log(notifyEmails);

            let newData = {
                userName: self.formUserName,
                userEmail: self.formUserEmail,
                questions: self.questions,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            };
            let emailList = [self.formUserEmail, 'help@offshoresms.com.au'];
            db.collection('onboarding')
                .doc('requestSmsReview')
                .collection('smsReviewRequests')
                .add(newData)
                .then(docRef => {
                    let emailData = {
                        logID: docRef.id,
                        userName: self.formUserName,
                        vesselName: self.questions[0].answer[0],
                        emailList: emailList
                    };
                    console.log(emailData);
                    var smsReviewRequestNotification = firebase
                        .functions()
                        .httpsCallable('smsReviewRequestNotification');
                    smsReviewRequestNotification(emailData);
                })
                .catch(error => {
                    console.log(error);
                    NProgress.done();
                });

            setTimeout(() => {
                self.formSubmitted = true;
                NProgress.done();
            }, 500);
        }
    }
};
</script>

<style lang="scss" scoped>
.info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3rem auto 10rem;
    padding: 0 20px;
    max-width: 1000px;
    h2 {
        font-family: 'Roboto Slab', serif;
        color: #2562a4;
        font-size: 1.5rem;
        line-height: 25px;
        margin: 0 auto 1.5rem;
        display: flex;
        align-items: start;
        justify-content: center;
    }
    h4 {
        text-align: center;
    }
}

.form-section {
    margin-bottom: 15px;
    &.last {
        border: none;
    }
    .notification {
        text-align: left;
        background-color: #f9f9f9;
        margin-bottom: 12px;
        h3 {
            color: cadetblue;
            font-family: 'Roboto Slab', serif;
            font-size: 1.1rem;
            margin-bottom: 8px;
            &.red {
                color: red;
            }
        }
        .field-prompt {
            margin-bottom: 12px;
        }
        .question-type {
            input[type='text'] {
                height: auto;
                padding: 15px;
            }
        }
    }
    .padded-hr {
        margin: 2rem 0;
    }
}
.review-field {
    input[type='text'] {
        height: auto;
        padding: 15px;
    }
}
.sub-heading {
    color: cadetblue;
    text-align: center;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
    .split-wrapper {
        flex-direction: column;
        padding: 0;
        .left-side {
            width: 100%;
            padding-top: 0;
            &:after {
                content: '';
                height: 1px;
                background-color: #e9e9e9;
                width: 80%;
                margin: 3rem auto 0;
                display: block;
            }
        }
        .right-side {
            width: 100%;
            padding-top: 1rem;
            .save-message {
                text-align: center;
            }
        }
    }
}
</style>
