<template>
	
	<div>

		<div class="site-wrapper no-header">

			<div class="container full-width">

				<div class="content has-text-centered">
          <div class="spacer s20"></div>
						<h3>SMS Features &amp; Pricing</h3>
						<p>It is 100% Free for anyone to join OffshoreSMS.</p>
            <p>If you are a vessel owner or operator, you can add as many vessels as you like, and each vessel comes with 100% Free access to digital logs in the app!</p>
            <p>We have a range of feature options to suit whatever you need (see features below):</p>
				</div>

				<div class="spacer s20"></div>

				<!-- <div class="pricing-table">

				  <div class="pricing-plan is-warning" :class="[ chosenPlan === 'Free' ? 'is-active' : '']">
				    <div class="plan-header">SKIPPERS &amp; CREW</div>
				    <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>0</span></div>
				    <div class="plan-items">
				      <div class="plan-item">ALWAYS FREE!</div>
              <div class="plan-item">Get added as crew on other vessels</div>
				      <div class="plan-item">View crewed vessel SMSs</div>
              <div class="plan-item">Log your sea time</div>
              <div class="plan-item">Build your own profile</div>
              <div class="plan-item">Record your qualifications</div>
              <div class="plan-item">View your inductions</div>
              <div class="plan-item">Add unlimited Trial vessels</div>
				    </div>
            <div class="plan-footer">
              <router-link to="sign-up" class="button is-fullwidth is-medium">
                <span>100% FREE!</span>
              </router-link>
            </div>
				    <div class="plan-footer">
				      <button :disabled="userExists" @click="signupNow" class="button is-fullwidth is-medium">
				      	<span>Join for Free</span>
				      </button>
				    </div>
				  </div>

				  <div class="pricing-plan is-primary" :class="[ chosenPlan === 'Starter' ? 'is-active' : '']">
				    <div class="plan-header">OWNER OPERATOR</div>
				    <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>149</span> <span class="price-rate">per vessel<br>per year</span></div>
				    <div class="plan-items">
              <div class="plan-item">Perfect for small operators</div>
              <div class="plan-item">Easy Step-by-Step SMS Builder</div>
              <div class="plan-item">Daily Logs and Crew Inductions</div>
              <div class="plan-item">Log anything in the Ship's Log</div>
              <div class="plan-item">Log Maintenance &amp; defects</div>
              <div class="plan-item">Loads of Helper Content</div>
				      <div class="plan-item">Find and add crew to your vessel</div>
				      <div class="plan-item">Save Important Dates</div>
              <div class="plan-item">Safety equipment register</div>
				      <div class="plan-item">Free Help and Support</div>
              <div class="plan-item">Create Passenger Manifests</div>
              <div class="plan-item">Log head counts in the app</div>
              <div class="plan-item">PERFECT FOR 1 OR 2 VESSELS!</div>
				    </div>
            <div class="plan-footer">
              <router-link to="sign-up" class="button is-fullwidth is-medium">
                <span>Less than $3 per week</span>
              </router-link>
            </div>
				    <div v-if="userExists" class="plan-footer">
				      <button :disabled="userData.userrole != 'free'" @click="signupNow" class="button is-fullwidth is-medium">
				      	<span>{{userData.userrole == 'starter' ? 'Current Plan' : userData.userrole == 'fleet' ? 'Starter Plan' : 'Buy Now'}}</span>
				      </button>
				    </div>
            <div v-else class="plan-footer">
              <button :disabled="userData.userrole == 'starter'" @click="signupNow" class="button is-fullwidth is-medium">
                <span>Sign Up Now</span>
              </button>
            </div>
            <div class="plan-footer">
              <button :disabled="userExists" @click="signupNow" class="button is-fullwidth is-medium">
                <span>Sign Up Now</span>
              </button>
            </div>
				  </div>

				 

				  <div class="pricing-plan is-danger">
				    <div class="plan-header">FLEET VESSELS</div>
				    <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>249</span> <span class="price-rate">per vessel<br>per year</span></div>
				    <div class="plan-items">
				      <div class="plan-item">Fleet Management Dashboard</div>
				      <div class="plan-item">Real-time Activity Logs</div>
				      <div class="plan-item">Monitor Vessel SMS Compliance</div>
				      <div class="plan-item">Expired Safety Equipment Register</div>
              <div class="plan-item">Important Fleet and vessel docs</div>
              <div class="plan-item">Scheduled Maintenance and Drills</div>
				      <div class="plan-item">Real time defect Register</div>
              <div class="plan-item">Passenger vessel safety tools</div>
              <div class="plan-item">Get vessel data in real time</div>
              <div class="plan-item">New timed activities</div>
              <div class="plan-item">Print out reports as PDFs</div>
              <div class="plan-item">Activity logs</div>
              <div class="plan-item">Shutdown logs</div>
              <div class="plan-item">Crew change logs</div>
              <div class="plan-item">Unlimited custom logs</div>
              <div class="plan-item">Real time signed crew inductions</div>
              <div class="plan-item">Live SMS updates to crew</div>
              <div class="plan-item">Free Help and Support</div>
				      <div class="plan-item">PERFECT FOR MULTI-VESSEL FLEETS!</div>
				    </div>
            <div class="plan-footer">
              <router-link to="sign-up" class="button is-fullwidth is-medium">
                <span>Less than $5 per week</span>
              </router-link>
            </div>
				    <div class="plan-footer">
				      <button @click="contactModal = true" class="button is-fullwidth">
				      	<span>Register an Interest</span>
				      </button>
				    </div>
				    <div class="plan-footer">
              <router-link to="fleet-enquiry" class="button is-fullwidth is-medium">Find Out More</router-link>
				    </div>            
            <div class="plan-footer">
              <button :disabled="userExists" @click="signupNow" class="button is-fullwidth is-medium">
                <span>Let's Get Started</span>
              </button>
            </div>
				  </div>

				</div> -->




        <div class="pricing-wrapper">

            <div class="pricing-table">

              <div class="pricing-plan is-warning">
                <div class="plan-header">Digital Logs</div>
                <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency"></span>100% Free</span></div>
                <div class="plan-items">
                  <div class="plan-item">Lightning fast logs<br>in the OffshoreSMS app</div>
                  <div class="plan-item">Log everything from<br>defects to inductions</div>
                  <div class="plan-item">All logs are saved securely<br>in the cloud</div>
                  <div class="plan-item">Get rid of paper logs<br>on your vessel for ever!</div>
                  <!-- <div class="plan-item">Perfect for any boat<br>anywhere on earth!</div> -->
                </div>
                <div class="plan-footer">
                  <router-link to="plan-logs" class="button is-fullwidth">All Features</router-link>
                </div>
              </div>

              <!-- <div class="pricing-plan is-info">
                <div class="plan-header">Digital SMS</div>
                <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>129</span> <span class="price-rate">per vessel<br>per year</span></div>
                <div class="plan-items">
                  <div class="plan-item">Build a fully compliant<br>safety management system</div>
                   <div class="plan-item">Get a Fast Track SMS or<br>use our expert helpers</div>
                  <div class="plan-item">Add unlimited crew and put<br>your SMS in their pockets!</div>
                  <div class="plan-item">Print an up-to-date copy of<br>your SMS at any time</div>
                </div>
                <div class="plan-footer">
                  <router-link to="plan-sms" class="button is-fullwidth">All Features</router-link>
                </div>
              </div> -->

              <div class="pricing-plan is-primary">
                <div class="plan-header">Logs + SMS</div>
                <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>149</span> <span class="price-rate">per vessel<br>per year</span></div>
                <div class="plan-items">
                  <div class="plan-item">Get all in-app digital logs<br>PLUS a safety management<br>system and crew access</div>
                   <div class="plan-item">Go fully paperless!</div>
                  <div class="plan-item">Let your crew access your<br>vessel's SMS from anywhere</div>
                  <div class="plan-item">Includes our new crew<br>Self Inductions in the app</div>
                </div>
                <div class="plan-footer">
                  <router-link to="plan-logs-sms" class="button is-fullwidth">All Features</router-link>
                </div>
              </div>

              <div class="pricing-plan is-danger">
                <div class="plan-header">Pro Plan</div>
                <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>249</span> <span class="price-rate">per vessel<br>per year</span></div>
                <div class="plan-items">
                  <div class="plan-item">Allow skippers to do all<br>logs on your behalf</div>
                   <div class="plan-item">Create unlimited custom<br>logs and forms</div>
                  <div class="plan-item">Scheduled maintenance and<br>vessel drills</div>
                  <div class="plan-item">Includes passenger tools<br>& timed activities</div>
                </div>
                <div class="plan-footer">
                  <router-link to="plan-pro" class="button is-fullwidth">All Features</router-link>
                </div>
              </div>

            </div>

            

        </div>


        <!-- <div class="pricing-wrapper">

            <div class="pricing-table">

              <div class="pricing-plan is-success">
                <div class="plan-header">Or just create your FREE Profile</div>
                <div class="plan-price"><span class="plan-price-amount"><span class="plan-price-currency">$</span>0</span> <span class="price-rate">per user</span></div>
                <div class="plan-items">
                  <div class="plan-item">Perfect for crew and skippers that work on a boat</div>
                  <div class="plan-item">100% free and you can still trial any vessel plan with no obligation</div>
                  <div class="plan-item">Use our free sea time tracker in the app with any free profile</div>
                  <div class="plan-item">Test out every feature before you buy anything!</div>
                  <div class="plan-item">Get found by potential maritime employers</div>
                </div>
                <div class="plan-footer">
                  <router-link to="sign-up" class="button is-fullwidth is-medium">Create your FREE profile</router-link>
                </div>
              </div>

            </div>

            

        </div> -->





        <div class="spacer s20"></div>

        <div class="buttons centered">
          <span v-if="!userExists" class="button is-medium is-success" @click="trackLink('sign-up','signup')">Join for FREE and decide later</span>
          <span v-else class="button is-medium is-primary" @click="trackLink('my-dashboard','dashboard')">Member Log in</span>
        </div>

        <div class="spacer s20"></div>

        <hr>

				<div class="has-text-centered">
					<!-- <hr> -->
					<!-- <p>Did you know that we offer a 30-Day 100% Money Back Guarantee?</p> -->
					<div class="has-text-centered">
						<!-- <p>&nbsp;</p>
						<p>* Billed Annually.</p> -->
						<p>&nbsp;</p>
						<p>Did you know that we offer a 30 Day 100% Money Back Guarantee if you're not completely happy?</p>
						<p>&nbsp;</p>
						<span @click="isActiveMoreInfo = true" class="button is-info">Need more information?</span>
						<p>&nbsp;</p>
					</div>
				</div>


        <div class="spacer s100"></div>


				<!-- <hr>

				<div class="has-text-centered">
					<p>&nbsp;</p>
					<router-link class="button is-info is-medium" to="sign-up">Sign Up Now</router-link>
					<p></p>
				</div> -->

			</div>


		</div>


		<div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':signupModal}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
      	
			    <header class="modal-card-head">
			      <p class="modal-card-title">{{chosenDescription}}</p>
			      <!-- <button class="delete" aria-label="close" @click.prevent="close"></button> -->
			    </header>
			    <section class="modal-card-body">		

			    	<p class="is-size-5">Great choice!</p>
			    	<p>To sign up on Offshore SMS with the {{chosenDescription}}, please click the Sign Up button below.</p>

			    </section>
			    <footer class="modal-card-foot">
			      <button class="button is-primary" @click.prevent="signup">Sign Up</button>
			      <button class="button" @click="close">Back</button>
			    </footer>        

	  	</div>
    </div>


		<div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':contactModal}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
      	
      	<form id="contactForm" @submit.prevent="sendContactEmail">

			    <header class="modal-card-head">
			      <p class="modal-card-title">Fleet Enquiry</p>
			      <!-- <button class="delete" aria-label="close" @click.prevent="close"></button> -->
			    </header>
			    <section class="modal-card-body">		

			    	<div v-if="!formSent">
				    	<p class="is-size-5">Register your interest for a Fleet SMS solution.</p>
				    	<p>Fill in some details below then hit send, and we will be in touch with you as soon as possible.</p>
				    	<p>&nbsp;</p>
				    	<input type="text" class="input" placeholder="Company name" v-model="enquiryCompany" required />
				    	<input type="text" class="input" placeholder="Contact person" v-model="enquiryContact" required />
				    	<input type="text" class="input" placeholder="Phone number" v-model="enquiryPhone" required />
				    	<input type="text" class="input" placeholder="Email address" v-model="enquiryEmail" required />
				    	<textarea rows="3" class="input" placeholder="Details about your operation (number of vessels, location etc)..." v-model="enquiryDetails"></textarea>
				    </div>

			  		<div v-if="formSent">Thank you for your enquiry. We'll be in touch as soon as possible.</div>  

			    </section>
			    <footer class="modal-card-foot">
			      <!-- <button class="button is-primary" @click.prevent="sendContactEmail">Send Enquiry</button> -->
			      <button v-show="showSendContact && !formSent" class="button is-primary" type="submit">Send Enquiry</button>
			      <button class="button" @click="close">Close</button>
			    </footer>  

			  </form>    

	  	</div>
    </div>


    <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':isActiveMoreInfo}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
      		<header class="modal-card-head">
			      <p class="modal-card-title">Need more information?</p>
			      <button class="delete" aria-label="close" @click.prevent="close"></button>
			    </header>

					<section class="modal-card-body">
            <div>
              <p>Click here to view our <router-link to="faqs">Frequently Asked Questions</router-link>.</p>
              <p>Still need some more information? Please feel free to give us a call on <a href="tel:+61-447-139-382">+61 447 139 382</a> and let's have a chat.</p>
            </div>
          </section>

					<footer class="modal-card-foot">
			      <!-- <button class="button is-primary" @click="checkout">Checkout</button> -->
			      <button class="button cancel" @click.prevent="close">Done</button>
			    </footer>
	  	</div>
    </div>



	</div>

</template>



<script>

	import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/compat/firestore';
  import 'firebase/compat/functions';
	import NProgress from 'nprogress';

	export default {

		name: 'Pricing',
		data: function() {
			return {
				msg: 'Pricing',
        firebaseid: '',
        userExists: false,
				chosenPlan: '',
				chosenDescription: '',
				chosenPrice: '',
				chosenDisplayPrice: '',
				plans: {
					'Free': { plan: 'Free', description: 'Crew plan', price: 0, class: 'is-warning', vessels: 0 },
					'Starter': { plan: 'Starter', description: 'SMS Starter plan', price: 149, class: 'is-primary', vessels: 2 },
					'Fleet': { plan: 'Fleet', description: 'Fleet plan', price: 249, class: 'is-danger', vessels: 10 }
				},
				signupModal: false,
				contactModal: false,
				enquiryCompany: '',
				enquiryContact: '',
				enquiryPhone: '',
				enquiryEmail: '',
				enquiryDetails: '',
				formSent: false,
				isActiveMoreInfo: false,
			}
		},
		computed: {
			showSendContact() {
				return this.enquiryCompany != '' & this.enquiryContact != '' && this.enquiryPhone != '';
			},
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
		},

    mounted() {
      firebase.auth().onAuthStateChanged( this.onUserLogin );
    },

		methods: {

      onUserLogin: function( user ) {
        let self = this;
        if (user) {         
          this.firebaseid = user.uid;     
          this.userExists = true;     
        }
      },

			choosePlan: function(choice) {
				this.chosenPlan = choice;
				this.chosenDescription = this.plans[choice].description;
				this.chosenPrice = this.plans[choice].price;
				this.chosenDisplayPrice = "$" + this.plans[choice].price + "/year";
				this.signupModal = true;
			},

			// signupNow: function() {
			// 	if (this.userExists) {
   //        this.$router.replace('sms-signup');
   //      } else {
   //        this.$router.replace('sign-up');
   //      }
			// },

      trackLink(page,type) {
        console.log(page,type);
        NProgress.start();
        let addRevision = firebase.functions().httpsCallable("addRevision");
        addRevision({
          userID: '-',
          userName: '-',
          description: 'A user has clicked through to the app.offshoresms ' + type + ' page.'
        });
        // this.$router.replace(page);
        window.location.href = 'https://app.offshoresms.com.au/' + page;
        // NProgress.done();
      },

			close: function() {
				this.signupModal = false;
				this.contactModal = false;
				this.enquiryCompany = '';
				this.enquiryContact = '';
				this.enquiryPhone = '';
				this.enquiryEmail = '';
				this.enquiryDetails = '';
				this.isActiveMoreInfo = false;
			},

			signup: function() {
				//console.log('Sign me up');
	      this.$router.push({ 
	        name: 'signUp', 
	      })
			},

			sendContactEmail: function() {
				//console.log(this.enquiryCompany,this.enquiryContact,this.enquiryPhone,this.enquiryEmail,this.enquiryDetails);
				NProgress.start();
				var self = this;
				var enquiryArray = {
					enquiryCompany: self.enquiryCompany,
					enquiryContact: self.enquiryContact,
					enquiryPhone: self.enquiryPhone,
					enquiryEmail: self.enquiryEmail,
					enquiryDetails: self.enquiryDetails
				}
				var sendEmailFleetEnquiry = firebase.functions().httpsCallable('sendEmailFleetEnquiry');
        sendEmailFleetEnquiry({
        	enquiryArray: enquiryArray
        }); 

        this.formSent = true;
        NProgress.done();
				
			}


		}

	}


</script> 




<style lang="scss" scoped>
	
	.container {

	}
	textarea {
		min-height: 100px !important;
		box-shadow: none;
    padding: 0.6rem 1rem;
	}

	.pricing-table {
		.pricing-plan {
      padding-bottom: 1rem;
      margin-bottom: 2rem;
			.plan-header {
				padding-bottom: 0;
			}
			.plan-footer {
				margin-top: 0;
		    margin-bottom: 1rem;
        .button {
          margin-top: 1rem;
          height: auto;
        }
		  }
		  .plan-price {
				.price-rate {
					width: 70px;
			    display: inline-block;
			    margin-left: 5px;
			    line-height: 14px;
			    text-align: left;
				}
			}
		}
	}

</style> 