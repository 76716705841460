<template>
    <div>
        <div class="site-wrapper no-header">
            <div ref="smsWrapper" class="container" v-if="exportID != ''">
                <div v-if="showBack" class="buttons hideForPrint">
                    <div @click="goBack()" class="button is-info">
                        <span><font-awesome-icon icon="chevron-left"/></span> &nbsp; Back
                    </div>
                    <hr v-if="showBack" class="narrow" />
                </div>

                <div
                    style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 2rem; outline: none;"
                >
                    Offshore SMS
                </div>
                <h2 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;">
                    {{ details.vesselname }} Timed Activity Export from {{ vesselName }} -
                    {{ today }}
                </h2>
                <div class="spacer s10"></div>
                <p>(EXPORT TIMESTAMP: {{ formatDate(currentExport.timestamp) }})</p>

                <div class="spacer s20"></div>

                <div class="edit-note hideForPrint">
                    <p class="italic">
                        Optionally remove rows to filter timers and update the total.
                    </p>
                </div>
                <div class="spacer s20"></div>

                <hr />

                <!-- <div v-if="details.intro != 'None'">
                    <h3 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;"><font-awesome-icon icon="exclamation-triangle" />&nbsp;ABOUT THIS SAFETY MANAGEMENT SYSTEM</h3>
                    <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                    <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                        <tr>
                        <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'helvetica', sans-serif;  text-align: left !important;">
                            <div contenteditable="true" v-for="line in details.intro.split('\n')" :key="line">{{line}}<br></div>
                        </td>
                        </tr>
        
                    </tbody>
                    </table>
                </div> -->

                <div v-if="details.avatarUrl != ''">
                    <div class="spacer s10"></div>
                    <img
                        style="max-width: 100%; width: auto;"
                        class="avatarUrl"
                        :src="details.avatarUrl"
                    />
                </div>

                <p class="total-time">{{ timedActivitiesFinalTotal }}</p>
                <div class="spacer s10"></div>

                <table
                    style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:0;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;"
                    class="table is-bordered is-fullwidth"
                >
                    <tbody
                        style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;"
                    >
                        <tr>
                            <th
                                style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;"
                            >
                                Activity
                            </th>
                            <!-- <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Logged By</th> -->
                            <th
                                style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;"
                            >
                                Time Splits / Notes
                            </th>
                            <th
                                style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;"
                            >
                                Final Time
                            </th>
                            <th class="hideForPrint removeCol">Remove</th>
                        </tr>

                        <tr
                            v-for="item in currentExport.selectedActivities"
                            :class="{ hidden: !item.show }"
                            :key="item.id"
                        >
                            <td
                                style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important; padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;"
                            >
                                <span
                                    style="font-weight: bold !important; text-transform: uppercase !important; font-size: 0.9rem;"
                                    >{{ item.activityName }} - {{ item.show }}</span
                                ><br />
                                <span style=" font-size: 0.8rem;">START:</span>
                                {{ formatDate(item.startTimestamp) }}<br />
                                <span style=" font-size: 0.8rem;">STOP:</span>
                                {{ formatDate(item.finishTimestamp) }}
                                <!-- <br>REAL FINISH: {{formatDate(item.realFinishTimestamp, 'D MMM YYYY (HH:mm)')}} -->
                            </td>
                            <!-- <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.activityName}}</td> -->
                            <!-- <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">
                                START: {{item.startedBy}}<br>STOP: {{item.completedBy}}
                            </td> -->
                            <td
                                style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;"
                            >
                                <div
                                    v-for="(note, idx) in item.activityNotes"
                                    :key="`note-${idx}`"
                                    style="margin-bottom: 10px;"
                                >
                                    {{ formatDate(note.timestamp) }}<br />
                                    <div style="margin-left:6px; font-size: 0.8rem;">
                                        {{ note.notes }}
                                    </div>
                                </div>
                            </td>
                            <td
                                style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;"
                            >
                                {{
                                    getFinalTime(item.finalDays, item.finalHours, item.finalMinutes)
                                }}
                            </td>
                            <td class="hideForPrint removeCol">
                                <span
                                    class="button is-delete"
                                    @click="deleteRowTimedActivities(item)"
                                    ><font-awesome-icon icon="trash-alt"
                                /></span>
                            </td>
                        </tr>

                        <tr v-if="currentExport.selectedActivities.length == 0">
                            <td colspan="5">No timed activities exported.</td>
                        </tr>
                    </tbody>
                </table>

                <div class="buttons">
                    <button class="button is-warning" @click="resetActivities()">Reset</button>
                    <button
                        :disabled="filteredTimedActivitiesLogs.length == 0"
                        class="button is-light"
                        @click="savePDF()"
                    >
                        <font-awesome-icon icon="print" /> &nbsp; {{ buttonText }}
                    </button>
                </div>

                <!-- <div v-if="allExports.length > 0" class="buttons">
                    <span class="button is-light" @click="savePDF()"><font-awesome-icon icon="print" /> &nbsp; {{buttonText}}</span>
                </div> -->

                <!-- <h3 style="font-family: 'Open Sans', sans-serif;color: #444;font-size: 1.2rem;text-transform: uppercase;margin-top: 2.5rem;"><font-awesome-icon icon="user-graduate" />&nbsp; Vessel Contact Information</h3>
                <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                    <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                    <tr>
                        <td style="width:30% !important;font-weight: bold !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Owner name</td>
                        <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{details.vesselOwnerName}}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: normal !important;font-size: 0.9rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Phone number</td>
                        <td contenteditable="true" style="width: 50% !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-size: 0.9rem;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{details.vesselOwnerPhone}}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: normal !important;font-size: 0.9rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Email address</td>
                        <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{details.vesselOwnerEmail}}</td>
                    </tr>
                    <tr v-if="!details.isCompanyVessel">
                        <td style="font-weight: normal !important;font-size: 0.9rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Shore Contact Name</td>
                        <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{details.shoreContactName}}</td>
                    </tr>
                    <tr v-if="!details.isCompanyVessel">
                        <td style="font-weight: normal !important;font-size: 0.9rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Shore Contact Number</td>
                        <td contenteditable="true" style="width: 50% !important;font-size: 0.9rem;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif; text-align: left !important;">{{details.shoreContactNumber}}</td>
                    </tr>
                    </tbody>
                </table> -->

                <!-- <span id="pdfButton" class="button is-primary" @click="savePDF()">{{buttonText}}</span> -->
            </div>

            <div class="container" v-else>
                <p>No export data found.</p>
                <p>&nbsp;</p>
            </div>

            <div class="spacer s90"></div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import NProgress from 'nprogress';

export default {
    name: 'FastTrackViewSms',

    props: {
        sentVesselID: String,
        sentVesselName: String,
        sentExportID: String,
        showBack: Boolean
    },

    components: {},

    computed: {
        timedActivitiesTotalDays() {
            // return this.allTimedActivities.length > 0 ? this.allTimedActivities.map((item) => item.finalDays).reduce((acc, current) => parseInt(acc) + parseInt(current)) : 0;
            return this.filteredTimedActivitiesLogs.length > 0
                ? this.filteredTimedActivitiesLogs
                      .map(item => item.finalDays)
                      .reduce((acc, current) => parseInt(acc) + parseInt(current))
                : 0;
        },
        timedActivitiesTotalHours() {
            // return this.allTimedActivities.length > 0 ? this.allTimedActivities.map((item) => item.finalHours).reduce((acc, current) => parseInt(acc) + parseInt(current)) : 0;
            return this.filteredTimedActivitiesLogs.length > 0
                ? this.filteredTimedActivitiesLogs
                      .map(item => item.finalHours)
                      .reduce((acc, current) => parseInt(acc) + parseInt(current))
                : 0;
        },
        timedActivitiesTotalMinutes() {
            // return this.allTimedActivities.length > 0 ? this.allTimedActivities.map((item) => item.finalMinutes).reduce((acc, current) => parseInt(acc) + parseInt(current)) : 0;
            return this.filteredTimedActivitiesLogs.length > 0
                ? this.filteredTimedActivitiesLogs
                      .map(item => item.finalMinutes)
                      .reduce((acc, current) => parseInt(acc) + parseInt(current))
                : 0;
        },
        timedActivitiesFinalTotal() {
            let finalMinutes = this.timedActivitiesTotalMinutes % 60;
            let extraHours = Math.floor(this.timedActivitiesTotalMinutes / 60);

            let finalHours = (this.timedActivitiesTotalHours + extraHours) % 24;
            let extraDays = Math.floor((this.timedActivitiesTotalHours + extraHours) / 24);

            let finalDays = this.timedActivitiesTotalDays + extraDays;

            return (
                'Total Time: ' +
                finalDays +
                ' days, ' +
                finalHours +
                ' hours, ' +
                finalMinutes +
                ' minutes'
            );
        },
        filteredTimedActivitiesLogs() {
            return this.currentExport.selectedActivities.filter(log => log.show);
        }
    },

    mounted: function() {
        //   let urlParams = new URLSearchParams(window.location.search);
        //   this.queryID = urlParams.get('vid');

        this.vesselID = this.sentVesselID
            ? this.sentVesselID
            : this.$route.query.qvid
            ? this.$route.query.qvid
            : '';
        this.vesselName = this.sentVesselName
            ? this.sentVesselName
            : this.$route.query.qvnm
            ? this.$route.query.qvnm
            : '';
        this.exportID = this.sentExportID
            ? this.sentExportID
            : this.$route.query.exid
            ? this.$route.query.exid
            : '';

        if (this.vesselID != '' && this.exportID != '') {
            this.loadExportData();
        }
        this.today = new Date().toDateString();
    },

    data: function() {
        return {
            vesselID: '',
            vesselName: '',
            exportID: '',
            buttonText: 'Download PDF',
            today: '',
            hideForPrint: false,

            currentExport: {
                recipients: [],
                selectedActivities: [],
                timestamp: '',
                exportedBy: ''
            },

            details: {
                avatarUrl: '',
                intro: 'some intro here...'
            }
        };
    },

    methods: {
        async loadExportData() {
            let self = this;
            NProgress.start();
            db.collection('vessels')
                .doc(this.vesselID)
                .collection('timedActivityExports')
                .doc(self.exportID)
                .get()
                .then(async doc => {
                    self.currentExport.recipients = doc.data().recipients
                        ? doc.data().recipients
                        : [];
                    self.currentExport.timestamp = doc.data().timestamp ? doc.data().timestamp : '';
                    self.currentExport.exportedBy = doc.data().exportedBy
                        ? doc.data().exportedBy
                        : '';
                    let activities = doc.data().selectedActivities
                        ? doc.data().selectedActivities
                        : [];
                    await Promise.all(
                        activities.map(async activity => {
                            activity.show = true;
                        })
                    );
                    self.currentExport.selectedActivities = activities;
                })
                .then(() => {
                    NProgress.done();
                });
        },

        async resetActivities() {
            await Promise.all(
                this.currentExport.selectedActivities.map(async activity => {
                    activity.show = true;
                })
            );
        },

        getFinalTime(days, hours, minutes) {
            return days + 'd ' + hours + 'h ' + minutes + 'm';
        },

        deleteRowTimedActivities(item) {
            item.show = false;
        },

        formatDate(sentDate) {
            if (sentDate == '') return '';
            const dateOptions = {
                timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            // const dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric'};
            const myDate = new Date(sentDate.toDate());
            return myDate.toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        sortedByDate(array) {
            return array.sort(function(a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.finishTimestamp) - new Date(a.finishTimestamp);
            });
        },

        savePDF() {
            console.log();

            this.buttonText = 'Preparing PDF. Please wait...';

            // let pdfName = 'test';
            // var doc = new jsPDF();
            // //doc.text(this.vesselID, 10, 10);
            // doc.text(this.$refs.smsWrapper, 10, 10);
            // doc.save(pdfName + '.pdf');

            //

            var self = this;

            // var generalEmail = firebase.functions().httpsCallable('generalEmail');
            // generalEmail({
            //   senderEmail: self.userProfile.useremail,
            //   senderName: self.userProfile.username,
            //   senderUserID: self.firebaseid,
            //   subject: 'A user has printed an SMS',
            //   message: self.userProfile.username + ' has printed an SMS for vessel ' + self.details.vesselname + ' (ID: ' + self.vesselID + ')',
            // });

            let addRevision = firebase.functions().httpsCallable('addRevision');
            addRevision({
                vesselID: '',
                vesselName: '',
                description:
                    'Someone has printed a personal timer export report for from ' + self.vesselName
            });

            // First we take the HTML of the page
            var html = document.documentElement.outerHTML;
            // var printElement = document.getElementById(sentElement);
            // console.log(printElement.outerHTML);

            // Now we prepare the data that we pass to the API
            // In this example we want to hide the section with the example so we pass custom CSS
            // Note that you don't need to pass any other parameters if your defaults in the members area are already good
            var css =
                '.header { display:none !important;} #pdfButton {display:none;} .left-sidebar {display: none;} .buttons {display: none;} .vue-back-to-top {display: none;} .hideForPrint {display: none;} .narrow {display: none;} .main-fleet-content {padding: 1rem 0; margin: 0;} h4.icon {margin-bottom: 0;} .header-spacer {display: none;}';
            // var data = { html: html, license: 'E197E92RdrKd', css: css, };
            var data = {
                html: html,
                license: 'E197E92RdrKd',
                css: css,
                orientation: 'Portrait',
                top: 5,
                bottom: 5,
                right: 2,
                left: 2,
                page_size: 'A4'
            };
            var serialized = Object.keys(data)
                .map(function(k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
                })
                .join('&');

            //console.log(serialized);

            // You can insert an "in progress" message here

            // We now prepare the API call
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                var a;
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // The PDF is now generated
                    // You can remove the "in progress" message here
                    // Now we show the PDF to the user
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    a.download = 'OffshoreSMS-Report.pdf';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    self.buttonText = 'Download PDF';
                }
            };

            // This is the actual call to our API
            xhttp.open('POST', 'https://pdfmyurl.com/api', true);
            xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhttp.responseType = 'blob';
            xhttp.send(serialized);
        }
    }
};
</script>

<style lang="scss" scoped>
#app .header {
    position: relative !important;
}

.site-wrapper .container {
    max-width: 840px;
}

hr {
    margin-bottom: 2rem !important;

    &.narrow {
        margin-bottom: 2rem !important;
    }
}

h3 {
    font-size: 1.2rem !important;
    text-transform: uppercase !important;
    margin-top: 2.5rem !important;
}

table {
    margin-top: 1rem !important;
    table-layout: fixed !important;
    width: 100% !important;

    th {
        font-weight: normal !important;
        border: 1px solid #d4d4d4 !important;
        padding: 8px 12px;
        font-family: 'Open Sans', sans-serif;
        text-align: left !important;
        word-break: break-word;
    }

    td {
        width: 50% !important;
        border: 1px solid #d4d4d4 !important;
        padding: 8px 12px;
        font-family: 'Open Sans', sans-serif;
        text-align: left !important;
        word-break: break-word;

        .link {
            margin-top: 5px;
        }
    }
}

.pagebreak {
    display: block !important;
    clear: both !important;
    page-break-after: always !important;
}
p {
    &.total-time {
        margin-bottom: 10px;
        font-weight: bold;
        color: dodgerblue;
        font-size: 1.3rem;
    }
}
</style>
