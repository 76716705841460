<template>
    <div>
        <div class="site-wrapper no-header">
            <div class="full-width-image section1">
                <div class="hero-content">
                    <!-- <span class="hero-icon"><font-awesome-icon icon="coffee"/></span> -->
                    <span class="hero-text">Perfect for owner operators looking looking for a digital safety management system.</span>
                </div>
            </div>
            <div class="container full-width">
                <!-- <div class="spacer s30"></div> -->

                <div class="hero has-text-centered">
                    <div class="spacer s40"></div>
                    <p class="title is-3 is-primary">Digital Safety Management System</p>
                    <p class="subtitle is-5">Create a fully compliant and up-to-date Safety Management System for your vessel in just a few clicks PLUS get the powerful Ship's Log in the app!</p>
                    <div class="spacer s10"></div>
                </div>

                <!-- <div class="intro-image">
                    <img src="@/assets/graphics/plan-header-logs-sms.jpg" alt="OffshoreSMS Logs and SMS" />
                </div> -->

                <div class="pricing-wrapper">
                    <div class="pricing-table">
                        <div class="pricing-plan is-primary">
                            <div class="plan-header">SMS Only</div>
                            <div class="plan-icon"><font-awesome-icon icon="clipboard-list" /></div>
                            <div class="plan-price">
                                <span class="plan-price-amount"><span class="plan-price-currency">$</span>149</span> <span class="price-rate">per vessel<br />per year</span>
                            </div>
                            <div class="plan-items">
                                <div class="plan-item">
                                    <!-- <img src="@/assets/icons/green-tick.png" /> -->
                                    <div>Build a digital safety management system</div>
                                </div>
                                <div class="plan-item">
                                    <!-- <img src="@/assets/icons/green-tick.png" /> -->
                                    <div>Includes the awesome digital Ship's Log</div>
                                </div>
                                <div class="plan-item">
                                    <!-- <img src="@/assets/icons/green-tick.png" /> -->
                                    <div>Also includes a calendar for important dates</div>
                                </div>
                                <div class="plan-item">
                                    <!-- <img src="@/assets/icons/green-tick.png" /> -->
                                    <div>Plus free online support for a year</div>
                                </div>
                                <div class="plan-item">
                                    <!-- <img src="@/assets/icons/green-tick.png" /> -->
                                    <div>Add a <span class="green bold">drop-in SMS for just $300</span></div>
                                </div>
                            </div>
                            <div class="plan-footer">
                                <a href="#features" v-smooth-scroll="{ offset: -100 }" class="button is-fullwidth">See all Features</a>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="hide767">
              <div class="buttons centered">
                <span class="button is-medium is-success" @click="toSignUp()">Get Started with a FREE trial</span>
              </div>
              <div class="spacer s30"></div>
            </div> -->

                    <div class="other-plans">
                        <div class="pricing-table squashed">
                            <h3 class="heading bold">View our other plan options</h3>

                            <div class="pricing-plan is-info">
                                <div class="plan-header-wrapper">
                                    <div class="plan-header">Ship's Log</div>
                                    <div class="plan-price">
                                        <span class="plan-price-amount"><span class="plan-price-currency"></span>FREE</span>
                                    </div>
                                </div>
                                <div class="plan-footer">
                                    <router-link to="plan-logs" class="button is-fullwidth">Learn more</router-link>
                                </div>
                            </div>

                            <div class="pricing-plan is-danger">
                                <div class="plan-header-wrapper">
                                    <div class="plan-header">Pro Plan</div>
                                    <div class="plan-price">
                                        <span class="plan-price-amount"><span class="plan-price-currency">$</span>249</span> <span class="price-rate">per vessel<br />per year</span>
                                    </div>
                                </div>
                                <div class="plan-footer">
                                    <router-link to="plan-pro" class="button is-fullwidth">All Features</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="clear"></div>
                </div>

                <div class="spacer s30"></div>

                <!-- <div v-if="userData.userid" class="buttons centered">
          <span class="button is-medium is-light" @click="trackLink('my-dashboard','dashboard')">View Your Dashboard</span>
        </div>
        <div v-else class="buttons centered">
          <span class="button is-medium is-success" @click="trackLink('sign-up','signup')">Get Started for FREE</span>
          <span class="button is-medium is-light" @click="trackLink('sign-in','login')">Member Log in</span>
        </div> -->
                <div class="spacer s20"></div>

                <div class="like-wrapper centered">
                    <span v-if="!liked" class="button is-medium is-pink" @click="like()"
                        ><font-awesome-icon v-if="liking" icon="cog" class="fa-spin" /><font-awesome-icon v-else icon="thumbs-up" />&nbsp;Like this</span
                    >
                    <span v-else class="button is-medium is-light"><font-awesome-icon icon="thumbs-up" />&nbsp;Thanks!</span>
                    <span class="like-counter">{{ likes }}</span>
                </div>

                <div class="spacer s20"></div>

                <div id="features" class="feature-list">
                    <div class="main-content">
                        <div class="notification centered">
                            <h2>Digital SMS Features:</h2>
                        </div>

                        <div class="side-icon left"><img src="@/assets/icons/rocket.jpg" /></div>
                        <div class="side-text right">
                            <h5>Build your SMS step-by-step or add a Drop-in SMS for just $300</h5>
                            <p>
                                Fill in the blanks and add your own policies and procedures, risk assessments and contacts to build your SMS. Or add a Drop-in SMS for just $300 and we'll build it for
                                you. Call us to find out more.
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right"><img src="@/assets/icons/boat-3.jpg" /></div>
                        <div class="side-text left">
                            <h5>Follow the steps to an AMSA compliant SMS</h5>
                            <p>
                                It has never been easier to put together a fully AMSA compliant safety management system. And even if AMSA has feedback that requires updating the SMS, you can make the
                                changes from the web or in the app in seconds, and send back the updated SMS via email.
                            </p>
                        </div>
                        <div class="clear"></div>
                        <hr />

                        <div class="side-icon left"><img src="@/assets/icons/template.jpg" /></div>
                        <div class="side-text right">
                            <h5>Access your SMS from anywhere, at any time</h5>
                            <p>
                                Your SMS lives safely in the cloud, which means that it is always backed up and accessible from any device, anywhere. If you use the OffshoreSMS app, you can even view
                                your SMS when out of phone range.
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right"><img src="@/assets/icons/crew.jpg" /></div>
                        <div class="side-text left">
                            <h5>Australian-made with 100% local support.</h5>
                            <p>
                                We are a local Australian business and we're all marine people so we know how you work. We also know you're busy so we're here to make life easy for you. Our support is
                                100% free and unlimited, 24-7. Well almost 24/7.
                            </p>
                        </div>
                        <div class="clear"></div>
                        <hr />

                        <!-- <div class="side-icon left"><img src="@/assets/icons/target.jpg" /></div>
                        <div class="side-text right">
                            <h5>Give your crew live access to your SMS</h5>
                            <p>
                                It is an AMSA requirement that every crew member knows where the SMS lives and how to use it. By getting your crew to download the OffshoreSMS app and adding them as
                                crew, they will be able to view your SMS from anywhere!
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right"><img src="@/assets/icons/signature.jpg" /></div>
                        <div class="side-text left">
                            <h5>Take your crew inductions to the next level</h5>
                            <p>
                                We've made it super easy to induct your crew from the app, and to get a time-stamped, geo-located signature as proof of the induction. In fact we've taken it one step
                                further by allowing all crew added to your vessel to do a Self Induction right from the app! This means you know exactly which parts of your SMS they have read and
                                understood.
                            </p>
                        </div>
                        <div class="clear"></div>
                        <hr />

                        <div class="side-icon left"><img src="@/assets/icons/boat-3.jpg" /></div>
                        <div class="side-text right">
                            <h5>Ready to go with all the vessel logs you need!</h5>
                            <p>
                                Get started straight away with pre-start safety checks, crew inductions, maintenance recording, incident reports plus the most versatile Ship's Log for anything you
                                need to log on your vessel. Build your own checklists or use our great helpers to get up and running in minutes.
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right"><img src="@/assets/icons/checklist.jpg" /></div>
                        <div class="side-text left">
                            <h5>PLUS! Build your own logs and forms with our amazing Custom Logs!</h5>
                            <p>
                                If you'd like to create your own custom log you can too! Simply add a new custom log and add as many fields in each log as you like. Choose from a simple text field, a
                                longer paragraph field, a dropdown selection or set of checkbox options! Or you can drop in a helper custom log, like a weather log, a voyage plan log plus more.
                            </p>
                        </div>
                        <div class="clear"></div>
                        <hr /> -->

                        <div class="side-icon left"><img src="@/assets/icons/phone.jpg" /></div>
                        <div class="side-text right">
                            <h5>Even use the logs when out of phone range</h5>
                            <p>
                                Your logs lives safely in the cloud, which means that they are always backed up and accessible from any device, anywhere. Any logs done in the OffshoreSMS app will sync
                                back up with the cloud when you come back into phone range!
                            </p>
                        </div>
                        <div class="clear"></div>

                        <hr />
                        <div class="side-icon right"><img src="@/assets/icons/trophy.jpg" /></div>
                        <div class="side-text left">
                            <h5>Upgrade to PRO at any time for even more logs</h5>
                            <p>
                                Upgrade to our PRO Plan and enjoy even more digital vessel logs, like drills and maintenance schedules, fuel logs, vessel defect logs, shutdown logs and timed
                                activities.
                            </p>
                            <p>Plus, if you run a charter or passenger vessel, the Pro plan logs include passenger manifests, passenger safety briefings and real-time head count logs!</p>
                        </div>
                        <div class="clear"></div>
                        <!-- <hr> -->
                    </div>
                </div>

                <div class="spacer s50"></div>

                <div class="buttons centered">
                    <span v-if="!userExists" class="button is-medium is-success" @click="trackLink('sign-up', 'signup')">Join for FREE and decide later</span>
                    <span v-else class="button is-medium is-primary" @click="trackLink('my-dashboard', 'dashboard')">Member Log in</span>
                </div>

                <!-- <div class="spacer s20"></div>

                <hr />

                <div class="has-text-centered">
                    <div class="has-text-centered">
                        <p>&nbsp;</p>
                        <p>Did you know that we offer a 30 Day 100% Money Back Guarantee if you're not completely happy?</p>
                        <p>&nbsp;</p>
                        <span @click="isActiveMoreInfo = true" class="button is-info">Need more information?</span>
                        <p>&nbsp;</p>
                    </div>
                </div> -->

                <div class="spacer s100"></div>

                <!-- <hr>

        <div class="has-text-centered">
          <p>&nbsp;</p>
          <router-link class="button is-info is-medium" to="sign-up">Sign Up Now</router-link>
          <p></p>
        </div> -->
            </div>
        </div>

        <div class="buttons floating">
            <span @click="goBack()" class="button is-info"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
            <button class="button is-medium is-primary" @click="openEnquiry = true"><font-awesome-icon icon="comments" /> &nbsp; Quick Enquiry</button>
        </div>

        <quick-enquiry v-if="openEnquiry" @closed="close()" />

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': isActiveMoreInfo }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Need more information?</p>
                    <button class="delete" aria-label="close" @click.prevent="close"></button>
                </header>

                <section class="modal-card-body">
                    <div>
                        <p>Click here to view our <router-link to="faqs">Frequently Asked Questions</router-link>.</p>
                        <p>Still need some more information? Please feel free to give us a call on <a href="tel:+61-447-139-382">+61 447 139 382</a> and let's have a chat.</p>
                    </div>
                </section>

                <footer class="modal-card-foot">
                    <!-- <button class="button is-primary" @click="checkout">Checkout</button> -->
                    <button class="button cancel" @click.prevent="close">Done</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/main.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import NProgress from 'nprogress';
import QuickEnquiry from '../reusables/QuickEnquiry.vue';

export default {
    name: 'PlanLogsSms',
    components: {
        QuickEnquiry
    },
    data: function() {
        return {
            firebaseid: '',
            userExists: false,
            chosenPlan: '',
            chosenDescription: '',
            chosenPrice: '',
            chosenDisplayPrice: '',
            plans: {
                Free: { plan: 'Free', description: 'Crew plan', price: 0, class: 'is-warning', vessels: 0 },
                Starter: { plan: 'Starter', description: 'SMS Starter plan', price: 149, class: 'is-primary', vessels: 2 },
                Fleet: { plan: 'Fleet', description: 'Fleet plan', price: 249, class: 'is-danger', vessels: 10 }
            },
            signupModal: false,
            contactModal: false,
            enquiryCompany: '',
            enquiryContact: '',
            enquiryPhone: '',
            enquiryEmail: '',
            enquiryDetails: '',
            formSent: false,
            isActiveMoreInfo: false,

            likes: 0,
            liked: false,
            liking: false,
            openEnquiry: false
        };
    },
    computed: {
        showSendContact() {
            return (this.enquiryCompany != '') & (this.enquiryContact != '') && this.enquiryPhone != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        }
    },

    mounted() {
        firebase.auth().onAuthStateChanged(this.onUserLogin);
    },

    methods: {
        onUserLogin: function(user) {
            let self = this;
            this.getLikes();
            if (user) {
                this.firebaseid = user.uid;
                this.userExists = true;
            }
        },

        goBack() {
            this.$router.go(-1);
        },

        getLikes() {
            let self = this;
            db.collection('pageLikes')
                .doc('logsSmsPlan')
                .onSnapshot(doc => {
                    self.likes = doc.data().likes;
                });
        },

        like() {
            let self = this;
            this.liking = true;
            db.collection('pageLikes')
                .doc('logsSmsPlan')
                .update({
                    likes: firebase.firestore.FieldValue.increment(1)
                })
                .then(() => {
                    setTimeout(() => {
                        self.liking = false;
                        self.liked = true;
                    }, 500);
                })
                .then(() => {
                    let person = self.userData.username ? self.userData.username : 'Someone';
                    let addRevision = firebase.functions().httpsCallable('addRevision');
                    addRevision({
                        userID: self.userData.userid ? self.userData.userid : '',
                        userName: self.userData.username ? self.userData.username : '',
                        description: person + ' has liked the Logs + SMS Plan page!'
                    });
                });
        },

        // toSignUp() {
        //   window.fbq('track', 'Lead');
        //   this.$router.push({ name: 'signUp', params: {
        //       sentPlanIndex: 2,
        //       aaaPlans: ['logs','sms'],
        //     }
        //   });
        // },

        trackLink(page, type) {
            console.log(page, type);
            NProgress.start();
            let addRevision = firebase.functions().httpsCallable('addRevision');
            addRevision({
                userID: '-',
                userName: '-',
                description: 'A user has clicked through to the app.offshoresms ' + type + ' page.'
            });
            // this.$router.replace(page);
            window.location.href = 'https://app.offshoresms.com.au/' + page;
            // NProgress.done();
        },

        close: function() {
            this.signupModal = false;
            this.contactModal = false;
            this.enquiryCompany = '';
            this.enquiryContact = '';
            this.enquiryPhone = '';
            this.enquiryEmail = '';
            this.enquiryDetails = '';
            this.isActiveMoreInfo = false;
            this.openEnquiry = false;
        },

        // signup: function() {
        //  //console.log('Sign me up');
        //     this.$router.push({
        //       name: 'signUp',
        //     })
        // },

        sendContactEmail: function() {
            //console.log(this.enquiryCompany,this.enquiryContact,this.enquiryPhone,this.enquiryEmail,this.enquiryDetails);
            NProgress.start();
            var self = this;
            var enquiryArray = {
                enquiryCompany: self.enquiryCompany,
                enquiryContact: self.enquiryContact,
                enquiryPhone: self.enquiryPhone,
                enquiryEmail: self.enquiryEmail,
                enquiryDetails: self.enquiryDetails
            };
            var sendEmailFleetEnquiry = firebase.functions().httpsCallable('sendEmailFleetEnquiry');
            sendEmailFleetEnquiry({
                enquiryArray: enquiryArray
            });

            this.formSent = true;
            NProgress.done();
        }
    }
};
</script>

<style lang="scss" scoped>
.site-wrapper {
    padding-top: 0;
}

.title {
    margin-bottom: 12px;
}
.subtitle {
    margin-top: 0 !important;
}

textarea {
    min-height: 100px !important;
    box-shadow: none;
    padding: 0.6rem 1rem;
}

.full-width-image {
    height: 160px;
    width: 100%;
    position: relative;
    &.section1 {
        background: url('../assets/img/offshore-sms-boat-tied-2.jpg') no-repeat center center;
        background-size: cover;
        .hero-content {
            position: absolute;
            color: white;
            display: flex;
            align-items: center;
            top: 35%;
            left: 10%;
            font-size: 1.8rem;
            align-items: flex-start;
            margin-right: 2rem;
            .hero-icon {
                // padding-top: 3px;
                margin-right: 10px;
            }
            .hero-text {
                line-height: 2rem;
            }
        }
    }
}

.pricing-table {
    .pricing-plan {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        .plan-header {
            // padding-bottom: 0;
        }
        .plan-footer {
            margin-top: 0;
            margin-bottom: 1rem;
            .button {
                margin-top: 1rem;
                height: auto;
            }
        }
        .plan-price {
            .price-rate {
                width: 70px;
                display: inline-block;
                margin-left: 5px;
                line-height: 14px;
                text-align: left;
            }
        }
    }
}

.hero {
    margin-bottom: 1.5rem;
    .is-primary {
        color: #00d1b2;
    }
    .is-info {
        color: #209cee;
    }
    .is-warning {
        color: #fdd32c;
    }
    .is-danger {
        color: #ff3860;
    }
    h3 {
        color: #fff;
    }
    .subtitle {
        max-width: 800px;
        margin: 0.5rem auto 0;
    }
}

.feature-list {
    border: 1px solid #f1f1f1;
    border-radius: 15px;
    background: #fff;
    margin-top: 1rem;
    padding: 2.5rem;
    .icon-list {
        width: 100%;
        margin: 3rem auto;
        max-width: 600px;
        li {
            margin-bottom: 2rem;
            width: 100%;
            .small-icon {
                float: left;
                width: 80px;
                img {
                    width: 100%;
                }
            }
            .details {
                float: left;
                width: calc(100% - 120px);
                font-family: 'Roboto Slab', serif;
                // color: #ff7878;
                color: #37cda8;
                font-size: 1.4rem;
                line-height: 1.8rem;
                text-align: left;
                margin-left: 10px;
                padding-top: 1rem;
            }
        }
    }
}

.pricing-wrapper {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    .pricing-table {
        width: 50%;
        float: left;
        .pricing-plan {
            // border: 2px solid #00d1b2;
            border-radius: 8px;
            box-shadow: rgba(122, 122, 122, 0.2) 0 8px 12px 0;
            .plan-items {
                .plan-item {
                    text-align: center;
                    img {
                        display: inline-block;
                        width: 45px;
                    }
                    div {
                        display: inline-block;
                    }
                }
            }
            .plan-icon {
                font-size: 4rem;
                margin: 5px auto;
                text-align: center;
            }
            .plan-footer {
                a {
                    padding: 1rem;
                }
            }
            .plan-header-wrapper {
                display: flex;
                .plan-header {
                    padding-bottom: 0;
                    // color: #fff;
                    // background: #00d1b2;
                    // background: #ffdd57;
                    // display: none;
                }
                .plan-price {
                    .price-rate {
                        width: 60px;
                        display: inline-block;
                        margin-left: 5px;
                        line-height: 14px;
                        text-align: left;
                    }
                }
            }
        }
        .heading {
            text-align: center;
            margin: 0 auto 1rem;
        }
        &.squashed {
            padding: 10px 0 0;
            width: 100%;
            .pricing-plan {
                width: 100%;
                display: block;
                margin-bottom: 10px;
                border: 1px solid whitesmoke;
                padding: 2rem 1rem;
                .plan-header {
                    width: 42%;
                    float: left;
                    height: auto;
                    padding: 0.75em;
                    padding-top: 1.3rem;
                    background: #fff;
                    color: #333;
                }
                .plan-price {
                    width: 50%;
                    float: left;
                    text-align: left;
                }
                .plan-footer {
                    a {
                        // background: #d2cdcd;
                        padding: 5px 12px;
                    }
                }
            }
        }
    }
    .other-plans {
        width: 50%;
        float: right;
        padding: 0 2rem;
    }
}

.intro-image {
    margin: 0 10px 2rem;
    img {
        width: 100%;
        max-width: none;
    }
}

.main-content {
    width: 100%;
    .notification {
        margin-bottom: 3rem;
        h2 {
            font-size: 1.7rem;
            // margin-bottom: 10px;
            text-transform: uppercase;
            color: steelblue;
            font-weight: bold;
        }
    }
    h5 {
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: bold;
        font-family: 'Roboto Slab', serif;
        color: #37cda8;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: left;
        padding-top: 1rem;
    }
    p {
        line-height: 1.8rem;
    }
    hr {
        margin: 2.5rem 0;
    }
    .side-icon {
        width: 25%;
        &.left {
            float: left;
            padding-right: 1rem;
        }
        &.right {
            float: right;
            padding-left: 1rem;
        }
        img {
            width: 100%;
            padding: 0;
            max-width: none;
        }
    }
    .side-text {
        width: 75%;
        padding: 0 2rem;
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }
    }
}

.like-wrapper {
    .button {
        display: inline-block;
        &.is-pink {
            background: deeppink;
            color: #fff;
            border: none;
        }
    }
    .like-counter {
        background: limegreen;
        color: #fff;
        line-height: 10px;
        font-size: 14px;
        position: relative;
        display: inline-block;
        margin-left: 20px;
        padding: 10px 6px;
        text-align: center;
        min-width: 35px;
        height: 30px;
        border-radius: 3px;
        vertical-align: sub;
        margin-top: 7px;
        &:before {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid limegreen;
            left: -7px;
            top: 6px;
        }
    }
}

.hide767 {
    display: none;
}

@media only screen and (max-width: 767px) {
    .hide767 {
        display: block;
    }
    .pricing-wrapper {
        flex-direction: column;
        .pricing-table {
            width: 100%;
            float: none;
            &.squashed {
                padding: 0;
                width: 100%;
                .pricing-plan {
                    width: 100%;
                    display: block;
                    margin-bottom: 1rem;
                    .plan-price {
                        width: 100%;
                        float: none;
                        text-align: left;
                    }
                }
            }
        }
        .other-plans {
            width: 100%;
            float: none;
            padding: 0;
        }
    }
    .container {
        width: 100%;
    }
    .side-menu {
        display: none;
    }
    .dropdown-wrapper {
        display: block;
    }
    .main-content {
        width: 100%;
        float: none;
        .side-text {
            padding: 0 10px;
        }
    }
    .feature-list {
        padding: 1rem;
    }
    .full-width-image {
        height: 300px;
        &.section1 {
            .hero-content {
                flex-direction: column;
                align-items: center;
                text-align: center;
                position: relative;
                left: auto;
                .hero-text {
                    margin: 0 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .main-content {
        .side-icon {
            width: 100%;
            &.left {
                float: none;
                padding-right: 0;
            }
            &.right {
                float: none;
                padding-left: 0;
            }
            img {
                width: 100%;
                padding: 0;
                max-width: 150px;
                display: block;
                margin: 0 auto;
            }
        }
        .side-text {
            width: 100%;
            &.left {
                float: none;
            }
            &.right {
                float: none;
            }
        }
    }
    .feature-list {
        padding: 10px;
    }
}
</style>
