
<template>

    <li :class="[show ? 'active' : '']">
    	<div class="question" @click="show = !show">{{question}}<div class="chevron"><font-awesome-icon icon="chevron-down" /></div></div>
    	<transition name="bounce"> 
    		<div v-if="show" class="answer"><span v-html="answer"></span></div>
    	</transition>
    </li>  

</template>

<script>
	
	export default {

		props: [
			'question',
			'answer'
		],
		data: function() {
			return {
	      show: false,
			}
		}

	}

</script>



<style lang="scss" scoped>

		ul.faqs {
			margin-top: 1rem;
			li {
				margin-bottom: 1.3rem;
				.question {
					position: relative;
					width: 100%;
			    background: #f5f5f5;
			    padding: 10px 2.5rem 10px 15px;
			    cursor: pointer;
			    color: #245e8b;
          border-radius: 3px;
					.chevron {
						transform: rotate(0deg);
  					transition-duration: 0.3s;
						position: absolute;
						right: 12px;
    				top: 12px;
					}
				}
				.answer {
					display: none;
					transition: 0.3s;
					padding: 15px;
					background: #fdfdfd;
			    color: #245e8b;
				}
				&.active {
					.question {
						// background: #f3f5fd;
						.chevron {
							transform: rotate(180deg);
  						transition-duration: 0.3s;
						}
					}
					.answer {
						display: block;
					}
				}
			}
		}



</style>


