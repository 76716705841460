<template>

	<div>

			<div class="site-wrapper no-header">

  				
			
				<div class="container">

          <ul class="scroller">
            <!-- <li><router-link to="my-dashboard">Dashboard</router-link></li> -->
            <li><router-link to="useful-links">Useful Links</router-link></li>
            <li class="is-active"><a aria-current="page">{{msg}}</a></li>
          </ul>

          <div class="spacer s30"></div>

					<div class="notification is-info">
						<h4 class="is-primary">Read our FAQs</h4>
						<p>If you can't find the answers to your questions here, please leave us a message from the link below:</p>
            <div class="spacer s10"></div>
						<router-link class="button is-light" to="feedback">Get in touch</router-link>
					</div>

          <div class="spacer s10"></div>

					<!-- <hr> -->


					<div class="">
							<div class="card-content">
								<div class="is-divider" data-content="AMSA AND THE LAW"></div>
						    <ul class="faqs">
						      	<faq v-for="faq in faqsLaw" :question="faq.question" :key="faq.id" :answer="faq.answer"></faq>
						    </ul>
							</div>
					</div>


					<div class="">
							<div class="card-content">
								<div class="is-divider" data-content="GENERAL QUESTIONS"></div>
						    <ul class="faqs">
						      	<faq v-for="faq in faqsGeneral" :question="faq.question" :key="faq.id" :answer="faq.answer"></faq>
						    </ul>
							</div>
					</div>

					<div class="">
							<div class="card-content">
								<div class="is-divider" data-content="SMS QUESTIONS"></div>
						    <ul class="faqs">
						      	<faq v-for="faq in faqsSMS" :question="faq.question" :key="faq.id" :answer="faq.answer"></faq>
						    </ul>
							</div>
					</div>

					<div class="">
							<div class="card-content">
								<div class="is-divider" data-content="PAYMENTS"></div>
						    <ul class="faqs">
						      	<faq v-for="faq in faqsPayments" :question="faq.question" :key="faq.id" :answer="faq.answer"></faq>
						    </ul>
							</div>
					</div>


				</div>

        <div class="spacer s100"></div>


			</div>

			<!-- <sticky-footer :allHelp="allHelp" :title="helpTitle == '' ? msg : helpTitle" ></sticky-footer> -->

	</div>

</template>






<script>
	
	import { db } from '../main.js';
	import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth'; 
  import 'firebase/compat/firestore';
  import 'firebase/compat/functions';
	import NProgress from 'nprogress';
	//import { store } from '../store/store';
  import Faq from '../reusables/Faq.vue';

  // const StickyFooter = () => import('../reusables/StickyFooter.vue');

	

	export default {

		name: 'Faqs',
	  components: {
	  	Faq,
	  },
		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );		
	    this.loadFAQS();	
		},
		computed: {
    	isUserAdmin() {
	      return this.$store.getters.getIsUserAdmin;
	    },
	    userData() {
	    	return this.$store.getters.getSubscriptionInfo;
	    },
	    faqsGeneral() {
	    	return this.allFAQS.filter(faq => {
	    		return faq.category == 'general'
	    	});
	    },
	    faqsSMS() {
	    	return this.allFAQS.filter(faq => {
	    		return faq.category == 'sms'
	    	});
	    },
	    faqsPayments() {
	    	return this.allFAQS.filter(faq => {
	    		return faq.category == 'payments'
	    	});
	    },
	    faqsLaw() {
	    	return this.allFAQS.filter(faq => {
	    		return faq.category == 'law'
	    	});
	    },

	  },
		data: function() {
			return {
				msg: 'FAQs',
				firebaseid: '',
				firebaseemail: '',
				firebaserole: '',
				firebasedisplayname: '',
				allFAQS: [],
				// faqsGeneral: [
				// 	{question: 'Can I call someone for help?', answer: 'You bet. Anytime. If your questions aren\'t answered here, please call us on +61 447 139 382.'},
				// 	{question: 'Is this a website or an app?', answer: 'Well, a bit of both really. It\'s a website that you can install as an icon on your mobile phone home screen so that it opens and behaves like an app.'},
				// 	{question: 'What is the Cloud and will it cost me extra?', answer: 'Absolutely not. When we say your SMS lives in the Cloud, we mean that it is stored and backed up on our servers which live both in Australia and in the US. This has nothing to do with Apple's iCloud, and there is no extra cost for the SMS on top of your annual charge.' },
				// ],
				// faqsSMS: [
				// 	{question: 'How do I set up an SMS?', answer: 'Step 1. Click SMS Setup in your Dashboard and add some policies, procedures and checklists. Step 2. Add a new vessel in My Vessels. Once you have added a vessel, you will be able to view and edit the SMS for each vessel.'},
				// 	{question: 'What if I sell a vessel?', answer: 'We are working on an effective vessel SMS transfer process for when vessels change hands. This should be released in the coming weeks. In the meantime, if you need to transfer ownership of a vessel and want the SMS to stay with the vessel, please just give us a call on +61 447 139 382.'},
				// 	{question: 'How do I add crew?', answer: 'Only vessel owners can add crew. In My Crew, click on the "Add a crew member" button. You can search for a crew member by typing his name. Once found, connect with the crew member. You can then Induct that crew member from your My Crew page.'},
				// 	{question: 'How does the Hazard Analysis work?', answer: 'At the heart of your SMS is the identification of Hazards that may cause injury to you or your crew. Once your vessel is set up, you can add a hazard from either the My Vessels page, or from the Quicklinks button at the bottom of every page.'},
				// 	// {question: 'Do I need to set up my company SMS first?', answer: 'Yes. On your Dashboard, click on the SMS Setup button and you will be able to add company level policies, operating and emergency procedures, plus startup, maintenance and induction checklists. These will be pulled into each new vessel that you add under My Vessels.'},
				// 	{question: 'Can I delete a vessel?', answer: 'Yes, but not from your Dashboard at this stage. We should have this feature built for your convenience soon. If you are certain that you want to remove a vessel, please call us on +61 447 139 382.'},
				// ],
				// faqsPayments: [
				// 	{question: 'What does OffshoreSMS Cost?', answer: 'For crew and contractors Offshore SMS is totally FREE. For vessel owners we have the Starter Plan for $99/year per vessel. Additional vessels can be bought and added to your subscription at any time. Each additional vessel is $99/year with pro-rata billing. To view our plans and pricing options, please click on the top menu and then Pricing. Fleet pricing is $199/year per vessel.'},
				// 	{question: 'How can I pay?', answer: 'When you sign up for the first time as a vessel owner, you will be guided through our payment plans and you can pay by credit card. We do require payment by credit card to enable your subscription to be renewed annualy.'},
				// 	{question: 'Can I cancel my subscription?', answer: 'Of course. Every annual payment is made in advance, which means if you choose to cancel the next renewal, you can still use Offshore SMS until the renewal date. To cancel or pause a subscription, please call us on on <a href="tel:+61-447-139-382">+61 447 139 382</a>.'},
				// 	{question: 'What if my business grows?', answer: 'Then you are a legend! If you need to add more vessels you can upgrade to the next plan by visiting the My Profile page, then My Subscriptions where you can upgrade.'},
				// ],
				// faqsLaw: [
				// 	{question: 'What does Offshore SMS have to do with AMSA?', answer: 'Nothing. Offshore SMS was created by two commercial fishermen seeking a way to have a simple, compliant SMS without the need for a ton of paper. We are in no way affiliated with AMSA, although we do work hard to make sure that AMSA is happy with any SMS produced on our platform.'},
				// 	{question: 'What is Marine Order 504?', answer: 'Marine Order 504 (or MO504) is a part of the National Law for Domestic Commercial Vessels that covers Certificates of Operation and the gerneral Operatinal Requirements of commercial vessels in Australia. It is these operational requirements that form the basis of a vessel's safety management system. <a href="https://www.legislation.gov.au/Details/F2018L00809" target="_blank">Click here</a> to view MO504.'}
				// ],

				allHelp: [
					{ item: 'If you can\'t find the answers you need, please call us on +61 447 139 382.'},
				],
				helpTitle: '',
			}
		},
		methods: {
      onUserLogin: function( user ) {
      	if (user) {      		
	      	this.firebaseemail = user.email;
	      	this.firebaseid = user.uid;
	      	//this.firebasedisplayname = user.displayName;
	      	this.firebasedisplayname = this.userData.username;
	      }
      },

      loadFAQS: function() {
      	let self = this;
      	db.collection('faqs').onSnapshot(function(querySnapshot) {
      		self.allFAQS = [];
      		querySnapshot.forEach(function(doc) {
      			self.allFAQS.push({
      				category: doc.data().category,
      				question: doc.data().question,
      				answer: doc.data().answer,
      				id: doc.id
      			})
      		});
      	})
      },

		}

	}





</script>




<style lang="scss" scoped>

	.content {
    padding: 0 1.25rem;
  }

	.header-image {
	  background: url('../assets/img/placeholder-3.jpg') no-repeat center top;
	  color: #fff;
	  background-size: cover;
	}


</style>