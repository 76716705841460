<template>

	<div>

          <div class="split-wrapper">
   
            <div class="left-side">
                <div>
                    <div class="quotes">&#8220;</div>
                    <div v-if="testimonial == ''" class="testimonial">Tell us what you love about OffshoreSMS..</div>
                    <div v-else class="testimonial" v-for="(line, idx) in testimonial.split('\n')" :key="idx">{{line}}<br></div>
                    <div class="author">{{author}}{{town == '' || author == '' ? '' : ','}} <span class="">{{town}}</span></div>
                </div>
            </div>

            <div class="right-side">
                <div v-if="!saving" class="testi-form">
                    <h3>Leave a Testimonial </h3>
                    <p>How has OffshoreSMS helped your operation to be safer, more compliant, reduce unnecessary paper or anything else?</p>
                    <div class="spacer s20"></div>
                    <input class="input" type="text" placeholder="Your name or business.." v-model="author" />
                    <input class="input" type="text" placeholder="Which town are you in?" v-model="town" />
                    <textarea class="input" cols="30" rows="10" v-model="testimonial" placeholder="Tell us more..."></textarea>
                </div>

                <div v-if="!saving" class="buttons">
                    <button class="button is-success" :disabled="!showButton" @click="submit()">Leave my Testimonial</button>
                </div>

                <div v-if="saving" class="save-message">{{saveMsg}}</div>

                <div class="spacer s100"></div>
            </div>

            <div class="clear"></div>

        </div>


	</div>

</template>






<script>
    
        
	import { db } from '../main.js';
    import firebase from 'firebase/compat/app';
    import 'firebase/compat/auth'; 
    import 'firebase/compat/firestore';
    import 'firebase/compat/functions';
    import NProgress from 'nprogress';

	export default {

		computed: {
            showButton() {
                return this.testimonial != '' && this.author != '';
            },
            user() {
                return this.$store.getters.getUser;
            }
        },
        data: function() {
            return {
                testimonial: '',
                author: '',
                town: '',
                saving: false,
                saveMsg: '',
            } 
        },
        methods: {
        
            submit() {
                let self = this;
                NProgress.start();
                this.saving = true;
                this.saveMsg = 'Saving your testimonial...';
                let newData = {
		      		author: self.author,
		      		town: self.town,
		      		testimonial: self.testimonial,
		      		timestamp: firebase.firestore.FieldValue.serverTimestamp()
		      	}
		      	db.collection("testimonials").add(newData).then(function(doc) {
                    self.saveMsg = 'Thank you! We really value your support!';
                    NProgress.done();
                    setTimeout(() => {
                        self.$router.push({ name: 'new-home', params: {}});
                    }, 3000);
                    let addRevision = firebase.functions().httpsCallable('addRevision');
                    addRevision({
                        userID: '',
                        userName: self.town ? self.town : 'from somewhere',
                        description: self.author + ' has left a new testimonial',
                    });  
                })
                .catch(function(error) {
                    console.log(error); 
                    NProgress.done();
                });	
                  

            }

		},

	}





</script>




<style lang="scss" scoped>
    
    .split-wrapper {
        display: flex;
        justify-content: center;
        margin: 3rem auto 10rem;
        padding: 0 20px;
        max-width: 1000px;
        .left-side {
            width: 50%;
            padding: 2rem;
            .quotes {
                font-family: u2000;
                font-size: 8rem;
                font-weight: bold;
                color: #ccc;
                line-height: 2rem;
                margin-top: 2rem;
            }
            .testimonial {
                font-size: 1.8rem;
                color: mediumvioletred;
                font-weight: bold;
                font-style: italic;
                line-height: 2.5rem;
            }
            .author {
                color: #888;
                font-size: 1rem;
                margin: 10px 0;
            }
        }
        .right-side {
            width: 50%;
            padding: 2rem;
            h3 {
                font-family: "Roboto Slab", serif;
                color: #2562a4;
                font-size: 2.2rem;
                line-height: 2.5rem;
                margin-bottom: 1.5rem;
            }
            .testi-form {
                input, textarea {
                    padding: 15px;
                    height: auto;
                    margin-bottom: 10px;
                }
            }
            .save-message {
                font-size: 1.2rem;
                color: dodgerblue;
                font-weight: bold;
                line-height: 2.5rem;
                margin-top: 3rem;
            }
        }
    }


    @media only screen and (max-width: 767px) {
      
      .split-wrapper {
          flex-direction: column;
          padding: 0;
          .left-side {
              width: 100%;
              padding-top: 0;
              &:after {
                content: '';
                height: 1px;
                background-color: #e9e9e9;
                width: 80%;
                margin: 3rem auto 0;
                display: block;
              }
          }
          .right-side {
                width: 100%;
                padding-top: 1rem;
                .save-message {
                    text-align: center;
                }
          }
      }

    }



</style>