<template>

  <div>




    <div class="header-image">
        <div class="header-content">
          <h1>About Offshore SMS</h1>
        </div>
      </div>

    <div class="site-wrapper">

        

      <div class="container">

        <ul class="scroller">
          <li><router-link to="useful-links">Useful Links</router-link></li>
          <li class="is-active"><a aria-current="page">About Us</a></li>
        </ul>

        <div class="spacer s30"></div>

        <div class="">
            <div class="avatar company"> </div>
            <div class="avatar-content-box">
              <div class="content-align">
                <h2>OffshoreSMS</h2>
                <span>An Australian software business</span>
                <div class="social">
                  <a href="https://www.facebook.com/offshoresms/" target="_blank"><font-awesome-icon :icon="['fab', 'facebook-square']"></font-awesome-icon></a>
                </div>
              </div>
            </div>
            <div class="clear"></div>
        </div>

        <div class="content">
          <p>OffshoreSMS was created in 2018 by a small team of commercial vessel owners in need of a better solution to deal with the mounting paperwork required to stay compliant. The introduction of a National Law in Australia for commercial vessels prompted us to develop a simple set of cloud-based tools for other commercial vessel owners and operators with the ultimate goal of reducing paper on vessels and making safety and compliance easy.</p>
          <p>As OffshoreSMS grows, our goal is to listen to the ideas, suggestions and feedback from our customers, and to improve and develop features to benefit our users. Almost every feature on OffshoreSMS has been built in response to our customer feedback and this is what drives us.</p>
          <p>At heart, we are still commercial vessel owners, and our mission every day is to make life easier for the people that work on and manage any commercial vessel anywhere on earth.</p>
        </div>

        <hr>

        <div class="">
            <div class="avatar steve"> </div>
            <div class="avatar-content-box">
              <div class="content-align">
                <h2>Steve Fraser</h2>
                <span>Managing Director and CTO</span>
                <div class="social">
                  <a href="https://www.linkedin.com/in/stevefrasernet/" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon></a>
                </div>
              </div>
            </div>
            <div class="clear"></div>
        </div>

        <div class="content">
          <p>Steve Fraser is the lead creator and driving force behind OffshoreSMS. A software developer with a passion for the maritime industry, Steve works day and night to make the range of web and app-based tools on the OffshoreSMS platform come to life.</p>
          <p>When not writing code, Steve is actively engaged in supporting new and existing OffshoreSMS customers, and engaging with industry groups, vessel fleets and other commercial vessel owners.</p>
        </div>

        <hr>

        <div class="spacer s30"></div>

        <div class="">
            <div class="avatar rob"> </div>
            <div class="avatar-content-box">
              <div class="content-align">
                <h2>Rob Menadue</h2>
                <span>Director and People Person</span>
                <!-- <div class="social">
                  <a href="https://www.linkedin.com/in/stevefrasernet/" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon></a>
                </div> -->
              </div>
            </div>
            <div class="clear"></div>
        </div>

        <div class="content">
          <p>Rob has been working on commercial boats for over 30 years across a range of industries and operations.</p>
          <p>A born people person, Rob is incredibly adept at seeing OffshoreSMS from a user's perspective, and keeps the rest of us tech-heads focused on product simplicity and ease of use.</p>
          <p>Without Rob, OffshoreSMS would undoubtedly be far more complex than it is!</p>
        </div>

        <hr>

        <div class="spacer s30"></div>

        <div class="">
            <div class="avatar josh"> </div>
            <div class="avatar-content-box">
              <div class="content-align">
                <h2>Josh Bennett</h2>
                <span>Marine Strategist and Safety Specialist</span>
                <!-- <div class="social">
                  <a href="https://www.linkedin.com/in/stevefrasernet/" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon></a>
                  <a href="https://www.linkedin.com/in/stevefrasernet/" target="_blank"><font-awesome-icon :icon="['fab', 'facebook-square']"></font-awesome-icon></a>
                </div> -->
              </div>
            </div>
            <div class="clear"></div>
        </div>

        <div class="content">
          <p>Josh is a rare breed - someone that is equally skilled at mastering a vessel and building spreadsheets in the office! His focus and organisational skills perfectly complement the chaos that sometimes comes in times of peak development.</p>
          <p>With a passion for maritime safety and efficient fleet management, Josh reminds us every day why we create the tools that we do - to make life safer and easier for people that work on the water.</p>
        </div>

        <div class="spacer s30"></div>

        <!-- <div class="">
            <div class="avatar thomas"> </div>
            <div class="avatar-content-box">
              <div class="content-align">
                <h2>Thomas Talsma</h2>
                <span>Web Developer</span>
                <div class="social">
                  <a href="https://www.linkedin.com/in/stevefrasernet/" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon></a>
                  <a href="https://www.linkedin.com/in/stevefrasernet/" target="_blank"><font-awesome-icon :icon="['fab', 'facebook-square']"></font-awesome-icon></a>
                </div>
              </div>
            </div>
            <div class="clear"></div>
        </div>

        <div class="content">
          <p>Thomas is an incredibly talented developer and an all round cool guy, driving the use of cutting edge tools and frameworks across our platforms.</p>
          <p>Organisation and efficiency make Thomas an important and a most valued addition to our team.</p>
        </div> -->



        <div class="spacer s60"></div>


      </div>
      

    </div>




  </div>
  

</template>




<script>




export default {

  name: 'AboutUs',

  components: {

  },

  mounted: function() {

  },

  computed: {

  },

  data: function() {
    return {


    }
  },

  methods: {
    
  }

}

</script>




<style lang="scss" scoped>

  .header-image {
    background: url('../assets/img/placeholder-2.jpg') no-repeat center center;
    color: #fff;
  }

  .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    background: steelblue;
    display: block;
    margin: 0 2rem 2rem 0;
    float: left;
    transition: 0.3s;
    background-repeat: no-repeat;
    background-position: center center;
    &.company {
      background: url('../assets/img/offshore-sms-icon.jpg') no-repeat center center;
      background-size: cover !important;
    }
    &.steve {
      background: url('../assets/img/stevefraser.jpg') no-repeat center center;
      background-size: contain;
    }
    &.rob {
      background: url('../assets/img/robmenadue.jpg') no-repeat center center;
      background-size: contain;
    }
    &.josh {
      background: url('../assets/img/joshbennett2.jpg') no-repeat center center;
      background-size: contain;
    }
    &.thomas {
      background: url('../assets/img/profile-placeholder.jpg') no-repeat center center;
      background-size: contain;
    }
  }
  .avatar-content-box {
    height: 150px;
    width: calc(100% - 200px);
    float: left;
    position: relative;
    .content-align {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        width: 0;
        bottom: -10px;
        background: steelblue;
        height: 5px;
        transition-property: width;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
      }
      h2 {
        font-size: 1.5rem;
        line-height: 1.8rem;
      }
      span {

      }
    }
  }
  .social {
    a {
      display: inline-block;
      transition: 0.3s;
      font-size: 2rem;
      margin-right: 10px;
    }
  }
  .content {
    p {
      margin-bottom: 1rem;
      color: #333;
    }
  }


  @media only screen and (max-width: 479px) {

    .avatar {
      float: none;
      margin: 0 auto 1rem;
    }
    .avatar-content-box {
      width: 100%;
      text-align: center;
    }


  }

</style>